const cmd_prefix = 'wscmd_'
//登录
const cmd_1 = 1
const cmd_1_resp = `${cmd_prefix}${cmd_1}`
//系统配置信息
const cmd_99 = 99
const cmd_99_resp = `${cmd_prefix}${cmd_99}`

//七牛云token获取
const cmd_98 = 98
const cmd_98_resp = `${cmd_prefix}${cmd_98}`

//提示音
const cmd_100 = 100
const cmd_100_resp = `${cmd_prefix}${cmd_100}`
//后台推送用户信息
const cmd_101 = 101
const cmd_101_resp = `${cmd_prefix}${cmd_101}`
//退出登录
const cmd_200 = 200
const cmd_200_resp = `${cmd_prefix}${cmd_200}`

//获取账号列表
const cmd_2 = 2
const cmd_2_resp = `${cmd_prefix}${cmd_2}`

//获取账号好友列表
const cmd_3 = 3
const cmd_3_resp = `${cmd_prefix}${cmd_3}`

//获取好友聊天记录或者后台推送聊天记录
const cmd_4 = 4
const cmd_4_resp = `${cmd_prefix}${cmd_4}`

//通知后台消息已读
const cmd_5 = 5
const cmd_5_resp = `${cmd_prefix}${cmd_5}`

//发送消息
const cmd_6 = 6
const cmd_6_resp = `${cmd_prefix}${cmd_6}`

//保存好友线索信息
const cmd_7 = 7
const cmd_7_resp = `${cmd_prefix}${cmd_7}`

//获取好友线索信息
const cmd_8 = 8
const cmd_8_resp = `${cmd_prefix}${cmd_8}`

//撤回消息
const cmd_9 = 9
const cmd_9_resp = `${cmd_prefix}${cmd_9}`

//标记账号消息已读
const cmd_10 = 10
const cmd_10_resp = `${cmd_prefix}${cmd_10}`

//保存好友联系方式
const cmd_11 = 11
const cmd_11_resp = `${cmd_prefix}${cmd_11}`


//保存好友联系方式
const cmd_12 = 12
const cmd_12_resp = `${cmd_prefix}${cmd_12}`

//检索聊天记录搜索结果
const cmd_13 = 13
const cmd_13_resp = `${cmd_prefix}${cmd_13}`

//空号检测
const cmd_14 = 14
const cmd_14_resp = `${cmd_prefix}${cmd_14}`
//修改客服服务状态
const cmd_20 = 20
const cmd_20_resp = `${cmd_prefix}${cmd_20}`
//修改账号机器人接管状态
const cmd_21 = 21
const cmd_21_resp = `${cmd_prefix}${cmd_21}`
//设置好友聊天人工接管
const cmd_22 = 22
const cmd_22_resp = `${cmd_prefix}${cmd_22}`
//设置人工接管有效时间
const cmd_23 = 23
const cmd_23_resp = `${cmd_prefix}${cmd_23}`

export default {
    cmd_prefix,
    cmd_99,
    cmd_99_resp,
    cmd_1,
    cmd_1_resp,
    cmd_2,
    cmd_2_resp,
    cmd_3,
    cmd_3_resp,
    cmd_4,
    cmd_4_resp,
    cmd_5,
    cmd_5_resp,
    cmd_6,
    cmd_6_resp,
    cmd_7,
    cmd_7_resp,
    cmd_8,
    cmd_8_resp,
    cmd_98,
    cmd_98_resp,
    cmd_9,
    cmd_9_resp,
    cmd_10,
    cmd_10_resp,
    cmd_11,
    cmd_11_resp,
    cmd_12,
    cmd_12_resp,
    cmd_13,
    cmd_13_resp,
    cmd_14,
    cmd_14_resp,
    cmd_20,
    cmd_20_resp,
    cmd_21,
    cmd_21_resp,
    cmd_22,
    cmd_22_resp,
    cmd_23,
    cmd_23_resp,
    cmd_100,
    cmd_100_resp,
    cmd_101,
    cmd_101_resp,
    cmd_200,
    cmd_200_resp,
  
}