const state = {
    // selectAccountId: 0,
    // selectAccountInfo:{},

    // selectAccountFriendId: 0,
    // selectAccountFriendInfo:{},
    // //某个账号激活的选中好友存储 {accountId:x,friendId:y}
    // accountChatFriend:[]
}


const mutations = {
    // SET_ACCOUNT_ID(state, data) {
    //     state.selectAccountId = data
    // },
    // SET_ACCOUNT_INFO(state, data) {
    //     state.selectAccountInfo = data
    // },

    // SET_ACCOUNT_FRIEND_ID(state, data) {
    //     state.selectAccountFriendId = data
    // },
    // SET_ACCOUNT_FRIEND_INFO(state, data) {
    //     state.selectAccountFriendInfo = data
    // },
    // //记录账号正在跟谁聊天
    // SET_ACCOUNT_CHAT_WITH(state, data){
    //     let accountId = data.accountId
    //     let friendId = data.friendId
    //     if(!friendId || !friendId){
    //         return
    //     }
    //     let findIdx = state.accountChatFriend.findIndex(item=>item.accountId == accountId)
    //     if(findIdx == -1){
    //         state.accountChatFriend.push(data)
    //     }else{
    //         state.accountChatFriend.splice(findIdx,1,data)
    //     }
    // }

}

const actions = {
   
    // //更新好友聊天记录
    // refreshFriendChatLog({ commit }, chatlog) {
    //     return new Promise((resolve) => {
    //         commit('SET_FRIEND_CHAT_LOG',chatlog)
    //         resolve()
    //     })
    // },


}
const getters = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
