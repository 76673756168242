import main from "../../main";
import { getUserinfo } from '@/utils/auth'
import { ElNotification } from 'element-plus'
const state = {
  socket: {
    // 连接状态
    isConnected: false,
    // 消息内容
    message: "",
    // 重新连接错误
    reconnectError: false,
    // 心跳消息发送时间
    heartBeatInterval: 10000,
    // 心跳定时器
    heartBeatTimer: 0,
    //消息队列
    messageQueue: [],

  }
};

const mutations = {
  // 连接打开
  SOCKET_ONOPEN(state, event) {
    console.log('------SOCKET_ONOPEN------')
    main.config.globalProperties.$socket = event.currentTarget;
    // console.log(main.config.globalProperties)
    state.socket.isConnected = true;
    // 连接成功时启动定时发送心跳消息，避免被服务器断开连接
    state.socket.heartBeatTimer = setInterval(() => {
      const message = "ping";
      if (state.socket.isConnected) {
        try {
          main.config.globalProperties.$socket.sendObj({
            code: 200,
            msg: message
          });
        } catch (e) {
          console.log(e)
        }

      }

    }, state.socket.heartBeatInterval);

    //发送未发送成功的消息
    state.socket.messageQueue.forEach((message) => {
      try {
        console.log('~~~~~~~~~~~~~~~~~messageQueue.forEach', message)
        this.dispatch('websocket/sendObj', message)
      } catch (e) {
        console.log(e)
      }

    });
    state.socket.messageQueue = [];

  },
  // 连接关闭
  SOCKET_ONCLOSE(state, event) {
    state.socket.isConnected = false;
    // 连接关闭时停掉心跳消息
    clearInterval(state.socket.heartBeatTimer);
    state.socket.heartBeatTimer = 0;
    console.log("连接已断开: " + new Date());
    console.log(event);
  },
  // 发生错误
  SOCKET_ONERROR(state, event) {
    console.error(state, event);
  },
  // 收到服务端发送的消息
  SOCKET_ONMESSAGE(state, message) {
    try {
      if (message && message.t) {
        //是否token过期
        let code = message.code
        if (code === -1) {
          main.config.globalProperties.$eventBus.emit('logout')
          return
        } else if (code != 1) {
          let msg = message.msg
          ElNotification({
            title: '错误提示',
            message: msg || '操作失败',
            type: 'error',
          })
          main.config.globalProperties.$eventBus.emit(`wscmd_${message.t}`, message)
          return
        }

        let showmsg = message.showmsg
        if (showmsg) {
          ElNotification({
            title: '提示信息',
            message: showmsg || '操作成功',
            type: 'success',
          })
        }

        //提示音标记
        if (message?.n == 1) {
          main.config.globalProperties.$eventBus.emit(`wscmd_100`, { notice: 1 })
        }
        //操作成功
        main.config.globalProperties.$eventBus.emit(`wscmd_${message.t}`, message)
      } else {
        //console.log('pong msg',message)
      }

      //console.log('------SOCKET_ONMESSAGE------')
      //console.log(message)
      state.socket.message = message;
    } catch (e) {
      console.log(e)
    }

  },
  // 自动重连
  SOCKET_RECONNECT(state, count) {
    console.info("消息系统重连中...", state, count);
  },
  // 重连错误
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true;
  },

}

const actions = {
  sendObj({ commit }, data) {
    let userInfo = getUserinfo()

    return new Promise((resolve) => {
      commit.d = 1
      if (state.socket.isConnected) {
        let sendData = {
          ...data, cid: userInfo?.company_id, uid: userInfo?.uid, token: userInfo?.token
        }
        console.log('sendObj', sendData)
        main.config.globalProperties.$socket.sendObj(sendData);
        return resolve(true)
      } else {
        //未发送成功下次重连后自动发送
        state.socket.messageQueue.push(data);
        //return reject(false)
      }
    })
  }

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
