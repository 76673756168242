
const defaultActiveComponent = 'chatingCustomInfo'
const allomponents ={
    chatingCustomInfo:{
        component_item: "chatingCustomInfo",
        title: "好友信息",
    },
    customChatlogHistory:{
        component_item: "customChatlogHistory",
        title: "好友聊天记录",
    },
}

const state = {
    activeComponent:allomponents[defaultActiveComponent],
}


const mutations = {
    SET_ACTIVE_COMPONENT(state, compStr) {
        state.activeComponent = allomponents[compStr]
    },
}

const actions = {
    resetActiveComponent({ commit }){
        return new Promise((resolve) => {
            commit('SET_ACTIVE_COMPONENT',defaultActiveComponent)
            resolve()
        })
    },
    //激活组件
    activeComponent({ commit }, compStr) {
        return new Promise((resolve) => {
            commit('SET_ACTIVE_COMPONENT',compStr)
            resolve()
        })
    },


}
const getters = {
    getAllComponent (state) {
        return state.allomponents
    },
    getActiveComponent (state) {
        return state.activeComponent
    }


}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
