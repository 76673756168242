import Cookies from 'js-cookie'

const TokenKey = 'feihu_chat_token'
const UserKey = 'feihu_chat_user'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserinfo() {
  const userinfo = localStorage.getItem(UserKey)
  if (userinfo) {
    return JSON.parse(userinfo)
  } else {
    return ''
  }
}

export function setUserinfo(userinfo) {
  return localStorage.setItem(UserKey, JSON.stringify(userinfo))
}
export function removeUserinfo() {
    localStorage.removeItem(UserKey)
}
