//emoji表情
let emojis = [{
    file: '1.png',
    title: '微笑'
  },
  {
    file: '2.png',
    title: '撇嘴'
  },
  {
    file: '3.png',
    title: '色'
  },
  {
    file: '4.png',
    title: '发呆'
  },
  {
    file: '5.png',
    title: '得意'
  },
  {
    file: '6.png',
    title: '流泪'
  },
  {
    file: '7.png',
    title: '害羞',
  },
  {
    file: '8.png',
    title: '闭嘴'
  },
  {
    file: '9.png',
    title: '睡'
  },
  {
    file: '10.png',
    title: '大哭'
  },
  {
    file: '11.png',
    title: '尴尬'
  },
  {
    file: '12.png',
    title: '发怒'
  },
  {
    file: '13.png',
    title: '调皮'
  },
  {
    file: '14.png',
    title: '呲牙'
  },
  {
    file: '15.png',
    title: '惊讶'
  },
  {
    file: '16.png',
    title: '难过'
  },
  {
    file: '17.png',
    title: '酷'
  },
  {
    file: '18.png',
    title: '囧'
  },
  {
    file: '19.png',
    title: '抓狂'
  },
  {
    file: '20.png',
    title: '吐'
  },
  {
    file: '21.png',
    title: '偷笑'
  },
  {
    file: '22.png',
    title: '愉快'
  },
  {
    file: '23.png',
    title: '白眼'
  },
  {
    file: '24.png',
    title: '傲慢'
  },
  {
    file: '25.png',
    title: '饥饿'
  },
  {
    file: '26.png',
    title: '困'
  },
  {
    file: '27.png',
    title: '惊恐'
  },
  {
    file: '28.png',
    title: '流汗'
  },
  {
    file: '29.png',
    title: '憨笑'
  },
  {
    file: '30.png',
    title: '悠闲'
  },
  {
    file: '31.png',
    title: '奋斗'
  },
  {
    file: '32.png',
    title: '咒骂'
  },
  {
    file: '33.png',
    title: '疑问'
  },
  {
    file: '34.png',
    title: '嘘'
  },
  {
    file: '35.png',
    title: '晕'
  },
  {
    file: '36.png',
    title: '疯了'
  },
  {
    file: '37.png',
    title: '衰'
  },
  {
    file: '38.png',
    title: '骷髅'
  },
  {
    file: '39.png',
    title: '敲打'
  },
  {
    file: '40.png',
    title: '再见'
  },
  {
    file: '41.png',
    title: '擦汗'
  },
  {
    file: '42.png',
    title: '抠鼻'
  },
  {
    file: '43.png',
    title: '鼓掌'
  },
  {
    file: '44.png',
    title: '糗大了'
  },
  {
    file: '45.png',
    title: '坏笑'
  },
  {
    file: '46.png',
    title: '左哼哼'
  },
  {
    file: '47.png',
    title: '右哼哼'
  },
  {
    file: '48.png',
    title: '哈欠'
  },
  {
    file: '49.png',
    title: '鄙视'
  },
  {
    file: '50.png',
    title: '委屈'
  },
  {
    file: '51.png',
    title: '快哭了'
  },
  {
    file: '52.png',
    title: '阴险'
  },
  {
    file: '53.png',
    title: '亲亲'
  },
  {
    file: '54.png',
    title: '吓'
  },
  {
    file: '55.png',
    title: '可怜'
  },
  {
    file: '56.png',
    title: '菜刀'
  },
  {
    file: '57.png',
    title: '西瓜'
  },
  {
    file: '58.png',
    title: '啤酒'
  },
  {
    file: '59.png',
    title: '篮球'
  },
  {
    file: '60.png',
    title: '乒乓'
  },
  {
    file: '61.png',
    title: '咖啡'
  },
  {
    file: '62.png',
    title: '饭'
  },
  {
    file: '63.png',
    title: '猪头'
  },
  {
    file: '64.png',
    title: '玫瑰'
  },
  {
    file: '65.png',
    title: '凋谢'
  },
  {
    file: '66.png',
    title: '嘴唇'
  },
  {
    file: '67.png',
    title: '爱心'
  },
  {
    file: '68.png',
    title: '心碎'
  },
  {
    file: '69.png',
    title: '蛋糕'
  },
  {
    file: '70.png',
    title: '闪电'
  },
  {
    file: '71.png',
    title: '炸弹'
  },
  {
    file: '72.png',
    title: '刀'
  },
  {
    file: '73.png',
    title: '足球'
  },
  {
    file: '74.png',
    title: '瓢虫'
  },
  {
    file: '75.png',
    title: '便便'
  },
  {
    file: '76.png',
    title: '月亮'
  },
  {
    file: '77.png',
    title: '太阳'
  },
  {
    file: '78.png',
    title: '礼物'
  },
  {
    file: '79.png',
    title: '拥抱'
  },
  {
    file: '80.png',
    title: '强'
  },
  {
    file: '81.png',
    title: '弱'
  },
  {
    file: '82.png',
    title: '握手'
  },
  {
    file: '83.png',
    title: '胜利'
  },
  {
    file: '84.png',
    title: '抱拳'
  },
  {
    file: '85.png',
    title: '勾引'
  },
  {
    file: '86.png',
    title: '拳头'
  },
  {
    file: '87.png',
    title: '差劲'
  },
  {
    file: '88.png',
    title: '爱你'
  },
  {
    file: '89.png',
    title: 'NO'
  },
  {
    file: '90.png',
    title: 'OK'
  },
  {
    file: '91.png',
    title: '爱情'
  },
  {
    file: '92.png',
    title: '飞吻'
  },
  {
    file: '93.png',
    title: '跳跳'
  },
  {
    file: '94.png',
    title: '发抖'
  },
  {
    file: '95.png',
    title: '怄火'
  },
  {
    file: '96.png',
    title: '转圈'
  },
  {
    file: '97.png',
    title: '磕头'
  },
  {
    file: '98.png',
    title: '回头'
  },
  {
    file: '99.png',
    title: '跳绳'
  },
  {
    file: '100.png',
    title: '投降'
  },
  {
    file: '101.png',
    title: '激动'
  },
  {
    file: '102.png',
    title: '乱舞'
  },
  {
    file: '103.png',
    title: '献吻'
  },
  {
    file: '104.png',
    title: '左太极'
  },
  {
    file: '105.png',
    title: '右太极'
  },
  {
    file: '106.png',
    title: '奸笑'
  },
  {
    file: '107.png',
    title: '嘿哈'
  },
  {
    file: '108.png',
    title: '捂脸'
  },
  {
    file: '109.png',
    title: '机智'
  },
  {
    file: '110.png',
    title: '茶'
  },
  {
    file: '111.png',
    title: '红包'
  },
  {
    file: '113.png',
    title: '耶'
  },
  {
    file: '114.png',
    title: '皱眉'
  }
]
//  在发送信息之后，将输入的内容中属于表情的部分替换成emoji图片标签
//  再经过v-html 渲染成真正的图片
function replaceEmoji(con) {
  con = con + ''

  //找找中括号表情
  let regExp = /(?<=\[)(.+?)(?=\])/g
  let matchRes = con.match(regExp)
  if(!matchRes || matchRes.length == 0){
    return con
  }

  for(let i =0;i<matchRes.length;i++){
    let emojStr = matchRes[i]
    let findItem = emojis.find(item=>item.title==emojStr)
    if(findItem){
      let img = '<img src="'+require("@assets/img/emoji/" +findItem.file )+ '"  alt="" style="vertical-align: middle; width: 24px; height: 24px" />'
      con = con.replace(new RegExp('\\[' + findItem.title + ']', 'g'), img)
    }

  }
  return con

  // if (con && con.includes('[') && con.includes(']')) {
  //   //查找

  //   for (var i = 0; i < emojis.length; i++) {
  //     let item = emojis[i]
  //     let img = '<img src="'+require("@assets/img/emoji/" +item.file )+ '"  alt="" style="vertical-align: middle; width: 24px; height: 24px" />'
  //     con = con.replace(new RegExp('\\[' + item.title + ']', 'g'), img)
  //   }
  //   return con;
  // }
  // return con;
}

/**
 * 将自定义的，较友好的表情字符转换为原生的字符
 * 
 * [鼓掌(/:handclap)] => /:handclap
 * @param {String} con 内容
 */
function toOriginEmoji(con) {
  if (con && con.includes('/:')) {
    for (var i = 0; i < emojis.length; i++) {
      let item = emojis[i];
      // 自定义的格式，样例: [鼓掌(/:handclap)]
      let reg = `[${item.title}(${item.code})]`;
      con = con.split(reg).join(item.code);
    }
    return con;
  }
  return con;
}

export {
  emojis,
  replaceEmoji,
  toOriginEmoji
}