import {  pushAll } from '@/utils/arrayutils'
const chatStore = []


function getAllChatLog() {
    return  chatStore
}

 function getChatLog(fid) {
    let findRes =  chatStore.find(item=>item.fid == fid) 
    if(!findRes){
      return {fid:fid,msglist:[]}
    }
    return findRes
}

function refreshChatLogStoreAsyn(chatLogDataCenter){
    return new Promise((resolve)=>{
        refreshChatLogStore(chatLogDataCenter)
        return resolve()
    })
      
}


function refreshChatLogStore(chatLogDataCenter){
    let type = chatLogDataCenter.type
    let chatLogData = chatLogDataCenter.data
    //动作类型 1全部，2新增，3更新，4删除，默认1
    if(type == 1){
        mergeChatLogStore(chatLogData)
    }else if(type == 2){
        mergeChatLogStore(chatLogData)
    }else if(type == 3){
        mergeChatLogStore(chatLogData)
    }else if(type == 4){
        deleteChatLogItemStore(chatLogData)
    }
}


//删除消息记录
function deleteChatLogItemStore(chatLogData){
    //待删除的元素数组
    let preDeletedMsgList = chatLogData.msglist
    if(!preDeletedMsgList || preDeletedMsgList.length == 0){
        return
    }

    let findIdx = chatStore.findIndex(item => item.fid == chatLogData.fid)
    if(findIdx < 0){
        return
    }
    let oldChatLogData = chatStore[findIdx]
    let oldChatLogDataMsgList = oldChatLogData.msglist
    if(!oldChatLogDataMsgList || oldChatLogDataMsgList.length == 0 ){
        return
    }

    for(let i = 0;i<preDeletedMsgList.length;i++){
        let deleteItem = preDeletedMsgList[i]
        let id = deleteItem.id
        if(!id){
            continue
        }
        //暂时未实现
        let deleteIdx = oldChatLogDataMsgList.findIndex(item => item.fid == id)
        if(deleteIdx >= 0){
            oldChatLogDataMsgList.splice(deleteIdx,1)
        }
    }
}

//替换所有消息
function replaceAllChatlogStore(chatLogData){
     //如果没有聊天记录则忽略
     if(!chatLogData.msglist || chatLogData.msglist.length == 0){
        return
    }

    let findIdx = chatStore.findIndex(item => item.fid == chatLogData.fid)
    if(findIdx < 0){
        //不存在直接push
        chatStore.push(chatLogData)
        return
    }
    //直接替换
    chatStore.splice(findIdx,1,chatLogData)
    return
}



//合并聊天记录 chatLog{}
function mergeChatLogStore(chatLogData){
    //如果没有聊天记录则忽略
    if(!chatLogData.msglist || chatLogData.msglist.length == 0){
        return
    }

    let findIdx = chatStore.findIndex(item => item.fid == chatLogData.fid)
    if(findIdx < 0){
        //不存在直接push
        chatStore.push(chatLogData)
        return
    }
    //原来好友的聊天记录
    let oldChatLogData = chatStore[findIdx]
    if(!oldChatLogData || !oldChatLogData.msglist || oldChatLogData.msglist.length == 0){
         //直接替换
         chatStore.splice(findIdx,1,chatLogData)
         return
    }
    //合并------------
    let oldChatLogDataMsgList = oldChatLogData.msglist
    let chatLogDataMsgList = chatLogData.msglist
    let mergedChatDataMsgList = mergeMessageList(oldChatLogDataMsgList,chatLogDataMsgList)
    oldChatLogData.msglist = mergedChatDataMsgList
    //替换
    chatStore.splice(findIdx,1,oldChatLogData)
    
}

//添加本地发送的消息
function pushSendMsgeStore(fid,msgItem){
    let findIdx = chatStore.findIndex(item => item.fid == fid)
    let msgList = []
    if(findIdx >= 0){
        msgList = chatStore[findIdx].msglist
        msgList.push(msgItem)
    }else{
        msgList.push(msgItem)
        let msgData = {
            fid:fid,
            msgList:msgList
        }
        chatStore.push(msgData)
    }
   
}

/**
 * @param {旧消息列表} oldMsgList 
 * @param {新消息列表} newMsgList 
 */
function refreshChatMessage(oldMsgList,newMsgList,type){
    //动作类型 1全部，2新增，3更新，4删除，默认1
    if(type == 1){
        mergeMessageList(oldMsgList,newMsgList)
    }else if(type == 2){
        mergeMessageList(oldMsgList,newMsgList)
    }else if(type == 3){
        mergeMessageList(oldMsgList,newMsgList)
    }else if(type == 4){
        deleteChatLogMessageItem(oldMsgList,newMsgList)
    }
}

/**
 * 新增消息
 * @param {旧消息列表} oldMsgList 
 * @param {新消息列表} newMsgList 
 */
function pushMessageList(oldMsgList,newMsgList){
     //待删除的元素数组
     if(!oldMsgList || oldMsgList.length == 0){
        return
    }
    if(!newMsgList || newMsgList.length == 0 ){
        return
    }
    oldMsgList.push(...newMsgList)
}


/**
 * 删除消息
 * @param {旧消息列表} oldMsgList 
 * @param {新消息列表} newMsgList 
 */
function deleteChatLogMessageItem(oldMsgList,newMsgList){
    //待删除的元素数组
    if(!oldMsgList || oldMsgList.length == 0){
        return
    }
    if(!newMsgList || newMsgList.length == 0 ){
        return
    }

    for(let i = 0;i<newMsgList.length;i++){
        let deleteItem = newMsgList[i]
        let id = deleteItem.id
        if(!id){
            continue
        }
        //暂时未实现
        let deleteIdx = oldMsgList.findIndex(item => item.fid == id)
        if(deleteIdx >= 0){
            oldMsgList.splice(deleteIdx,1)
        }
    }
}

/**
 * 
 * @param {旧消息列表} oldMsgList 
 * @param {新消息列表} newMsgList 
 */
function replaceAllMessageList(oldMsgList,newMsgList){
    if(!newMsgList || newMsgList.length == 0){
        if(!oldMsgList || oldMsgList.length == 0){
            return
        }else{
            //清清除所有
            oldMsgList.splice(0,oldMsgList.length)
        }
    }else{
         //替换所有
         oldMsgList.splice(0,oldMsgList.length,...newMsgList)
    }
}

//合并消息列表并排序
function mergeMessageList(oldMsgList,newMsgList){

    if(!oldMsgList && !newMsgList){
        return
    }

    if(!oldMsgList || oldMsgList.length == 0){
        return pushAll(oldMsgList,newMsgList)
    }

    if(!newMsgList || newMsgList.length == 0){
        return oldMsgList
    }
    
    for(let i =0;i<newMsgList.length;i++){
        let newMsgItem = newMsgList[i]
        let s_uuid = newMsgItem.s_uuid
        let findIdx = -1
        //发送消息的标识
        if(s_uuid){
             findIdx = oldMsgList.findIndex(item => item.s_uuid == s_uuid)
             if(findIdx != -1){
                //替换旧消息的部分内容
                let oldMsgItem = oldMsgList[findIdx]
                oldMsgItem.id = newMsgItem.id
                oldMsgItem.send_status = newMsgItem.send_status
                oldMsgItem.error_msg = newMsgItem.error_msg
                oldMsgItem.send_time = newMsgItem.send_time
                // oldMsgItem.s_uuid = ''
                newMsgItem = oldMsgItem
             }
        }else{
            let id = newMsgList[i].id
            findIdx = oldMsgList.findIndex(item => item.id == id)
        }
        if(findIdx != -1){
            //替换这个元素
            oldMsgList.splice(findIdx,1,newMsgItem)
        }else{
            oldMsgList.push(newMsgItem)
        }
    }
    oldMsgList.sort((item1,item2)=>item1.id - item2.id)
    return oldMsgList
}



export default{
    getAllChatLog,
    getChatLog,
    refreshChatLogStoreAsyn,
    mergeMessageList,
    pushSendMsgeStore,
    refreshChatMessage,
    replaceAllMessageList,
    replaceAllChatlogStore,
    pushMessageList,
}