<template>
  <el-card>
    <div>
      <el-form :model="messageSetForm" label-width="auto">
        <el-form-item>
          <template #label>
            人工接管有效时间(单位秒)
            <el-tooltip placement="bottom" effect="light">
              <template #content>
                <div>
                  <p>开启人工接管状态后N秒后自动关闭人工接管状态</p>
                </div>
              </template>

              <i class="iconfont icon-tishi1"></i>
            </el-tooltip>
          </template>

          <el-input
            v-model="messageSetForm.artificial_active_time"
            type="number"
            :min="1"
            :max="3600"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div style="display: flex; justify-content: flex-end">
        <el-button @click="handleSave" type="primary">保存</el-button>
      </div>
    </template>
  </el-card>
</template>
    <script>
import { props, reactive,computed, ref, onMounted, nextTick, onUnmounted } from "vue";
import * as echarts from "echarts";
import { useStore } from "vuex";
import {changeArtificialActiveTime}  from "@/api/userapi";
export default {
  name: "myleads_today",

  props: { title: { type: String, default: "" } },
  setup(props) {
    const store = useStore();
    const userInfo = computed(() => {
      return store.state.user.user;
    });

    let messageSetFormState = {
      artificial_active_time: 0,
    };
    let messageSetForm = reactive({
      ...messageSetFormState,
    });

    // --- 方法 --------
    const handleSave = ()=>{
        let data = {artificial_active_time:messageSetForm.artificial_active_time}
        changeArtificialActiveTime(data)
    }
    const handleInitPage = ()=>{
        messageSetForm.artificial_active_time = userInfo.value.artificial_active_time
    }
    onMounted(handleInitPage);
    return {
      messageSetForm,
      userInfo,

      handleSave,
    };
  },
};
</script>
    <style lang="scss" scoped>
</style>