/**
 * 引入公共组件
 */
// 引入饿了么ElementPlus组件库
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import V3Layer from '../components/v3layer'
import V3Scroll from '@components/v3scroll'
import WinBar from '../layouts/winbar.vue'
import SideBar from '../layouts/sidebar'
import Middle from '../layouts/middle'
import Left from '../layouts/left'
import Sidebarleft from '../layouts/sidebarleft'
import SidebarRight from '../layouts/sidebarRight'
import Utils from './utils'
import WsCmd from './wsCmd'
import eventbusCms from './eventbusCms'
import myRegion from './myRegion'
import Moment from './moment'
import chatlogstore from './chatlogstore'
import eventbus from './eventbus'



const Plugins = app => {
  app.use(ElementPlus)
  app.use(V3Layer)
  app.use(V3Scroll)
  app.use(eventbus)

  app.component('WinBar', WinBar)
  app.component('SideBar', SideBar)
  app.component('Left', Left)
  app.component('Sidebarleft', Sidebarleft)
  app.component('Middle', Middle)
  app.component('SidebarRight', SidebarRight)
  app.provide('utils', Utils)
  app.provide('wsCmd', WsCmd)
  app.provide('myRegion', myRegion)
  app.provide('eventbusCms', eventbusCms)
  app.provide('momentUtil', Moment)
  app.provide('chatlogstore', chatlogstore)
}
export default Plugins