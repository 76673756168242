<!-- //右上角按钮 -->
<template>
  <div class="nt__winbar flexbox flex-alignc">
    <div class="nt__winbar-btns">
    <!-- <a class="wbtn" title="最小化"><i class="iconfont icon-min"></i></a>
    <a class="wbtn" :title="store.state.user.isWinMaximize ? '向下还原' : '最大化'" @click="handleMaximize"><i class="iconfont" :class="store.state.user.isWinMaximize ? 'icon-restore' : 'icon-max'"></i></a> -->
    <!-- <a class="wbtn close" title="关闭"><i class="iconfont icon-quit"></i></a> -->
    </div>
    </div>
</template>
<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
export default {
    setup() {
      const store = useStore()
        onMounted(() => {let _isWinMax = localStorage.getItem('isWinMaximize') || store.state.user.isWinMaximize; store.commit('user/SET_WINMAXIMIZE', JSON.parse(_isWinMax));
        })
     const handleMaximize = () => {store.commit('user/SET_WINMAXIMIZE', store.state.user.isWinMaximize ? false : true)
        }
        return {store, handleMaximize
     }
  }
}
</script>
