/**
 * 常用函数
 */
let _doc = document
let _docEl = document.documentElement
let scrollBarWidth
const domUtils = {
    getScrollBarSize: function() {
    if (scrollBarWidth !== undefined) return scrollBarWidth;
        const outer = document.createElement('div');
     outer.style.visibility = 'hidden';
        outer.style.width = '100px';
    outer.style.position = 'absolute';
     outer.style.top = '-9999px';
     document.body.appendChild(outer);
     const widthNoScroll = outer.offsetWidth;
     outer.style.overflow = 'scroll';
        const inner = document.createElement('div');
     inner.style.width = '100%';
    outer.appendChild(inner);
      const widthWithScroll = inner.offsetWidth;
     outer.parentNode.removeChild(outer);
     scrollBarWidth = widthNoScroll - widthWithScroll;
        return scrollBarWidth;
    },
 generateId: function() {return Math.floor(Math.random() * 10000);
    },
    convert: function(str) {
      const c = str.charAt(0);
    return c.toUpperCase() + str.replace(c, '');
    },
    scroll: function(type) {
     const _s = this.convert(type);
    return _docEl['scroll' + _s] || _doc.body['scroll' + _s];
    },
    client: function(type) {
     const _s = this.convert(type);
     return _docEl['client' + _s] || _doc.body['client' + _s];
    },
    getFollowRect: function(follow, oW, oH) {
     let followo;
     let t = typeof follow;
    let rT, rL, sT, sL, _top, _left, _placement;
      let wW = this.client('width');
    let wH = this.client('height');
        // 定位元素（class或id .xxx|#xxx）
        if(t == 'string') {
         followo = document.querySelector(follow);
         rT = Math.round(followo.getBoundingClientRect().top);
            rL = Math.round(followo.getBoundingClientRect().left);
            sT = rT + this.scroll('top');
        sL = rL + this.scroll('left');
            if((rT + oH + followo.offsetHeight) > wH) {
             _top = sT - oH;
            _placement = 'top';
         }else {
              _top = sT + followo.offsetHeight;
                _placement = 'bottom';
         }
         _left = (rL + oW) > wW ? (sL - oW + followo.offsetWidth) : sL;
        } 
        if(t == 'object') {
         rT = follow[1];
        rL = follow[0];
          sT = rT + this.scroll('top');
            sL = rL + this.scroll('left');
         _top = (rT + oH) > wH ? (sT - oH) : sT;
         _left = (rL + oW) > wW ? (sL - oW) : sL;
        }
      return [_left, _top, _placement];
    },
    getZIndex: function(zIndex) {
    for(var $idx = parseInt(zIndex), $elm = document.getElementsByTagName('*'), i = 0, len = $elm.length; i < len; i++)
         $idx = Math.max($idx, $elm[i].style.zIndex)
      return $idx;
    },
    // 获取样式
    getStyle: function(el, styleName) {
     if(!el || !styleName) return;
        return el.currentStyle ? el.currentStyle[styleName] : _doc.defaultView.getComputedStyle(el, null)[styleName];
    },
    on: function(el, event, handle) {el.attachEvent ? el.attachEvent('on' + event, handle) : el.addEventListener(event, handle, false);
    },
    off: function(el, event, handle) {el.detachEvent ? el.detachEvent('on' + event, handle) : el.removeEventListener(event, handle, false);
    },
    isIE: function() {return !!window.ActiveXObject || 'ActiveXObject' in window
 }
}
export default domUtils