<template>
     <div class="flex_center" style="margin-right: 10px;">
          <span class="rlay_r" :class="noticeClass">
            提示音
            <el-tooltip
                  content="开启后有新的消息将会提示音提示"
                  placement="bottom"
                  effect="light"
                >
                  <i class="iconfont icon-tishi1"></i>
                </el-tooltip>
          </span>
          <el-switch
            v-model="noticeStatusRef" 
            inline-prompt
            size="small"
            active-text="打开"
            inactive-text="关闭"
            active-value="1"
            inactive-value="0"
            @change = "handleToggleNoticeStatus"
          />
      </div>
      <div>
        <div v-for="item in autioListRef" :key="item">
          <div  style="margin: 5px;">
          <i @click="handleCheckItem(item)" class="iconfont icon-gouxuan1" :class="{m_color_s:item==checkedRadioRef}"></i>  提示音[{{ item }}]  <i style="cursor: pointer;" class="iconfont icon-play"  @click="handlePlay(item)"></i>
          </div>
        </div>
        <audio ref="audioRef" controls controlslist="nodownload noplaybackrate" style="display: none;">
            <source :src="audioSrc">
          </audio>
      </div>
</template>
  <script>
import { props, ref, onMounted, computed, onUnmounted } from "vue";
import { useStore } from "vuex";
import defaultAudio from '@/assets/audio/notice1.mp3'
import _ from 'lodash';
import { ElMessage } from 'element-plus'
export default {
  name: "myleads_today",
  setup(props) {
    const store = useStore();
    const playItem = ref(1)
    const noticeStatusRef = ref('0')
    const audioRef = ref()
    const audioSrc = ref(defaultAudio)

    const autioList = _.range(1, 10);

    const autioListRef = ref([...autioList])

    const checkedRadioRef = computed(() => {
      return store.state.profile.noticeItem;
    });

    //提醒声音是否开启
    const noticeStatus = computed(()=>{
      return store.state.profile.noticeStatus
    })

    const noticeClass = computed(()=>{
      if(noticeStatus.value === '1'){
        return "online";
      }
      return "offline";
    })

    const handlePlay = (item)=>{
      playItem.value = item
      audioSrc.value = require(`@/assets/audio/notice${item}.mp3`)
      audioRef.value.load()
      audioRef.value.play()
    }

    const handleCheckItem = (item)=>{
      store.commit("profile/CHANE_NOTICE_ITEM",item);
       //本地清空
       ElMessage({
        showClose: true,
        message: '操作成功',
        type: 'success',
      })
    }

    const handleToggleNoticeStatus = (flag)=>{
      if(flag === '1'){
        store.commit("profile/OPEN_NOTICE");
      }else{
        store.commit("profile/CLOSE_NOTICE");
      }
    }
    onMounted(()=>{
      noticeStatusRef.value = store.state.profile.noticeStatus
    });
    return {
      noticeStatusRef,
      noticeClass,
      autioListRef,
      playItem,
      audioRef,
      defaultAudio,
      audioSrc,
      checkedRadioRef,
      handleToggleNoticeStatus,
      handlePlay,
      handleCheckItem,
    };
  },
};
</script>
  
  <style lang="scss" scoped>
</style>