<template>
  <div class="middle_container" >

    <div class="right" style="min-width:100%;display: flex;flex-direction: column;" >
      <div class="right_top">
        <div class="ct">
          <div class="t1">
            <el-dropdown @command="handlecCommandChange">
              <span class="el-dropdown-link" style="display: flex;align-items: center;">
                <i class="iconfont icon-shousuo" style="margin-right: 10px;"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu >
                  <el-dropdown-item :command="{key:1,desc:'标记账号消息已读（'}"> <i class="iconfont icon-icon_clean" style="margin-right: 10px;"></i>标记账号消息已读（{{chatingAccount.new_msg_count}}） </el-dropdown-item>
                  <el-dropdown-item :command="{key:2,desc:'未读'}"> <i class="iconfont icon-978weiduxinxi" style="margin-right: 10px;"></i>未读 （{{chatingAccount.new_msg_count}}）</el-dropdown-item>
                  <el-dropdown-item :command="{key:3,desc:'已开口'}"> <i class="iconfont icon-shuohuaspeak1 speack_icon" style="margin-right: 10px;"></i>已开口 </el-dropdown-item>
                </el-dropdown-menu>
              </template> 
            </el-dropdown>


            <el-input v-model="searchKeywords"  size="small" style="padding:0" placeholder="搜索好友"  >
              <template #prefix>
                <i class="iconfont icon-search"></i>
              </template>
            </el-input>
          </div>
          <div class="t2" v-if="accountFriendList?.length > 0">
            <!-- 最新消息的好友 -->
            <div class="newest_item"  v-for="(item, index) in accountFriendList.slice(0,8)" :key="index"
            :class="{ newest_item_on: item.cid === selectedAccountFriendId }"  @click="handleGoUrl(item)">
              <div v-if="item.avatar" class="newest_item_i">
                  <div  class="newest_item_c">
                    <img :src="item.avatar" /> 
                    <!-- <em  v-if="item.new_msg_count && item.cid != selectedAccountFriendId" class="friend_vui__badge">{{ item.new_msg_count }}</em> -->
                  </div>
              </div>
              <div class="newest_item_n">
                {{ item.title }}
              </div>
                <em  v-if="item.new_msg_count && item.cid != selectedAccountFriendId" class="item_vui__badge">{{ item.new_msg_count }}</em>
            </div>
          </div>
          <div class="slider-demo-block" style="padding:0 10px;">
            <span style="color:#9ea0a3">已加载好友数:{{ showNum }}/{{ allFriendsNum }}</span>
            <el-slider v-model="showNum" :min="0" :max="allFriendsNum"/>
          </div>
          <div>
            <el-tag style="margin-right: 2px;" type="primary" v-for="item in filterRef" :key="item.key" closable @close="handleRemoveFilter(item)">{{ item.desc }}</el-tag>
          </div>
        </div>
      </div>
      <div style="flex:1;height: 1px;">
        <el-scrollbar >
          <div class="ntSide__view flex1 flexbox flex-col">
          <div class="nt-cellList flexbox flex-col">
                <div
                  v-for="(item, index) in accountFriendList"
                  :key="index"
                  class="item flexbox flex-alignc vui__material"
                  :class="{ on: item.cid === selectedAccountFriendId }"
                  @click="handleGoUrl(item)"
                >

                  <div style="display: flex;width:100%">
                    <div v-if="item.avatar" style="position: relative;">
                      <div  class="img friend_img_container">
                        <img :src="item.avatar" /> 
                        <!-- <em  v-if="item.new_msg_count && item.cid != selectedAccountFriendId" class="friend_vui__badge">{{ item.new_msg_count }}</em> -->
                      </div>
                      <em  v-if="item.new_msg_count && item.cid != selectedAccountFriendId" class="friend_vui__badge">{{ item.new_msg_count }}</em>
                    </div>
                    <div class="info flex1 friend_item">
                      <div class="r1 flex1">

                        <!-- AI接管或人工接管 -->
                       <!-- <div v-if="chatingAccount.use_robot_status === 1">
                         <el-tooltip
                              class="box-item"
                              effect="dark"
                              :content="item.is_artificial == 0?'AI接管':'人工接管,人工接管状态追粉消息和AI消息都不会发送'"
                              placement="top">
                            <i v-if="item.is_artificial === 0" class="iconfont icon-zhinengkefuzhongxin m_desc_color m_color_s22" style="margin-right: 2px;" ></i>
                            <i v-else class="iconfont icon-male m_desc_color m_color_s22" style="margin-right: 2px;" ></i>
                          </el-tooltip>
                       </div> -->
                       <div>
                        <el-tooltip
                              class="box-item"
                              effect="dark"
                              :content="handleGetArtificialTipMsg(item)"
                              placement="top">
                            <!-- <i v-if="item.is_artificial === 0" class="iconfont icon-male m_desc_color " style="margin-right: 2px;" ></i> -->
                            <i v-if="item.is_artificial === 1" class="iconfont icon-male m_desc_color m_color_s22" style="margin-right: 2px;" ></i>
                          </el-tooltip>
                       </div>

                        <h2 class="title r1_l ">
                          <span class="m_color_2">  {{ item.title || "--" }}</span>
                        </h2>
                        <div class="time flex-selft r1_r">{{ handleDateTimeFormat(item.time) }}</div>
                      </div>
                      <div class="r2">
                        <p class="desc">
                          <span v-if="item.have_customer_flg>0">
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              :content="item.save_customer_flg == 1?'线索已保存':'线索未保存'"
                              placement="top">
                              <el-tag v-if="item.save_customer_flg == 1"  size="small m_friend_icon" style="margin-right: 2px;">线索</el-tag>
                              <el-tag  v-else type="warning" size="small m_friend_icon" style="margin-right: 2px;">线索</el-tag>
                            </el-tooltip>
                          </span>
                         
                          <span v-else>
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              :content="handleSpeakStr(item.is_interact)"
                              placement="top">
                              <i class="iconfont icon-shuohuaspeak1 speack_icon m_friend_icon" v-if="item.is_interact == 1"></i>
                              <i class="iconfont icon-shuohuaspeak nospeack_icon m_friend_icon" v-else></i>
                            </el-tooltip>
                          </span>
                          {{ item.subtit || "--" }}
                        </p>
                      </div>
                    </div>
                  </div>          
                </div>
              </div>
          </div>
        </el-scrollbar>
      </div>
    
     
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, inject, computed,onMounted,ref,watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'


const recordJSON = require("@/mock/recordlist.js");
import {notifyAccountMsgReaded} from "@/api/chatapi";
export default {
  setup() {
    const router = useRouter();
    const v3layer = inject("v3layer");
    const momentUtil = inject("momentUtil");

    const bus = inject("$eventBus");
    const wsCmd = inject("wsCmd");
    const eventbusCms = inject("eventbusCms");

    const DEFAULT_SHOW_NUM = 50
    const showNum = ref(DEFAULT_SHOW_NUM)
    const allFriendsNum = ref(0)


    /**
     * {key:2,desc:'未读'},
     * {key:3,desc:'已开口'},
     * {key:4,desc:'未开口'}
     */
    const filterRef = ref([])

    let store = useStore();

    const data = reactive({
      searchKeywords:'',
      recordList: recordJSON,
      selectAccountFriendRow: {},
     
    });

    //切换好友Ai接管状态
    const handleUseRobotStatusChange = ()=>{

    }

    //好友人工接管提示信息
    const handleGetArtificialTipMsg = (item)=>{
      let msg = ""

      let AiMsg = "Ai消息(如果AI启用)，"
      //后台AI接管开启
      // if(chatingAccount.value.use_robot_status === 1){
      //   AiMsg = ""
      // }

      //人工接管开启
      if(item.is_artificial == 1){
        msg = `人工接管状态【开启】：${AiMsg}追粉消息和欢迎语【不会发送】`
      }else{
        msg = `人工接管状态【关闭】：${AiMsg}追粉消息和欢迎语【正常发送】`
      }
       return msg
    }

    //当前选中平台
    // const chatingPlatId = computed(() => {
    //       return store.state.wsData.chatingPlatId
    // });

     //当前聊天账号
     const chatingAccount = computed(() => {
      return store.state.wsData.chatingAccountInfo;
    });

    //当前选中账号
    const selectedAccountId = computed(() => {
      let chatLink = store.state.wsData.platAccountActiveMap.find(
        (item) => item.chatingPlatId == store.state.wsData.chatingPlatId
      );
      let chatingAccountId = store.state.wsData.chatingAccountId || chatLink?.chatingAccountId;
      return chatingAccountId
    });

    //当前选中好友
    const selectedAccountFriendId = computed(() => {
      let chatLink = store.state.wsData.accountFriendActiveMap.find(
        (item) => item.chatingAccountId == selectedAccountId.value
      );
      let chatingFriendId = store.state.wsData.chatingFriendId || chatLink?.chatingFriendId;
      // if(!store.state.wsData.chatingFriendId){

      // }
      return chatingFriendId
    });

    // const platAccountActiveMap = computed(()=>{
    //   return    store.state.wsData.platAccountActiveMap
    // })

    //好友列表
    function receiveAccountFriendList(accountFriendListResp) {

      if (accountFriendListResp && accountFriendListResp.code === 1) {
        store.commit("wsData/SET_ACCOUNT_FRIEND_LIST", accountFriendListResp);
        // 如果是，子命令：3_1   好友聊天人工接管标志变更,此处处理是为了兼容刷新页面后第一次
        let sub_t = accountFriendListResp.sub_t
        if(sub_t === '3_1'){
           let updateFriends = accountFriendListResp?.data?.friendlist
           if(updateFriends?.length > 0){
            let updateFriend = updateFriends[0]
            if(updateFriend.cid === selectedAccountFriendId.value){
              //更新好友的AI接管标识
              store.commit("wsData/SET_CHATING_FRIEND_AIFLAG", updateFriend.is_artificial);
            }
           }
        }
      }
    }

    //设置所有好友个数
    const handleSetAllFriendCount = (num)=>{
      allFriendsNum.value = num
    }
    //设置显示好友的个数
    const handleShowFriendCount = (num)=>{
      showNum.value = num
    }
  

  
    //账号列表
    const accountFriendList = computed(() => {
      let _accountFriendListDataStore =
      store.state.wsData.accountFriendList.find((item) => item.aid == selectedAccountId.value);
      let friendList = _accountFriendListDataStore?.friendlist;
      if (friendList && friendList.length > 0) {
        //如果没设置选中账号则处理
        let chatingFriendId = store.state.wsData.chatingFriendInfo?.cid;
        if (!chatingFriendId) {
          let chatingFriend = friendList.find(
            (item) => item.cid == selectedAccountFriendId.value
          );
          if (chatingFriend?.cid) {
            //store.commit("wsData/SET_CHATING_FRIEND", chatingFriend);
            handleGoUrl(chatingFriend);
          } else {
            //默认选中第一条
            handleGoUrl(friendList[0]);
          }
        }
      }
      if(!friendList){
        return []
      }
     
      handleSetAllFriendCount(friendList.length)

      if(data.searchKeywords){
        friendList  =  friendList.filter(item=>haldleFilterFriend(item,data.searchKeywords)) || [];
        //handleShowFriendCount(resultList.length)
      }

      //过滤条件
      if(filterRef.value.length > 0){
        friendList = friendList.filter(item=>{
          for(let v of filterRef.value){
            let res = false
            //未读--
            if(v.key == 2){
              res = item.new_msg_count > 0
              if(!res){
                return false
              }
            }
            //已开口--
            if(v.key == 3){
              res = item.is_interact == 1 ? true:false
              if(!res){
                return false
              }
            }
          }
          return true
        })
      }


      //按照最新发送时间排序
      friendList = friendList.sort((item1,item2)=>{
        if(!item1.time && !item2.time){
          return 0
        }

        if(!item2.time){
          return -1
        }

        if(!item1.time){
          return 1
        }

        let item1Time = momentUtil.moment(item1.time, "YYYY-MM-DD HH:mm:ss")
        let item2Time = momentUtil.moment(item2.time, "YYYY-MM-DD HH:mm:ss")
      
        if(item1Time.isAfter(item2Time)){
          return -1
        }else if(item2Time.isAfter(item1Time)){
          return 1
        }else{
          return 0
        }
      })

      let friendsNum = friendList.length
      //  let _showNum = showNum.value > friendsNum?friendsNum:showNum.value;
      let _showNum = showNum.value
      if(_showNum === 0 || _showNum < DEFAULT_SHOW_NUM){
        _showNum = Math.min(friendsNum,DEFAULT_SHOW_NUM)
      }

      handleShowFriendCount(_showNum)
      return friendList.slice(0,_showNum)
    });

    //加载更多
    const handleShowMore = ()=>{
      let _num = showNum.value + DEFAULT_SHOW_NUM
      if(_num > allFriendsNum.value){
        _num = allFriendsNum.value
      }
      showNum.value = _num

    }

  
    //过滤好友
    const haldleFilterFriend = (friendItem,searchKeyWords)=>{
      if(searchKeyWords){
        let _searchKeywords = searchKeyWords.replace(/^\s\s*/, '').replace(/\s\s*$/, '')
        if(_searchKeywords.length == 0){
          return true
        }
        let title = friendItem.title
        if(!title){
          return false
        }
        return title.includes(_searchKeywords)
      }else{
        return true
      }
    }

    //好友右键菜单
    const handleContextLayer = (e) => {
      e.preventDefault();
      let pos = [e.clientX, e.clientY];
      let $el = v3layer({
        type: "contextmenu",
        opacity: 0,
        follow: pos,
        btns: [
          { text: "置顶" },
          { text: "标为已读" },
          { text: "开启新消息提醒" },
          { text: "设置备注" },
          {
            text: "删除",
            click: () => {
              $el.close();
            },
          },
        ],
      });
    };
    const handleGoUrl = (item) => {


      store.commit("wsData/SET_CHATING_FRIEND", item);
      //因为是多账号，这里需要记住哪个账号激活的是哪个好友聊天
      router.push({ name: "groupChat", query: { id: item.cid } });
      bus.emit(eventbusCms.selectFriendChange,item.cid)
      item.new_msg_count = 0
    };

    const handleDateTimeFormat = (dateStr)=>{
      if(!dateStr){
        return '';
      }
      let timeMoment = momentUtil.moment(dateStr, "YYYY-MM-DD HH:mm:ss")
      if(timeMoment.isSame(momentUtil.moment(), "day")){
        return timeMoment.format('HH:mm')
      }
      return timeMoment.fromNow();
    }
    //当前聊天好友
    const chatingFriendInfo = computed(() => {
      return store.state.wsData.chatingFriendInfo;
    });

    //标记账号消息已读
    const handleNotifyAccountMsgReaded = ()=>{
      notifyAccountMsgReaded({aid:selectedAccountId.value})
      for(let i=0;i<accountFriendList.value.length;i++){

        console.log(accountFriendList.value[i].new_msg_count)
        accountFriendList.value[i].new_msg_count = 0
      }

      //本地清空
      ElMessage({
        showClose: true,
        message: '操作成功',
        type: 'success',
      })
    }


    // 过滤条件变更---------------------------
    const handlecCommandChange = (cmd)=>{
      switch(cmd.key){
        case 1:
            handleNotifyAccountMsgReaded()
          return;
        default:
          if(!filterRef.value.some(item=>item.key == cmd.key)){
            filterRef.value.push(cmd)
          }
          return
      }
    }
    // 移除过滤条件------------------------
    const handleRemoveFilter = (item)=>{
      let findIdx = filterRef.value.map(item=>item.key).indexOf(item.key)
      if(findIdx != -1){
        filterRef.value.splice(findIdx,1)
        showNum.value = DEFAULT_SHOW_NUM
      }
    }

    const handleSpeakStr = (is_interact)=>{
      return is_interact==1?"已开口":"未开口"
    }

    onMounted(() => {
      //当前选中好友
      handleGoUrl(chatingFriendInfo.value)
      showNum.value = DEFAULT_SHOW_NUM
      bus.on(`${wsCmd.cmd_3_resp}`, receiveAccountFriendList); // listen
    });

    //---------监听器--------------------
    // 单个 ref
    watch(selectedAccountId, () => {
      showNum.value = DEFAULT_SHOW_NUM
    })

    watch(
      () => data.searchKeywords,
      (newValue)=>{
        if(!newValue){
          showNum.value = DEFAULT_SHOW_NUM
        }
      }
  )
    return {
      ...toRefs(data),
      accountFriendList,
      selectedAccountFriendId,
      selectedAccountId,
      chatingFriendInfo,
      showNum,
      allFriendsNum,
      chatingAccount,
      filterRef,
      haldleFilterFriend,
      handleContextLayer,
      handleGoUrl,
      handleDateTimeFormat,
      receiveAccountFriendList,
      handleNotifyAccountMsgReaded,
      handleSpeakStr,
      handleSetAllFriendCount,
      handleShowFriendCount,
      handleShowMore,
      handlecCommandChange,
      handleRemoveFilter,
      handleUseRobotStatusChange,
      handleGetArtificialTipMsg,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.el-input__inner){
  height: 25px;
  // background-color: red;
  border:none;
  background-color: rgba(255, 255, 255, 0.9);
}

.right_top_tip{
  padding-left: 10px;
  width:100%;
  box-sizing: border-box;
  height: 15px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  color:#9ea0a3;
  font-size: 10px;
  position:absolute;
  bottom: 0;
  .right_top_tip_n{
    // 
    color:rgb(0, 139, 139);
  }
  .right_top_tip_go{
    padding-right: 5px;
    margin-left: auto;
    color: rgba(64,158,255, 1);
  }
  .right_top_tip_go1{
    padding-right: 5px;
    margin-left: auto;
  }
  .right_top_tip_go:hover{
    cursor: pointer;
  }
}

.right_top{
  min-height:55px;
  box-sizing: border-box;
  position:relative;
  display: flex;
  align-items: center;
  padding:5px;
  // .right_top_util{
  //    height: 30px;
  // }
  .ct{
    min-width:270px;
    flex:1;
    .t1{
      box-sizing: border-box;
      display: flex;
      padding-right:5px;
      padding-left:5px;
    }
    .t2{
      display: flex;
      height:50px;
      align-items: center;
      box-sizing: border-box;
      margin-top:5px;
      overflow: hidden;
      .newest_item_on {
          /* background-color: #dedede */
          background-color: rgba(223,229,244, 0.9)
      }
      .newest_item{
        padding-top: 2px;
        cursor: pointer;
        width:50px;
        height:50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 2px;
        border-radius: 5px;
        .newest_item_n{
          margin-top:2px;
       
          width:40px;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
          white-space: nowrap;
          font-size: 10px;
          color:#9ea0a3;
        }
        .newest_item_i{
          
          width:30px;
          height:30px;
          border-radius: 100%;
          overflow: hidden;
          position: relative;
          flex-shrink: 0;
          flex-grow: 0;
          .newest_item_c img{
            width:30px;
            height:30px;
          }
        }
        .item_vui__badge{
            position:absolute;
            top: 0px;
            right: 0px;
          }
    
      }
      .newest_item:hover{
        background-color: #e2e2e2
      }
    }
  }


  .right_top_input{
    display: flex;
    align-items: center;
    padding:10px;
    box-sizing: border-box;
  }
  .right_top_util{
    box-sizing: border-box;
    padding-right: 10px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .util_item{
      cursor: pointer;
    }
    // .util_item:hover{
    //   background-color: rgba(0, 0, 0, 0.5);
    // }
  }
}
.friend_item {
  box-sizing: border-box;

  .r1 {
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    display: flex;
    .r1_l {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
      // width: 140px;
      box-sizing: border-box;
    }
    .r1_r {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
      width: 60px;
      text-align: right;
    }
  }
  .r2 {
    margin-top: 5px;
    white-space: nowrap;
    box-sizing: border-box;
    p{
      width:200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
      .speack_icon{
        color:rgb(0, 139, 139);
      }
      .nospeack_icon{
        color:#9ea0a3;
      }
    }
  }
}
.middle_container {
  display: flex;
  flex-direction: row;
  width:100%;
  border-radius: 10px;
}
.friend_img_container {
  box-sizing: border-box;
  position: relative;
  width:40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}
.friend_vui__badge {
    position: absolute;
    top: 0px;
    right: 0px;
  }
</style>

