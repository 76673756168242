/**
 * 自定义函数
 */
export default {
  // 随机token
  setToken(len) {
  len = len || 32; let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    let maxLen = $chars.length; let str = '';
  for(var i = 0; i < len; i++){ str += $chars.charAt(Math.floor(Math.random() * maxLen));
    }
    return str;
   },
    // 验证手机号
  checkTel(val) { var reg = 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/;
    if(val == '' || !reg.test(val)){return false;
    }
   return true;
  }
}