<template>
  <div class="ch_container">
    <div class="top">
      <div class="search_container">
        <!-- <el-popover
          ref="popover"
          placement="right"
          title="Title"
          :width="200"
          trigger="focus"
          content="this is content, this is content, this is content"
        >
          <template #reference>
            <el-input
            class="search_input"
            v-model="searchMsg"
            style="width: 240px"
            placeholder="请输入搜索内容"
            ref="findLogInputRef"
          />
          </template>
        </el-popover> -->
        <el-input
          class="search_input"
          v-model="searchMsg"
          style="width: 240px"
          placeholder="请输入搜索内容"
          ref="findLogInputRef"
          :clearable="true"
          @clear="handleCloseSearchHistoryList"
        >
          <template #append>
            <!-- <i  style="cursor: pointer;" class="iconfont icon-search1"></i> -->
            <el-button :icon="Search" ref="popoverDivRef" @click="handleSearchChatlobKeywords"/>
          </template>
        </el-input>
      </div>
    </div>

    <el-popover
      ref="popoverRef"
      title="历史记录"
      :width="500"
      :height="500"
      :virtual-ref="popoverDivRef"
      :visible="chatLoglistVisiable"
      virtual-triggering
    >
    <div>
      <div class="search_header">
            <div class="search_remote">
              <el-button
                type="primary"
                link
                @click="handleSearchChatlobKeywords"
                style="color:#008B8B"
              >
                搜索远程记录
              </el-button>
              <div class="lds-ring" v-if="searchKeyWording">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
      </div>
      <el-scrollbar height="500px">
        <div>
         
          <div
            v-for="item in searchChatLogList"
            :key="item.id"
            @click.stop="handleSearchItemHistory(item)"
          >
            <div class="search_result_item">
              <div class="left">
                <img :src="item.avatar" width="40" height="40" />
              </div>
              <div class="right" >
                <div class="r1">
                  <div class="nickName">
                    {{ item.author }}
                  </div>
                  <div class="time">
                    {{ item.send_time }}
                  </div>
                </div>
                <div class="r2">
                  <div class="msg" >
                    <div class="msg_inner" v-html="handleHighLightSearchMsg(item.msg)"></div>
                  </div>
                  <div class="find_item">点击查看上下文</div>
                  <div class="lds-ring" v-if="item.id == searchMessageId && loadingSearchHistoryItem">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    </el-popover>
    <div class="main">
      <div style="height: 100px" class="main_t">
        <!--正常历史记录-->
        <div class="loadMore" @click="handleLoadMore" v-if="!chatLoglistNomore && !showSearchHistoryList">
          加载更多...
        </div>
        <div class="loadMore" v-else-if="chatLoglistNomore && !showSearchHistoryList">没有更多数据了...</div>

        <!-- 加载搜索历史记录-->
        <div class="loadMore" @click="handleLoadSearchListMore" v-if="!chatSearchLoglistNomore && showSearchHistoryList">
          加载更多...
        </div>
        <div class="loadMore" v-else-if="chatSearchLoglistNomore && showSearchHistoryList">没有更多数据了...</div>



        <el-scrollbar ref="scrollRef">
          <div class="vChatMsg-cnt scrollInner" ref="innerRef">
            <ul class="clearfix" v-if="!showSearchHistoryList">
              <messageItem
                :msgItem="msgItem"
                v-for="msgItem in chatLogList"
                :key="msgItem.id"
              />
            </ul>

            <!--搜索的历史记录查看-->
            <div v-else>
              
              <div class="chatLogTip"><i class="iconfont icon-arrL chatLogTipL" style="width:50px" @click="handleCloseSearchHistoryList"></i>搜索历史记录列表</div>
              <ul class="clearfix">
                <!-- <li v-for="(msgItem,index) in chatLogListHistory"   :key="index">{{  msgItem}}</li> -->
                <messageItem
                  :msgItem="msgItem"
                  :searchKeyWords="searchMsg"
                  v-for="msgItem in chatLogListHistory"
                  :key="msgItem.id"
                />
              </ul>
            </div>
          </div>
        </el-scrollbar>

         <!-- 加载搜索历史记录-->
         <div class="loadMoreBottom" @click="handleLoadSearchListMoreBottom" v-if="!chatSearchLoglistNomoreBottom && showSearchHistoryList">
          加载更多...
        </div>
        <div class="loadMoreBottom" v-else-if="chatSearchLoglistNomoreBottom && showSearchHistoryList">没有更多数据了...</div>
      </div>
    </div>
  </div>
</template>
    <script>
// 引入动态组件（中间栏）
import {
  toRefs,
  onMounted,
  reactive,
  onUnmounted,
  inject,
  computed,
  watch,
  ref,
  unref,
} from "vue";
import messageItem from "@/views/components/messageItem.vue";
import { useStore } from "vuex";
import { getFriendChatLog,searchChatlobKeywords,searchChatlobKeywordsList } from "@/api/chatapi";
import { Search } from "@element-plus/icons-vue";
// import {mergeChatLogList} from "@/utils/arrayutils"
import {mergeListAndSortDesc,mergeListAndSort} from "@/utils/arrayutils"
export default {
  components: { messageItem },
  setup() {
    const bus = inject("$eventBus");
    const eventbusCms = inject("eventbusCms");
    const wsCmd = inject("wsCmd");
    const innerRef = ref(null);
    const scrollRef = ref(null);
    const popoverRef = ref(null);
    const findLogInputRef = ref(null);
    const popoverDivRef = ref(null);

    const chatLoglistVisiable = ref(false);
    const showSearchHistoryList = ref(false);

    let store = useStore();
    const chatlogstore = inject("chatlogstore");
    const data = reactive({
      active_item: 1,
      chatLoglistNomore: false,
      searchMsg: "",
      friendChatLogData: {
        fid: 0,
        msglist: [],
      },
      searchMessageId: 0,

      chatSearchLoglistNomore:false,
      searchKeyWording:false,
      loadingSearchHistoryItem:false,
    });

    const searchKeyWordingUp = ref(true)
    const chatSearchLoglistNomore = ref(false)
    const chatSearchLoglistNomoreBottom = ref(false)
    const searchRemoteChatLogLists = ref([])
    const chatLogListHistoryList = ref([])
    //消息滚动条滚动事件处理
    const handleLoadMore = () => {
      //获取最早一条聊天记录ID
      //本地数据
      let localData = chatlogstore.getChatLog(selectedAccountFriendId.value);
      let minId = 0;
      if (localData && localData.msglist?.length > 0) {
        minId = getMinChatLogId(localData.msglist);
      }
      //当前聊天数据
      if (
        data.friendChatLogData.msglist &&
        data.friendChatLogData.msglist.length > 0
      ) {
        let pageMinId = getMinChatLogId(data.friendChatLogData.msglist);
        if (pageMinId < minId) {
          minId = pageMinId;
        }
      }
      getFriendChatLog({
        fid: selectedAccountFriendId.value, //好友id
        limit: 20, //返回记录数，默认20
        log_id: minId, //上页记录id
      });
    };

    //获取最小的聊天消息ID
    function getMinChatLogId(msgList) {
      if (!msgList || msgList.length == 0) {
        return 0;
      }
      let minId = 0;
      for (var i = 0; i < msgList.length; i++) {
        let item = msgList[i];
        //排除时间类型
        if (item.msgtype == 1) {
          continue;
        }
        minId = item.id;
        break;
      }
      return minId;
    }
    //获取最小的聊天消息ID
    function getMaxChatLogId(msgList) {
      if (!msgList || msgList.length == 0) {
        return 0;
      }
      let maxId = 0;
      for (var i = msgList.length-1; i >=0 ; i--) {
        let item = msgList[i];
        //排除时间类型
        if (item.msgtype == 1) {
          continue;
        }
        maxId = item.id;
        break;
      }
      return maxId;
    }



    //当前聊天好友
    const chatingFriendInfo = computed(() => {
      return store.state.wsData.chatingFriendInfo;
    });

    //当前选中好友
    const selectedAccountFriendId = computed(() => {
      let chatingFriendId = store.state.wsData.chatingFriendId;
      return chatingFriendId;
    });

    function chatLogListChange(fid) {
      if (fid == selectedAccountFriendId.value) {
        let allChatLogList = chatlogstore.getChatLog(
          selectedAccountFriendId.value
        )?.msglist;

        chatlogstore.mergeMessageList(
          data.friendChatLogData.msglist,
          allChatLogList
        );
        data.friendChatLogData.msglist.push({ temp: true });
        //页面不刷新临时解决办法
        let delteIndex = data.friendChatLogData.msglist.findIndex(
          (item) => item.temp == true
        );
        data.friendChatLogData.msglist.splice(delteIndex, 1);
        //console.log(data.friendChatLogData.msglist[data.friendChatLogData.msglist.length -1])
      }
    }

    //没有更多消息
    function handleChatLoglistNomore(fid) {
      if (selectedAccountFriendId.value == fid) {
        data.chatLoglistNomore = true;
      }
    }

    //根据关键字搜索好友聊天记录
    const handleSearchChatlobKeywords = ()=>{
      //去除空格
      let keywords = data.searchMsg
      if(keywords){
        keywords = keywords.split(/[\t\r\f\n\s]*/g).join('')
      }
      if(!keywords){
        return
      }
      data.searchKeyWording = true
      searchChatlobKeywords({
        fid: selectedAccountFriendId.value, //好友id
        limit: 30, //返回记录数，默认20
        keyword: keywords, //上页记录id
      })
    }

    //根据关键词搜索记录结果
    const handleSearchChatlobKeywordsResp = (resp)=>{
      data.searchKeyWording = false
      if(resp.code == 1){
        let data = resp.data
        let fid = data.fid
        if(fid == selectedAccountFriendId.value){
          let respList = data.msglist
         
          if(searchRemoteChatLogLists.value.length > 0){
            searchRemoteChatLogLists.value.splice(0,searchRemoteChatLogLists.value.length,...respList)
          }else{
            searchRemoteChatLogLists.value.push(...respList)
          }
        }
      }
    }

    watch(
      () => chatingFriendInfo,
      (newValue) => {
        data.searchKeyWording = false
        data.loadingSearchHistoryItem = false
        data.friendChatLogData.fid = newValue.value.cid;
        data.chatLoglistNomore = false;
        data.friendChatLogData.msglist = chatlogstore.getChatLog(newValue.value.cid)?.msglist;
        chatSearchLoglistNomore.value = false;
        chatSearchLoglistNomoreBottom.value = false;
        //历史记录列表清空
        handleCloseSearchHistoryList()
      },
      { deep: true }
    );

    watch(
      () => data.searchMsg,
      (newValue) => {
        if (newValue) {
          chatLoglistVisiable.value = true;
        } else {
          chatLoglistVisiable.value = false;
          showSearchHistoryList.value = false;
        }
      }
    );

    const chatLogList = computed(() => {
      let msgList = data.friendChatLogData.msglist;
      return msgList;
    });

    const chatLogListHistory = computed(() => {
      let resultMsgList =  chatLogListHistoryList.value
      return resultMsgList
      // let msgList = data.friendChatLogData.msglist;
      // if (!msgList || msgList.length == 0) {
      //   return [];
      // }
      // let findIdx = msgList.findIndex(
      //   (item) => item.id == data.searchMessageId
      // );
      // if (findIdx == -1) {
      //   return [];
      // }

      // //最多前5条
      // const preCountNum = 3
      // if(findIdx >=preCountNum){
      //   return msgList.slice(findIdx-preCountNum);
      // }else{
      //   return msgList.slice(0);
      // }
    });

    //高亮搜索内容
    const handleHighLightSearchMsg = (msg) => {
        if (!msg) {
          return msg;
        }
        if (msg.includes("color:#008B8B")) {
          return msg;
        }

        if (msg.includes(data.searchMsg)) {
          let searchMsgH = `<span style='color:#008B8B'>${data.searchMsg}</span>`;
          msg = msg.replace(data.searchMsg, searchMsgH);
          // console.log('~~~~~~msgItem.msg',msgItem.msg,data.searchMsg)
          return msg;
        }
        return msg;
    };

    /**
     * 搜索历史记录选项
     */
    const searchChatLogList = computed(() => {
      let msgList = data.friendChatLogData.msglist;
      let remoteMsgList = searchRemoteChatLogLists.value
      if (data.searchMsg) {
        let _searchKeywords = data.searchMsg
          .replace(/^\s\s*/, "")
          .replace(/\s\s*$/, "");
        if (_searchKeywords.length > 0) {
          let resultList = msgList.filter((item) => {
            if (item.msg && item.msgtype==3) {
              let includes = item.msg.includes(_searchKeywords);
              return includes
            }
            return false
          })

          //合并远程索索记录
          resultList = mergeListAndSortDesc(remoteMsgList,resultList)
          return resultList;
        }
      }
      return msgList;
    });

    //从本地初始化聊天记录
    function initChatLogFromLocalStoreMsg() {
      let localData = chatlogstore.getChatLog(selectedAccountFriendId.value);
      if (!localData) {
        data.friendChatLogData.msglist = [];
      } else {
        data.friendChatLogData.fid = localData.fid;
        data.friendChatLogData.aid = localData.aid;
        data.friendChatLogData.msglist = localData.msglist;
      }
    }

    //显示查找历史记录
    function closeSearchItemWindow() {
      unref(popoverRef).popperRef?.delayHide?.();
    }
    //搜索历史记录
    function handleSearchItemHistory(item) {
      data.searchMessageId = item.id;
      data.loadingSearchHistoryItem = true
      //搜索历史记录列表
      searchChatlobKeywordsList({
        "fid":  selectedAccountFriendId.value,//好友id
        "limit":  20,//返回记录数，默认20
        "log_id":  item.id,//搜索时返回msglist的id（传此值时，结果返回紧接着该id之前的聊天信息）
        "page_type":  3,//翻页类型（1向上翻页，2向下翻页，3取前后数据），不传时默认1
      })
    }

    //加载更多历史搜索数据
    const handleLoadSearchListMore = ()=>{
      //标记向上搜索
      searchKeyWordingUp.value = true
       //获取最早一条聊天记录ID
      //本地数据
      let msgList = chatLogListHistoryList.value
      let minId = 0;
      if (msgList && msgList?.length > 0) {
        minId = getMinChatLogId(msgList);
      }
      if(!minId){
        return
      }
     //搜索历史记录列表
     searchChatlobKeywordsList({
        "fid":  selectedAccountFriendId.value,//好友id
        "limit":  20,//返回记录数，默认20
        "log_id":  minId,//搜索时返回msglist的id（传此值时，结果返回紧接着该id之前的聊天信息）
        "page_type":  1,//翻页类型（1向上翻页，2向下翻页，3取前后数据），不传时默认1
      })
    }

    //关键词历史记录向下搜索
    const handleLoadSearchListMoreBottom = ()=>{
       //标记向下搜索
      searchKeyWordingUp.value = false
         //获取最早一条聊天记录ID
      //本地数据
      let msgList = chatLogListHistoryList.value
      let maxId = 0;
      if (msgList && msgList?.length > 0) {
        maxId = getMaxChatLogId(msgList);
      }

      if(!maxId){
        return
      }
     //搜索历史记录列表
     searchChatlobKeywordsList({
        "fid":  selectedAccountFriendId.value,//好友id
        "limit":  20,//返回记录数，默认20
        "log_id":  maxId,//搜索时返回msglist的id（传此值时，结果返回紧接着该id之前的聊天信息）
        "page_type":  2,//翻页类型（1向上翻页，2向下翻页，3取前后数据），不传时默认1
      })
    }

    //搜索历史记录上下文响应结果
    const handleSearchChatlobKeywordsListResp = (resp)=>{
      popoverRef.value.hide();
      showSearchHistoryList.value = true;
      data.loadingSearchHistoryItem = false
      if(resp.code == 1){
        let data = resp.data
        let fid = data.fid

        if(fid == selectedAccountFriendId.value && data.msglist?.length >0){
          let oldMsgList = chatLogListHistoryList.value
          oldMsgList = mergeListAndSort(oldMsgList,data.msglist)
          chatLogListHistoryList.value.splice(0,chatLogListHistoryList.value.length,...oldMsgList)
        }
        if(!data.msglist || data.msglist?.length ==0){
          if(searchKeyWordingUp.value == true){
            chatSearchLoglistNomore.value = true
          }else{
            chatSearchLoglistNomoreBottom.value = true
          }
          
        }
      }
    }

    //
    function handleSearchChatLog() {
      chatLoglistVisiable.value = false;
    }

    //关闭搜索历史记录列表
    const handleCloseSearchHistoryList = ()=>{
      data.searchMsg = ''
      chatSearchLoglistNomore.value = false
      chatSearchLoglistNomoreBottom.value = false
      chatLogListHistoryList.value.splice(0,chatLogListHistoryList.value.length)
      searchKeyWordingUp.value = true
    }

    onUnmounted(() => {
      bus.off(eventbusCms.chatLogListChange);
      bus.off(eventbusCms.chatLogListNomore);
      bus.off(wsCmd.cmd_12_resp)
      bus.off(wsCmd.cmd_13_resp)
    });

    onMounted(() => {
      initChatLogFromLocalStoreMsg();
      //消息变化
      bus.on(eventbusCms.chatLogListChange, chatLogListChange);
      bus.on(eventbusCms.chatLogListNomore, handleChatLoglistNomore);
      bus.on(wsCmd.cmd_12_resp, handleSearchChatlobKeywordsResp);
      bus.on(wsCmd.cmd_13_resp, handleSearchChatlobKeywordsListResp);
    });

    return {
      ...toRefs(data),
      innerRef,
      scrollRef,
      findLogInputRef,
      popoverDivRef,
      popoverRef,
      chatLogList,
      selectedAccountFriendId,
      chatLoglistVisiable,
      searchChatLogList,
      showSearchHistoryList,
      chatLogListHistory,
      searchRemoteChatLogLists,
      chatLogListHistoryList,
      chatSearchLoglistNomore,
      chatSearchLoglistNomoreBottom,
      searchKeyWordingUp,
      handleLoadSearchListMoreBottom,
      handleLoadMore,
      chatLogListChange,
      handleChatLoglistNomore,
      closeSearchItemWindow,
      handleSearchChatLog,
      handleSearchItemHistory,
      handleHighLightSearchMsg,
      handleSearchChatlobKeywords,
      handleSearchChatlobKeywordsResp,
      handleCloseSearchHistoryList,
      handleSearchChatlobKeywordsListResp,
      handleLoadSearchListMore,
      
      Search,
    };
  },
};
</script>
  
<style lang="scss" scoped>
.chatLogTip{
  display: flex;
  align-items: center;
 
}
.chatLogTipL{
  cursor:pointer;
}
.chatLogTipL:hover{
  color: #008b8b;
}
.search_header {
  .search_remote {
    color: #008b8b;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search_remote:hover {
    text-decoration: underline;
  }
}
.search_result_item {
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  align-items: center;
  .left {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  .right {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    height: 50px;
    flex: 1;
    padding: 5px 10px;
    font-size: 11px;
    .r1 {
      height: 20px;
      box-sizing: border-box;
      line-height: 20px;
      display: flex;
      justify-content: flex-end;
      color: #9ea0a3;
      .nickName {
        flex: 1;
        text-align: left;
      }
      .time {
        align-self: flex-end;
      }
    }
    .r2 {
      height: 20px;
      overflow: hidden;
      box-sizing: border-box;
      line-height: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      white-space: nowrap;
      .msg {
        box-sizing: border-box;
        height: 20px;
        line-height: 20px;
        flex: 1;
        width: 10px;
        .msg_inner {
          height: 20px;
          line-height: 20px;
          box-sizing: border-box;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
      .find_item {
        font-weight: normal;
        color: #008b8b;
        width: 80px;
        display: none;
        flex-shrink: 0;
        flex-grow: 0;
      }
      .find_item:hover {
        text-decoration: underline;
      }
    }
  }
}
.search_result_item:hover .right .r2 .find_item {
  display: inline-block;
}

.ch_container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .top {
    height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    box-shadow: 0px 22px 30px -3px rgba(0, 0, 0, 0.1);
    .search_container {
      flex-grow: 1;
      display: flex;
      box-sizing: border-box;
      padding: 0 10px;
      .search_input {
        flex-grow: 1;
      }
    }
  }
  .main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .main_t {
      flex-grow: 1;
      position: relative;

      .loadMore {
        display: inline-block;
        box-sizing: border-box;
        position: absolute;
        top: 0px;
        z-index: 1;
        text-align: center;
        width: 100%;
        color: #9ea0a3;
        font-size: 12px;
        cursor: pointer;
        height: 20px;
      }
      .loadMore:hover {
        color: blue;
      }

      .loadMoreBottom{
        display: inline-block;
        box-sizing: border-box;
        position: absolute;
        bottom: 0px;
        z-index: 1;
        text-align: center;
        width: 100%;
        color: #9ea0a3;
        font-size: 12px;
        cursor: pointer;
        height: 20px;
      }
      .loadMoreBottom:hover {
        color: blue;
      }
    }
  }
}
</style>
       
       