<!-- //中间栏模块 -->
<template>
 <component v-if="!store.state.user.isSideCollapsed" class="nt__middle flexbox flex-col" :is="activeComponent"></component>
</template>
<script>
import {reactive, toRefs, h} from 'vue'
import {useRouter} from 'vue-router'
import { useStore } from 'vuex'
// 引入动态组件（中间栏）
import recordList from '@/layouts/middle/recordList.vue'
import friendList from '@/layouts/middle/friendList.vue'
import favoriteList from '@/layouts/middle/favoriteList.vue'
export default {
    components:{'recordList': recordList,'friendList': friendList,'favoriteList': favoriteList,
      'notDefined': h('div', '中间栏模块未定义！')
    },
    setup() {
      const store = useStore()
      // const route = useRoute();
        const router = useRouter()
        //,from, next
     router.beforeEach((to) => {
       let sidebarRight = to.meta.sidebarRight
       if(sidebarRight){
        data.activeComponent = 'recordList'
        // console.log(from)
        // next()
        return
       }
       

      // console.log(router)
        const path = to.path
         data.activeComponent = (
            path == '/' ? 
            'recordList'
             : 
             path == '/chat/group-chat' ? 
            'recordList'
             : 
            path == '/contact' ? 
             'friendList'
             :
            path == '/contact/new-friends' ? 
             'friendList'
             : 
             path == '/contact/uinfo' ? 
            'friendList'
             : 
             path == '/my/favorite' ? 
             'favoriteList'
             : 
             'notDefined'
          )
      }) 
    const data = reactive({
      activeComponent: 'recordList'
     })
    return {...toRefs(data),
      store
     }
  }
}
</script>

