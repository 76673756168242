<template>
  <div>
     <div class="ntSide__searNav"><div class="iptbox flexbox"><i class="iconfont icon-search"></i><input class="flex1" name="kw" placeholder="搜索" /></div>
    </div>
    <div class="ntSide__view flex1 flexbox flex-col">
      <v3-scroll autohide>
          <div class="nt-cellList flexbox flex-col"><router-link class="item flexbox flex-alignc vui__material" to="/contact/new-friends">
             <div class="imgico" style="background:#fc9c3a;"><i class="iconfont icon-addfriends2 c-fff fs-24"></i></div>
            <div class="info flex1"><h2 class="title clamp1">新的朋友</h2></div>
                <em class="vui__badge" style="border:0;position:unset;">1</em>
            </router-link>
            <div class="item flexbox flex-alignc vui__material">
              <div class="imgico" style="background:#07c160;"><i class="iconfont icon-qunzu c-fff fs-24"></i></div>
            <div class="info flex1"><h2 class="title clamp1">群聊</h2></div>
            </div>
             <div v-for="(item,index) in friendList" :key="index">
            <h2 class="capital">{{item.capital}}</h2>
            <div v-for="(item2,index2) in item.list" :key="index2" class="item flexbox flex-alignc vui__material" @click="handleGoUrl(item2)" @contextmenu.prevent="handleContextLayer">
            <div class="img"><img :src="item2.avatar" /></div>
              <div class="info flex1"><h2 class="title clamp1">{{item2.nickname}}</h2></div>
             </div>
          </div>
      </div>
      </v3-scroll>
     </div>
  </div>
</template>
<script>
import { reactive, toRefs, inject } from 'vue'
import { useRouter } from 'vue-router'
const friendJSON = require('@/mock/friendlist.js')
export default {
    setup() {
     const router = useRouter()
     const v3layer = inject('v3layer')
    const data = reactive({friendList: friendJSON,
     })
     const handleContextLayer = (e) => {
        e.preventDefault()
      let pos = [e.clientX, e.clientY]
         let $el = v3layer({type: 'contextmenu',opacity: 0,
           follow: pos, btns: [
              {text: '发消息'},{text: '设置备注信息'},
             {text: '删除好友', click: () => {
               $el.close()
             }
            }
          ]
         })
        }
        const handleGoUrl = (item) => {router.push({name: 'uinfo', query: {fid: item.fid}})
        }
     return {...toRefs(data),handleContextLayer, handleGoUrl,
     }
 }
}
</script>

