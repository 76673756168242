
module.exports = [{
     capital: 'A',
    list: [{
      fid: 20210111,avatar: require('@assets/img/uimg/img-avatar13.jpg'),nickname: 'Aimi&大江大河',
          sex: 'female', sign: '一起来交个朋友吧~~',area: '上海 梦幻夜滩'
     }, {
       fid: 20210112,avatar: require('@assets/img/uimg/img-avatar06.jpg'),nickname: '吖吖',
       sex: 'female',sign: '一个人的思想永远没有国界。', area: '澳大利亚 黄金海岸'
     },
     {fid: 20210113,avatar: require('@assets/img/uimg/img-avatar01.jpg'), nickname: 'Andy',
       sex: 'male',sign: '2021遇见不一样的自己！', area: '广东 广州'
      },
     {fid: 20210114, avatar: require('@assets/img/uimg/img-avatar15.jpg'), nickname: '爱丽丝',
       sex: 'female', sign: '努力做个善良的人。', area: '河北 石家庄'
       },
     ]
    },
    {capital: 'B', list: [
      {fid: 20210116, avatar: require('@assets/img/uimg/img-avatar14.jpg'), nickname: '北国风光',
        sex: 'male',sign: '忘不掉你的容颜，让我♥仪神往。', area: '悉尼'
       },
      {fid: 20210115, avatar: require('@assets/img/uimg/img-avatar03.jpg'), nickname: 'Beart',
         sex: 'female', sign: '微笑每一时，快乐每一天。', area: '中国 佳木斯'
     },
      ]
    },
    {capital: 'C', list: [
     {fid: 20210117,avatar: require('@assets/img/uimg/img-avatar08.jpg'), nickname: '财神爷',
      sex: 'male',sign: '我们一起暴富吧！',area: '威尼斯'
      },
   { fid: 20210118, avatar: require('@assets/img/uimg/img-avatar02.jpg'), nickname: 'Cassiel',
      sex: 'female', sign: '无畏做自己、勇往直前！', area: '云南 丽江'
  },]
 },
]