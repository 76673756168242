// 首页
//import request from '@/utils/request'

import store from '@/store'

import wsCmd from '@/plugins/wsCmd'
import {toWebsocketData} from '@/utils/websocketutils'



//修改AI接管状态
//@pb 公用回传参数，如果请求时传递了该参数，该参数原样返回
export function changeAiStatus(data,passback_params) {
    let _data = toWebsocketData(wsCmd.cmd_21,data,passback_params)
    return store.dispatch('websocket/sendObj',_data)
}

//修改好友AI接管状态
//@pb 公用回传参数，如果请求时传递了该参数，该参数原样返回
export function changeFriendAiStatus(data,passback_params) {
    let _data = toWebsocketData(wsCmd.cmd_22,data,passback_params)
    return store.dispatch('websocket/sendObj',_data)
}

