<template>
  <li :id="msgItem.id">
    <div v-if="msgItem.msgtype == 1" class="msgitem time">
      <span>{{ msgItem.msg }}</span>
    </div>
    <div v-if="msgItem.msgtype == 2" class="msgitem notice">
      <span>{{ msgItem.msg }}</span>
    </div>

    <div class="msgitem notice" v-if="showTime">
      <span>{{ handleDateTimeFormat(msgItem.send_time) }}</span>
    </div>

    <textMessage v-if="msgItem.msgtype == 3" :msgItem="msgItem" :searchKeyWords="searchKeyWords"  />
    <!--5 图片消息-->
    <imageMessage v-if="msgItem.msgtype == 5" :msgItem="msgItem"  />
  </li>
</template>
    <script>
// 引入动态组件（中间栏）
import { toRefs, onMounted, reactive, computed,inject } from "vue";
import imageMessage from './imageMessage'
import textMessage from './textMessage'

export default {
  components: {textMessage,imageMessage},
  props: {
    msgItem: { type: [Object], default: ()=>{return {}} },
    upMsgTime:{type:String,default:()=>{
      return ''
    }},
    searchKeyWords:{type:String},
  },
  setup(props) {
    const momentUtil = inject("momentUtil");
    const data = reactive({
      active_item: 1,
    });


    const showTime = computed(()=>{
      if(!props.msgItem.send_time){
        return false
      }
      if(!props.upMsgTime){
        return true
      }
      let time1 = momentUtil.moment(props.msgItem.send_time, "YYYY-MM-DD HH:mm:ss")
      let time2 = momentUtil.moment(props.upMsgTime, "YYYY-MM-DD HH:mm:ss")
      let diff = time1.diff(time2, 'seconds')
      if(diff >60){
        return true
      }
      return false
    })

    const handleDateTimeFormat = (dateStr)=>{
      if(!dateStr){
        return '';
      }
      let timeMoment = momentUtil.moment(dateStr, "YYYY-MM-DD HH:mm:ss")
      if(timeMoment.isSame(momentUtil.moment(), "day")){
        return timeMoment.format('HH:mm')
      }else{
        return dateStr
      }

      // if(momentUtil.moment().diff(timeMoment,"day") >= 1){
      //   return dateStr
      // }
      // return timeMoment.fromNow();
    }
  

    onMounted(() => {});
 
    return {
      ...toRefs(data),
      showTime,
      handleDateTimeFormat,
    };
  },
};
</script>
  
  <style lang="scss" scoped>
</style>
       
       