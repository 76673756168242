import {getToken} from '@/api/qiniuapi'
const state = {
    token:'',
    domain:'',
    uploadurl:'',
    active_time:0,
}


const mutations = {
    SET_QINIU_INFO(state, data) {
        state.token = data.token
        state.domain = data.domain
        state.uploadurl = data.uploadurl
        state.active_time = data.active_time
    },
    CLEAN_QINIU_TOKEN(state){
        state.token = ''
        state.active_time = 0
    },
    GET_QINIU_INFO(){

    }

}

const actions = {
    //更新好友聊天记录
    refreshToken() {
        try{
            getToken()
        }catch(e){
            console.log(e)
        }
        //一分钟之前过期
        // if(state.active_time <= new Date().getTime()/1000 +60){
            //commit('CLEAN_QINIU_TOKEN')
            //重新获取token
     
        // }
    },
}
const getters = {
    getTokenInfo(state){
        if(state.active_time <= new Date().getTime()/1000 +60){
            return {}
        }else{
            return {token:state.token,domain:state.domain,uploadurl:state.uploadurl,active_time:state.active_time}
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
