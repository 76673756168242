
const NOTICE_KEY = 'notice_key'
const NOTICE_ITEM_KEY = 'notice_item_key'

export function openNotice(){
    localStorage.setItem(NOTICE_KEY, '1')
}
export function closeNotice(){
    localStorage.setItem(NOTICE_KEY, '0')
}
export function getNoticeStatus(){
    return localStorage.getItem(NOTICE_KEY) || '1'
}
export function getNoticeItem(){
    return localStorage.getItem(NOTICE_ITEM_KEY) || 1
}
export function setNoticeItem(item){
    return localStorage.setItem(NOTICE_ITEM_KEY,item)
}