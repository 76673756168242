import {AES_Encrypt,AES_Decrypt} from '@/utils/aes'
const RIGHT_BAR_WIDTH = 'right_bar_width'
const REMEMBER_ME_KEY = 'syb_webchat_me'


export function setRightBarWidth(data){
  if(data > 0){
    return localStorage.setItem(RIGHT_BAR_WIDTH, data)
  }

}

export function getRightBarWidth(){
  const rightbarWidth = localStorage.getItem(RIGHT_BAR_WIDTH)
  return rightbarWidth || 0
}


export function getMe(){
  const me = localStorage.getItem(REMEMBER_ME_KEY)
  if(me){
    let meStr = AES_Decrypt(me)
    return JSON.parse(meStr)
  }
  return {}
}
export function rememberPassword(data){
  let dataStr = JSON.stringify(data)
  dataStr = AES_Encrypt(dataStr)
  return localStorage.setItem(REMEMBER_ME_KEY, dataStr)
}
export function unrememberPassword(){
  return localStorage.removeItem(REMEMBER_ME_KEY)
}
