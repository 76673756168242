<template>
  <el-descriptions
    direction="vertical"
    :column="4"
    border
  >
    <el-descriptions-item label="Ctrl+Shift+B">放大显示比例</el-descriptions-item>
    <el-descriptions-item label="Ctrl+Shift+M">缩小显示比例</el-descriptions-item>
    <el-descriptions-item label="Enter">发送消息</el-descriptions-item>
  </el-descriptions>
</template>
  <script>
import { props, ref, onMounted, nextTick, onUnmounted } from "vue";
import * as echarts from "echarts";

export default {
  name: "myleads_today",

  props: { title: { type: String, default: "" } },
  setup(props) {
    //   onMounted(handleInitPage);
    return {};
  },
};
</script>
  
  <style lang="scss" scoped>
</style>