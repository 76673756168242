import {openNotice,closeNotice,getNoticeStatus,getNoticeItem,setNoticeItem} from '@/utils/profileutils'
const state = {
    noticeStatus:getNoticeStatus(),
    //选中的铃声
    noticeItem:getNoticeItem(),
}

const mutations = {
    OPEN_NOTICE(state) {
        state.noticeStatus = '1'
        openNotice()
    },
    CLOSE_NOTICE(state){
        state.noticeStatus = '0'
        closeNotice()
    },
    CHANE_NOTICE_ITEM(state,data){
        state.noticeItem = data
        setNoticeItem(data)
    }
}

const actions = {
 
}
const getters = {
 
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
