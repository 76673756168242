<template>
    <div class="msgitem" :class="textMessageItem.isme ? 'me' : 'others'">
        <router-link v-if="!textMessageItem.isme" class="avatar" to="/contact/uinfo"><img :src="textMessageItem.avatar" /></router-link>
        <div class="content">
            <p class="author">
              <i  v-if="textMessageItem.isme  && textMessageItem.is_aichat == 1"  class="iconfont icon-zhinengkefuzhongxin m_ai_icon"></i>
              {{ textMessageItem.author }}
            </p>
            <div :class="{ me_msg_ontainer: textMessageItem.isme }">
                <div class="lds-ring" v-if="textMessageItem.isme && textMessageItem.send_status == 2">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div v-if="textMessageItem.isme && textMessageItem.send_status === 0">
                    <el-popover placement="top-start" :width="200" trigger="hover" :content=" textMessageItem.error_msg">
                        <template #reference>
                          <i class="iconfont icon-Shapex" style="color:red"></i>
                        </template>
                     </el-popover>
                </div>
            <div>
          <el-popover trigger="contextmenu" >
            <template #reference>
              <div class="msg">
                 <div v-html="handleFormatMsg(textMessageItem.msg)"></div>
              
                  <div v-if="!textMessageItem.isme && (mobileList || wxList || qqList)" style="margin-top: 5px; border:1px solid rgba(0, 0, 0, 0);background-color: rgba(0, 0, 0, 0); border-radius: 5px;">
              
                      <div class="btn_items">
                          <el-button link type="primary" v-if="mobileList" @click.stop="handleSaveCustomMobile"><i class="iconfont icon-phone"></i>保存手机
                              <el-tooltip
                                class="box-item"
                                effect="dark"
                                content="手机空号检测"
                                placement="top-start"
                              >
                                <i @click.stop="handleCheckCustomMobile" style="font-size: 20px;color:#008b8b;" class="iconfont icon-skintest"></i>
                              </el-tooltip>
                          </el-button>
                          <el-button link type="primary" v-if="wxList" @click.stop="handleSaveCustomWeixin"><i class="iconfont icon-weixin"></i>保存微信
                            <el-tooltip
                                class="box-item"
                                effect="dark"
                                content="手机空号检测"
                                placement="top-start"
                              >
                                <!-- <i @click.stop="handleCheckCustomMobile" style="font-size: 20px;color:#008b8b;" class="iconfont icon-skintest"></i> -->
                              </el-tooltip>
                          </el-button>
                          <el-button link type="primary" v-if="qqList" @click="handleSaveCustomQq"><i class="iconfont icon-QQ"></i>保存QQ</el-button>
                          <!-- <el-button link type="primary" v-if="mobileList"><i class="iconfont icon-cloud-upload"></i>空号检测</el-button> -->
                      </div>
              
                      <div class="operator_log_container">
                          <div class="log_item" v-for="(log_item,index) in textMessageItem.operate_log" :key="index">
                             <!-- 这里除了第一条其他的都暂时折叠 -->
                            <div v-show="index ==0">
                                <i class="iconfont icon-a-Arrow-Right2 prefix_icon"></i>
                                {{log_item.create_time}} {{ log_item.name }} <span :class="{content_color:(log_item.type == 2 || log_item.type == 3)}">{{log_item.content  }}</span>
                                <span v-if="log_item.type == 2 || log_item.type == 3">
                                  <br/>
                                  <span v-if="log_item.check_status == 1" class="normal_color"><i class="iconfont icon-circle checkresult_icon" ></i>正常号</span>
                                  <span v-else class="fail_color"><i class="iconfont icon-circle checkresult_icon"></i>非正常号</span>
                                  <span>{{ log_item.check_msg }}</span>
                                </span>
                                <el-button v-if="textMessageItem.operate_log?.length > 1" link type="primary" style="font-size:12px" @click="shoMoreOperatorLog = !shoMoreOperatorLog">
                                  更多记录 
                                  <i class="iconfont icon-arrR" style="font-size:12px" v-if="!shoMoreOperatorLog"></i>
                                   <i class="iconfont icon-arrD" style="font-size:12px" v-else></i>
                                </el-button>
                            </div>

                            <div v-if="index >0 && shoMoreOperatorLog">
                                <i class="iconfont icon-a-Arrow-Right2 prefix_icon"></i>
                                {{log_item.create_time}} {{ log_item.name }} <span :class="{content_color:(log_item.type == 2 || log_item.type == 3)}">{{log_item.content  }}</span>
                                <span v-if="log_item.type == 2 || log_item.type == 3">
                                  <br/>
                                  <span v-if="log_item.check_status == 1" class="normal_color"><i class="iconfont icon-circle checkresult_icon" ></i>正常号</span>
                                  <span v-else class="fail_color"><i class="iconfont icon-circle checkresult_icon"></i>非正常号</span>
                                  <span>{{ log_item.check_msg }}</span>
                                </span>
                            </div>
                          </div>
                      </div>
              
                  </div>
                </div>
              </template>
              <template #default>
                <div>
                  <div class="contextmenu_container" v-if="textMessageItem.isme">
                  <p class="contextmenu_item" @click="handlRevocationMsg(textMessageItem)">撤回</p>
                  </div>
                </div>
                 
              </template>
          </el-popover>

            <div style="clear: both; text-align: center;color:#aaa;font-size: 10px;padding-top: 5px;">
               <p v-if="textMessageItem.is_delete == 1">已删除</p>
               <p v-if="textMessageItem.is_delete == 2">已撤回</p>
            </div>
        </div>
      </div>
    </div>
    <router-link v-if="textMessageItem.isme" class="avatar" to="/contact/uinfo"><img :src="textMessageItem.avatar"/></router-link>

    <!-- 保存用户联系方式 -->
    <el-dialog v-model="checkUserContactdialogVisible" title="用户联系方式保存" width="500" draggable @close="handleCloseUserContact">
      <div>
        <el-radio-group v-model="customContactToSave">
          <el-radio :value="item" v-for="(item,index) in customContactCheckList" :key="index">{{item}}</el-radio>
        </el-radio-group>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="checkUserContactdialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSaveCustomContactDialog">
            保存
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 空号检测 -->
    <el-dialog v-model="testUserContactdialogVisible" title="空号检测" width="500" draggable @close="handleCloseUserContactTest">
      <div>
        <el-radio-group v-model="customContactToTest">
          <el-radio :value="item" v-for="(item,index) in customContactTestList" :key="index">{{item}}</el-radio>
        </el-radio-group>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="testUserContactdialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleTestCustomContactDialog">
            检测
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
// 引入动态组件（中间栏）
import { toRefs, onMounted, reactive, computed,ref } from "vue";
// import { useStore } from "vuex";
import { replaceEmoji } from "@/utils/emoji-util";
import {
    revocationMsg,
    saveCustomContact,
    checkCustomContact,
} from "@/api/chatapi";
import { customClueMatch } from '@/utils/commonUtils'
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
export default {
    components: {},
    props: {
        msgItem: {
            type: [Object],
            default: () => {
                return {};
            },
        },
        searchKeyWords:{
          type:String,
        },
    },
    setup(props) {
      let store = useStore();
      const shoMoreOperatorLog = ref(false)
      const data = reactive({
          active_item: 1,
          mobileList: [],
          wxList: [],
          qqList: [],
          contact_type:0,
          checkUserContactdialogVisible:false,
          //默认弹窗标题
          defaultDialogTitle:'用户联系方式保存',
          operatorStr:'',
          //联系方式列表
          customContactCheckList:[],
          //待操作的联系方式value
          customContactToSave:'',

          //空号检测--start--
          testUserContactdialogVisible:false,
          customContactToTest:'',
          customContactTestList:[],
          //空号检测--end--
      });



      onMounted(() => {
          let matchResult = customClueMatch(props.msgItem.msg)
          data.mobileList = matchResult.mobileList
          data.wxList = matchResult.wxList
          data.qqList = matchResult.qqList
        
      });


      const handleFormatMsg = (msg) => {
          if (!msg || typeof msg != 'string') {
              return msg;
          }
          msg = msg.replace(/\n/g, "<br>");
          msg = msg.replace(/ /g, "&nbsp;");
          msg = replaceEmoji(msg);

          //如果是搜索列表
          if(props.searchKeyWords){
            msg = handleHighLightSearchMsg(msg,props.searchKeyWords)
          }
          return msg;
      };

      //高亮搜索内容
    const handleHighLightSearchMsg = (msg,highlightStr) => {
        if (!msg) {
          return msg;
        }
        if (msg.includes("color:#008B8B")) {
          return msg;
        }

        if (msg.includes(highlightStr)) {
          let searchMsgH = `<span style='color:#008B8B'>${highlightStr}</span>`;
          msg = msg.replace(highlightStr, searchMsgH);
          // console.log('~~~~~~msgItem.msg',msgItem.msg,data.searchMsg)
          return msg;
        }
        return msg;
    };

        const textMessageItem = computed(() => {
            return props.msgItem
        })

        //首条操作记录
        const firstMessageOperatorLog = computed(()=>{
           if(props.msgItem.operate_log?.length >=1){
            return props.msgItem.operate_log[0];
           }
           return null
        })

        //其他操作记录排除第一条的
        const excludeFirstMessageOperatorLog = computed(()=>{
           if(props.msgItem.operate_log?.length >1){
              return props.msgItem.operate_log.slice(1)
           }
           return null
        })



        //当前选中好友
        const selectedAccountFriendId = computed(() => {
            let chatingFriendId = store.state.wsData.chatingFriendId
            return chatingFriendId
        });

        //保存好友联系方式
        const handleSaveCustomContact = (contact_type, contact) => {
            let sendData = {
                fid: selectedAccountFriendId.value, //好友id
                mid: props.msgItem.id, //消息记录id
                type: 1, //操作类型:1=保存好友信息,2=手机号空号检测,3=微信号空号检测
                contact_type: contact_type, //好友信息类型:1=手机号,2=微信号,3=QQ号
                contact: contact, //好友信息内容 手机号，微信号，QQ号
            }
            saveCustomContact(sendData)
        }
        //空号检测
        const handleCheckCustomContact = (contact_type, contact) => {
            let sendData = {
                fid: selectedAccountFriendId.value, //好友id
                mid: props.msgItem.id, //消息记录id
                type: 2, //操作类型:1=保存好友信息,2=手机号空号检测,3=微信号空号检测
                contact_type: contact_type, //好友信息类型:1=手机号,2=微信号,3=QQ号
                contact: contact, //好友信息内容 手机号，微信号，QQ号
            }
            checkCustomContact(sendData)
        }

    

        //dialog保存用户联系方式
        const handleSaveCustomContactDialog = ()=>{
          let contact_type = data.contact_type
          let contact = data.customContactToSave

          if(!contact_type || !contact){
            ElMessage.error('未找到用户联系方式信息')
            return
          }
          data.checkUserContactdialogVisible = false
          handleSaveCustomContact(contact_type,contact)
        }

        //dialog空号检测
        const handleTestCustomContactDialog = ()=>{
          let contact_type = data.contact_type
          let contact = data.customContactToTest

          if(!contact_type || !contact){
            ElMessage.error('未找到用户联系方式信息')
            return
          }
          data.testUserContactdialogVisible = false
          handleCheckCustomContact(contact_type,contact)
        }




        //保存用户手机
        const handleSaveCustomMobile = () => {
          if(!data.mobileList ||  data.mobileList.length == 0){
            ElMessage.error('未找到用户手机号相关信息')
            return
          }
         
          if(data.mobileList.length > 1){
            data.customContactCheckList = data.mobileList
            data.customContactToSave = data.customContactCheckList[0]
            data.contact_type = 1
            data.checkUserContactdialogVisible = true
            return
          }
          let contact = data.mobileList[0]
          handleSaveCustomContact(1, contact)
        }




        //手机号空号检测
        const handleCheckCustomMobile =()=>{
          if(!data.mobileList ||  data.mobileList.length == 0){
            ElMessage.error('未找到用户手机号相关信息')
            return
          }
          data.defaultDialogTitle = '手机号空号检测'
    
          if(data.mobileList.length > 1){
            data.customContactTestList = data.mobileList
            data.customContactToTest = data.customContactTestList[0]
            data.contact_type = 1
            data.testUserContactdialogVisible = true
            return
          }
          let contact = data.mobileList[0]
          handleCheckCustomContact(1, contact)
        }
        //保存用户微信
        const handleSaveCustomWeixin = () => {
          if(!data.wxList ||  data.wxList.length == 0){
            ElMessage.error('未找到用户微信号相关信息')
            return
          }
      
          if(data.wxList.length > 1){
            data.customContactCheckList = data.wxList
            data.customContactToSave = data.customContactCheckList[0]
            data.contact_type = 2
            data.checkUserContactdialogVisible = true
            return
          }
          let contact = data.wxList[0]
          handleSaveCustomContact(2, contact)
        }
        //保存用户QQ
        const handleSaveCustomQq = () => {
          if(!data.qqList ||  data.qqList.length == 0){
            ElMessage.error('未找到用户QQ号相关信息')
            return
          }
         
          if(data.qqList.length > 1){
            data.customContactCheckList = data.qqList
            data.customContactToSave = data.customContactCheckList[0]
            data.contact_type = 3
            data.checkUserContactdialogVisible = true
            return
          }
          let contact = data.qqList[0]
          handleSaveCustomContact(3, contact)
        }

        //撤销消息
        const handlRevocationMsg = (msgitem) => {
            revocationMsg({ id: msgitem.id })
        }

        const handleCloseUserContact = ()=>{
          data.customContactCheckList = []
          data.customContactToSave = ''
          data.contact_type = 0
          data.defaultDialogTitle='用户联系方式保存'
        }


        const handleCloseUserContactTest = ()=>{
          data.customContactTestList = []
          data.customContactToTest = ''
          data.contact_type = 0
        }

        return {
            ...toRefs(data),
            textMessageItem,
            selectedAccountFriendId,
            firstMessageOperatorLog,
            excludeFirstMessageOperatorLog,
            shoMoreOperatorLog,
            handleFormatMsg,
            handlRevocationMsg,
            handleSaveCustomMobile,
            handleSaveCustomWeixin,
            handleSaveCustomQq,
            handleCloseUserContact,
            handleSaveCustomContactDialog,
            handleCheckCustomMobile,
            handleCloseUserContactTest,
            handleTestCustomContactDialog,
        };
    },
};
</script>
  
<style lang="scss" scoped>
.operator_log_container{
  color:#aaa;
  font-size: 12px;
  .log_item{
    padding:2px 5px;
    .prefix_icon{
      color:black;font-size: 12px;
    }
    .content_color{
      color:#008b8b;
    }
    .normal_color{
      color:#67c23a;
    }
    .fail_color{
      color:red;
    }
    .checkresult_icon{
      font-size: 10px;
    }
  }
}
.me_msg_ontainer {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
}

.lds-ring {
    /* change color here */
    color: #1c4c5b;
}

.lds-ring,
.lds-ring div {
    box-sizing: border-box;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid currentColor;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: currentColor transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
       
       