import CryptoJS from 'crypto-js'
 
/**
 * AES 加密
 * @param word: 需要加密的文本
 * KEY: // 需要前后端保持一致
 * mode: ECB // 需要前后端保持一致
 * pad: Pkcs7 //前端 Pkcs7 对应 后端 Pkcs5
 */
const KEY = CryptoJS.enc.Utf8.parse('rwgsfseeqfghujil')
 
export const AES_Encrypt = (plaintext) => {
    //var keyHex = CryptoJS.enc.Base64.parse(KEY);
    var messageHex = CryptoJS.enc.Utf8.parse(plaintext);
    var encrypted = CryptoJS.AES.encrypt(messageHex, KEY, {
      "mode": CryptoJS.mode.ECB,
      "padding": CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
}
 
/**
 * AES 解密
 * @param jsonStr
 */
export const AES_Decrypt = (plaintextBase64) => {
    //var keyHex = CryptoJS.enc.Base64.parse(KEY);
    var decrypt = CryptoJS.AES.decrypt(plaintextBase64, KEY, {
      "mode": CryptoJS.mode.ECB,
      "padding": CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Utf8.stringify(decrypt);
}

