
//消息类型转换，由于前台和后台快捷消息的消息类型不匹配这里做转换
/**
 * 
 * @param {后台消息类型} backMsgTtype 
 */
function messageTypeTrans(backMsgTtype){
    return backMsgTtype
    // if(!backMsgTtype){
    //     return ''
    // }
    // //前台消息类型:1 时间类型，2 通知类型，3 文本消息 ，5图片
    // switch(backMsgTtype){
    //     case 1:
    //         return 3;
    //     case 3:
    //         return 5;
    // }
    // return ''
}


//用户线索响应结果消息模板
const customClueMartchResult = {mobileList:[],wxList:[],qqList:[]}


/**
 * 
 * @param {消息文本} msgText 
 * @returns {mobileList:[],wxList:[],qqList:[]}
 */
function customClueMatch(msgText){
    if(!msgText || !msgText.trim()){
        return customClueMartchResult
    }

    let result = {}
    //手机号判断
    let mobileRegexp = /(?<!\d)1[3-9][0-9]{9}(?!\d)/gm
    //手机号判断需要处理的符号 181 7829　5871   181-7829－5871
    let mobileMsgTextTmp = msgText.replace(/\s+|-+/g,'')
    
    result.mobileList = mobileMsgTextTmp.match(mobileRegexp)




    //微信号判断
    let wxRegexp = /(?<![a-zA-Z])[a-zA-Z][-_a-zA-Z0-9]{5,19}(?![-_a-zA-Z0-9])/gm
    result.wxList = msgText.match(wxRegexp)
    if(result.mobileList && result.mobileList.length > 0){
        if(!result.wxList){
            result.wxList = []
        }
        result.wxList.unshift(...result.mobileList)
    }

    //QQ号判断
    let qqRegexp =  /(?<!\d)[1-9][0-9]{4,9}(?!\d)/gm
    result.qqList = msgText.match(qqRegexp)

    return result
}

export{
    messageTypeTrans,
    customClueMatch,
}