// 首页
//import request from '@/utils/request'

import store from '@/store'

import wsCmd from '@/plugins/wsCmd'
import {toWebsocketData} from '@/utils/websocketutils'



//用户登录
export function login(data) {
    let _data = toWebsocketData(wsCmd.cmd_1,data)
    return store.dispatch('websocket/sendObj',_data)
}


//初始化登录用户系统信息
export function initUserSystemData(data){
    return new Promise((resolve)=>{
        let _data = toWebsocketData(wsCmd.cmd_99,data)
        store.dispatch('websocket/sendObj',_data)
        return resolve()
    })
}

//修改私信客服服务状态
export function changeServiceStatus(data){
    return new Promise((resolve)=>{
        let _data = toWebsocketData(wsCmd.cmd_20,data)
        store.dispatch('websocket/sendObj',_data)
        return resolve()
    })
}
//退出登录
export function logout(){
    return new Promise((resolve)=>{
        let _data = toWebsocketData(wsCmd.cmd_200,{})
        store.dispatch('websocket/sendObj',_data)
        return resolve()
    })
}
//设置人工接管有效时间
export function changeArtificialActiveTime(data){
    return new Promise((resolve)=>{
        let _data = toWebsocketData(wsCmd.cmd_23,data)
        store.dispatch('websocket/sendObj',_data)
        return resolve()
    })
}