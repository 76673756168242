import indexedDb from '@/indexeddb/index'

const ACCOUNT_LIST = 'feihu_chat_accountList'
const ACCOUNT_FRIEND_LIST = 'feihu_chat_accountFriendList'
const PLAT_ACCOUNT_MAP = 'plat_account_map'
const ACCOUNT_FRIEND_MAP = 'account_friend_map'
const SYS_CFG_INFO = "system_config_info"
const SYS_CFG_INFO_INITED = "system_config_info_inited"
const CHATING_USER_ID = "chating_user_id"
const CHATING_ACCOUNT_INFO = 'chatting_account_info'


const USER_KEY_PREFIX = 'user_'

export function setChatingAccountInfo(data){
  localStorage.setItem(CHATING_ACCOUNT_INFO, data)
}
export function getChatingAccountInfo(){
  return localStorage.getItem(CHATING_ACCOUNT_INFO) || {}
}



export function setChatingUserId(data){
  return localStorage.setItem(CHATING_USER_ID, data)
}

export function getChatingUserId(){
  return localStorage.getItem(CHATING_USER_ID)
}

export function setSyCfgInfo(data,chatingUserid){
  if(!data){
    data = {}
  }
  return indexedDb.setItem(`${USER_KEY_PREFIX}${chatingUserid}_${SYS_CFG_INFO}`, data)
}

export function getSyCfgInfo(chatingUserid){
  return new Promise((resolve)=>{
    indexedDb.getItem(`${USER_KEY_PREFIX}${chatingUserid}_${SYS_CFG_INFO}`).then(sysCfgJson=>{
      if (!sysCfgJson) {
        return resolve({})
      }
      return resolve(sysCfgJson)
    })
   
  })
}

export function setSyCfgInited(data){
  if(!data){
    data = false
  }
  return localStorage.setItem(SYS_CFG_INFO_INITED, data)
}

export function getSyCfgInited(){
  const sysCfgDataInited = localStorage.getItem(SYS_CFG_INFO_INITED)
  if(!sysCfgDataInited){
    return false
  }
  return Boolean(sysCfgDataInited)
}




//获取当前用户的账号列表
export function getPlatAccountList(chatingUserid) {
    // console.log('-------------------this.chatingUserid:',chatingUserid)
    let deaultValue = [
      {plat_type:1,plat_type_str:'抖音',accountlist:[]},
      {plat_type:2,plat_type_str:'小红书',accountlist:[]},
      {plat_type:3,plat_type_str:'快手',accountlist:[]},
    ]
    return new Promise((resolve) => {
      indexedDb.getItem(ACCOUNT_LIST).then(accountDataJson=>{
        if (accountDataJson) {
          //数据格式{user_plat_account_1:[{plat_type:1,plat_type_str:'抖音',accountlist:[]}]}
          //获取当前用户的平台账号信息数组
          let platAccountList = accountDataJson[`${USER_KEY_PREFIX}${chatingUserid}`]
          if(platAccountList && platAccountList.length > 0){
            resolve(platAccountList)
            return
          }
        } 
        resolve(deaultValue)
      })
    })
}

export function removePlatAccountList(chatingUserid){
    indexedDb.getItem(ACCOUNT_LIST).then(userPlatAccountListJson=>{
      if(userPlatAccountListJson){
        delete userPlatAccountListJson[`${USER_KEY_PREFIX}${chatingUserid}`]
        indexedDb.setItem(ACCOUNT_LIST, userPlatAccountListJson)
      }
    })
    
}

export function setPlatAccountList(accountList,chatingUserid){
    indexedDb.getItem(ACCOUNT_LIST).then((userPlatAccountListJson)=>{
      if(!userPlatAccountListJson){
        userPlatAccountListJson = {}
      }
      userPlatAccountListJson[`${USER_KEY_PREFIX}${chatingUserid}`] = accountList
      return indexedDb.setItem(ACCOUNT_LIST, userPlatAccountListJson)
    })
    
}


/**
 * 
 * @param {平台账号} chatingUserid 
 * @returns 
 */
export function getAccountFriendList(chatingUserid) {
    return new Promise((resolve)=>{
        indexedDb.getItem(ACCOUNT_FRIEND_LIST).then(accountFriendListJson=>{
          if (accountFriendListJson) {
            let accountFriendList = accountFriendListJson[`${USER_KEY_PREFIX}${chatingUserid}`]
            if(accountFriendList && accountFriendList.length > 0){
              return resolve( accountFriendList)
            }
          } 
          return resolve([])
        })
        
    })
   
    
}

export function setAccountFriendList(accountFriendList,chatingUserid){
  indexedDb.getItem(ACCOUNT_FRIEND_LIST).then(userAccountFriendListJson=>{
    if(!userAccountFriendListJson){
      userAccountFriendListJson = {}
    }
    userAccountFriendListJson[`${USER_KEY_PREFIX}${chatingUserid}`] = accountFriendList
    return indexedDb.setItem(ACCOUNT_FRIEND_LIST, userAccountFriendListJson)
  })
 
}

export function getPlatAccountActiveMap(chatingUserid){
  return new Promise((resolve)=>{
    indexedDb.getItem(PLAT_ACCOUNT_MAP).then((userPlatAccountMapJson)=>{
      if (userPlatAccountMapJson) {
        let userPlatAccountList = userPlatAccountMapJson[`${USER_KEY_PREFIX}${chatingUserid}`]
        if(userPlatAccountList && userPlatAccountList.length > 0){
          return resolve(userPlatAccountList)
        }
      } 
      return resolve([])
    })
  
  })
}


export function setPlatAccountActiveMap(chatLinkList,chatingUserid){
  indexedDb.getItem(PLAT_ACCOUNT_MAP).then(userPlatAccountMapJson=>{
    if(!userPlatAccountMapJson){
      userPlatAccountMapJson = {}
    }
    userPlatAccountMapJson[`${USER_KEY_PREFIX}${chatingUserid}`] = chatLinkList
    return indexedDb.setItem(PLAT_ACCOUNT_MAP, userPlatAccountMapJson)
  })
  
}


export function getAccountFriendActiveMap(chatingUserid){
  return new Promise((resolve)=>{
    indexedDb.getItem(ACCOUNT_FRIEND_MAP).then((userAccountFriendMapJson)=>{
      if (userAccountFriendMapJson) {
        let userAccountFriendMapList = userAccountFriendMapJson[`${USER_KEY_PREFIX}${chatingUserid}`]
        if(userAccountFriendMapList && userAccountFriendMapList.length > 0){
          return resolve(userAccountFriendMapList)
        }
    
      }
      return resolve([])
    })
    
  })
}
export function setAccountFriendActiveMap(chatLinkList,chatingUserid){

  indexedDb.getItem(ACCOUNT_FRIEND_MAP).then(userAccountFriendMapJson=>{
    if(!userAccountFriendMapJson){
      userAccountFriendMapJson = {}
    }
    userAccountFriendMapJson[`${USER_KEY_PREFIX}${chatingUserid}`] = chatLinkList
    return indexedDb.setItem(ACCOUNT_FRIEND_MAP, userAccountFriendMapJson)
  })

}




//处理websocket 数据
// function handleWebSocketData(oldDataList,newDataList){
    
// }