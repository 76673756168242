<!-- //中间栏模块 -->
<template>
  <div class="leftContainer" :class="{collapsedLeft:store.state.user.isLeftCollapsed}">
    <div class="title" >
      <div class="title_util">
        <!-- <el-input
          v-model="input2"
          placeholder="Type something"
          :prefix-icon="Search"
        /> -->
        <!-- <el-icon :size="20">
        <Search />
      </el-icon> -->

        <el-input v-model="searchKeyWords"  size="small" style="padding:0;" placeholder="搜索账号"  >
          <template #prefix>
            <i class="iconfont icon-search"></i>
          </template>
        </el-input>
        <!-- <span @click="haldleSetLeftCollapsedToggled" :class="{collapsedLeftIcon:store.state.user.isLeftCollapsed,uncollapsedLeft:!store.state.user.isLeftCollapsed}">
          <i class="iconfont icon-shousuo"></i>
        </span> -->
      </div>
    </div>
    <!-- <div class="title_tip">
      账号数{{ accountList.length }},已加载账号数:{{ 100 }}
    </div> -->
 
    <v3-scroll autohide zIndex="2021">
      <div class="item_contaimer">
        <div>
          <el-alert  type="info" v-if="!accountList || accountList?.length ==0">
            <div style="font-size: 12px;">账号未显示
              <el-tooltip
                    class="box-item"
                    effect="light"
                    placement="top-start"
                  >
                  <template #content>
                      <p>常见问题：</p>
                      <ul>
                        <li class="m_desc">1. 您新分配的账号需要<em class="m_color_p">收到第一条新信息</em>后才会显示哦!</li>
                      </ul>
                  </template>
              <i class="iconfont icon-tishi1"></i>
              </el-tooltip>
            </div>
          </el-alert>
        </div>
          
          <div class="item" v-for="(item, index) in accountList" :key="index" @click="handleSelectAccouont(item)" :class="{selectAccount:item.id === selectedAccountId}">
            <!-- 标签 -->
            <!-- <div class="new-item-badge" ></div> -->

            <div class="img_container_p" >
              <div class="img_container" >
                <div v-if="index >36666" class="image_cover">
                  离线
                </div>
                <img
                  class="uImage img" 
                  :src="item.avatar || '@assets/img/uimg/img-avatar01.jpg'"
                  @click ="showUinfoLayer(item,$event)"
                />
              </div>
              <!-- <em class="m_vui__badge" v-if="item.id !== selectedAccountId && item.new_msg_count > 0">{{ item.new_msg_count }}</em> -->
              <em class="m_vui__badge" v-if="item.new_msg_count > 0">{{ item.new_msg_count }}</em>
            </div>
         

            <div class="info_container overflow_ellipsis">
              <div style="display: flex;flex-direction: column;">
                <div class="c1 flex_center" style="flex:1;">
                  <div class="icon_item">
                    <el-tooltip
                    class="box-item"
                    effect="light"
                    placement="top-start"
                      >
                          <template #content>
                            <div style="width:200px;min-height:50px">
                              <div v-if="item.end_flg == -1">
                                端口信息： <span class="m_color_e">端口未绑定</span>
                                <div class="m_color_e">
                                  请联系管理员尽快绑定端口，否则无法正常使用功能
                                </div>
                              </div>
                              <div v-else>
                                <div>
                                  端口信息：
                                  <span v-if="item.expire_day >= 30">正常</span>
                                  <span v-else-if="item.expire_day > 0" class="m_color_w">即将过期</span>
                                  <span v-else class="m_color_e">端口已过期</span>
                                </div>
                                <div>
                                  端口号：【{{ item.port_id }}】
                                </div>
                                <div>
                                  端口过期时间：{{ item.expire_time }}
                                </div>
                                <div>
                                  有效天数：
                                  <span v-if="item.expire_day < 0">0</span>
                                  <span v-else>{{ item.expire_day }}</span>
                                </div>

                                <div v-if="item.expire_day < 30" class="m_color_e">
                                  请联系管理员尽快续费端口号，否则端口过期后无法正常使用功能。
                                </div>
                              </div>
                            </div>
                          </template>
                          <div>
                            <i class="iconfont icon-anquanbaozhang-xianxing m_color_s22" v-if="item.expire_day >= 30"></i>
                            <i class="iconfont icon-anquanbaozhang-xianxing m_color_w" v-else-if="item.expire_day >0"></i>
                            <i class="iconfont icon-anquanbaozhang-xianxing m_color_e" v-else></i>
                          </div>
                      </el-tooltip>
                  </div>
      
                  <div v-if="item.use_robot === 1" class="icon_item">
                    <el-popover
                      placement="top-start"
                      width="200"
                      trigger="hover">
                      <div style="min-height:50px;font-size: 12px;">
                          <div class="m_desc m_border_b" style="margin-bottom: 10px;">账号的AI客服接管开启后，AI会自动回复此账号下可会话客户的对话。</div>
                          <div v-if="item.use_robot_status === 1">
                            AI客服状态： <span class="m_color_s2">开启</span>
                            
                          </div>
                          <div v-else>
                            AI客服状态： <span class="m_color_e">关闭</span>
                          </div>
                          <div>AI客服：<span class="m_desc">{{ item.robot_name }}</span></div>
                          <div>
                            AI客服接管:
                            <el-switch
                                v-model="item.use_robot_status"
                                size="small"
                                active-text=""
                                inactive-text=""
                                :active-value="1"
                                :inactive-value="0"
                                @change="handleUseRobotStatusChange(item)"
                              />
                          </div>
                        </div>
                        <template #reference>
                          <i class="iconfont icon-zhinengkefuzhongxin" :class="{m_color_s22:item.use_robot_status === 1}"></i>
                        </template>
                    </el-popover>
                  </div>
      
                  <i class="iconfont icon-douyin" style="color:#000;"></i>  
                  <span class="m_color_2" style="margin-left: 3px;">{{ item.username }}</span>
                  <div class="m_border_r m_desc overflow_ellipsis"  style="margin-left: 3px;font-size: 11px;padding:0 3px;">{{ $syscfgNameByValue('customer_type_list',item.customer_type_id) }}</div>
                </div>
              </div>
              <!-- <div class="r2">
              
              </div> -->
            </div>

            <!-- <div class="selectFlag" v-if="item.id === selectedAccountId">

            </div> -->
          </div>
      </div>
    </v3-scroll>
    <!-- 卡片模板 -->
    <v3-layer
      v-model="isShowUinfoLayer"
      :follow="uInfoLayerPos"
      opacity="0"
      teleport="body"
      :zIndex="999"
    >
      <div class="rlay__uCards">
        <div class="item flexbox flex-alignc vui__hairline-bottom">
          <span class="lbl flex1"
            ><em class="fs-18">{{mainData.selectAccountInfoRow.username || '--'}}</em
            ><em class="iconfont icon-male ml-10 c-46b6ef"></em
            ></span
          >
          <img class="uimg"  :src="mainData.selectAccountInfoRow.avatar || '@assets/img/uimg/img-avatar01.jpg'" />
        </div>
        <div class="item flexbox flex-alignc vui__hairline-bottom">
          <label class="lbl flex1">分类：{{ $syscfgNameByValue('customer_type_list',mainData.selectAccountInfoRow.customer_type_id) }}</label>
        </div>
      </div>
    </v3-layer>
  </div>
</template>
<script> 
// 引入动态组件（中间栏）
import {  reactive, toRefs, computed,inject,onMounted, onUnmounted,ref } from "vue";
import { getAccountFriendList } from "@/api/chatapi";
import {changeAiStatus} from '@/api/aichatapi'
import { useStore } from "vuex";

export default {
  components: {},
  setup() {
    const bus = inject("$eventBus");
    const wsCmd = inject("wsCmd");
    let store = useStore();

     
    const data = reactive({
      searchKeyWords:'',
      status: "online",
      isShowUinfoLayer: false,
      uInfoLayerPos: null,
    });
    const mainData = reactive({
  
      selectAccountInfoRow:{}
    })


    //接收账号信息变更
    function receiveAccountList(accountListResp) {
        if (accountListResp && accountListResp.code === 1) {
          store.commit('wsData/UPDATE_ACCOUNT_LIST',accountListResp)
        }
    }



    //当前选中平台
    const chatingPlatId = computed(() => {
      return store.state.wsData.chatingPlatId
    });

     //当前选中账号
     const selectedAccountId = computed(() => {
      let chatLink = store.state.wsData.platAccountActiveMap.find(item=>item.chatingPlatId == store.state.wsData.chatingPlatId)
      let chatingAccountId =  store.state.wsData.chatingAccountId || chatLink?.chatingAccountId
      return chatingAccountId
    });

    const platAccountActiveMap = computed(()=>{
      return    store.state.wsData.platAccountActiveMap
    })


    //账号列表
    const accountList = computed(() => {
      let _accountListDataStore =  store.state.wsData.platAccountList.find(item=>item.plat_type == chatingPlatId.value);

      let accountList = _accountListDataStore?.accountlist
      if(accountList && accountList.length > 0){
          //如果没设置选中账号则处理
          let chatingAccountId = store.state.wsData.chatingAccountInfo?.id
          if(!chatingAccountId){
            let chatingAccount = accountList.find(item => item.id == selectedAccountId.value)
            if(chatingAccount?.id){
              store.commit("wsData/SET_CHATING_ACCOUNT", chatingAccount);
              handleSelectAccouont(chatingAccount)
            }else{
              //默认选中第一条
              store.commit("wsData/SET_CHATING_ACCOUNT", accountList[0]);
              handleSelectAccouont(accountList[0])
            }
          }
      }

      if(!accountList){
        return []
      }
      if(data.searchKeyWords){
        return accountList.filter(item=>haldleFilterAccount(item,data.searchKeyWords)) || [];
      }
      return accountList;
    });

     //过滤账号
     const haldleFilterAccount = (friendItem,searchKeyWords)=>{


      if(searchKeyWords){
        let _searchKeywords = searchKeyWords.replace(/^\s\s*/, '').replace(/\s\s*$/, '')
        if(_searchKeywords.length == 0){
          return true
        }
        let title = friendItem.username
        if(!title){
          return false
        }
        return title.includes(_searchKeywords)
      }else{
        return true
      }
    }



    //左侧边栏收缩
    const haldleSetLeftCollapsedToggled = ()=>{
      store.commit("user/SET_LEFT_COLLAPSED_TOGGLED");
    }


    // 用户卡片
    const showUinfoLayer = (row,e) => {
      //如果折叠起来了直接事件冒泡就好了
      if(store.state.user.isLeftCollapsed){
        return
      }

      let pos = [e.clientX, e.clientY];
      data.isShowUinfoLayer = true;
      data.uInfoLayerPos = pos;
      mainData.selectAccountInfoRow = row
      e.stopPropagation()
    };

    //账号选择
    function handleSelectAccouont(item){
      store.commit("wsData/SET_CHATING_ACCOUNT", item);
      //清理正选择的好友信息
      store.commit("wsData/CLEAR_CHATING_FRIEND");
      //获取账号好友列表
      getAccountFriendList({aid:item.id})
      return
    }

    //当前聊天账号
    const chatingAccount = computed(() => {
      return store.state.wsData.chatingAccountInfo;
    });

    //切换机器人接管状态
    const handleUseRobotStatusChange = (item)=>{
      let data = {aid:item.id,status:item.use_robot_status}
      changeAiStatus(data,item.id)
    }

    const receiveAiStatusResult = (resp)=>{
      console.log('----------------')
      console.log(resp)
    }

    onMounted(() => {
      //当前选中账号
      //handleSelectAccouont(chatingAccount.value)

      bus.on(`${wsCmd.cmd_2_resp}`, receiveAccountList); // listen
      bus.on(`${wsCmd.cmd_21_resp}`, receiveAiStatusResult); // listen
    });

    onUnmounted(()=>{
      bus.off(`${wsCmd.cmd_2_resp}`); // listen
      bus.off(`${wsCmd.cmd_21_resp}`); // listen
    })

    return {
      ...toRefs(data),
      showUinfoLayer,
      accountList,
      selectedAccountId,
      mainData,
      platAccountActiveMap,
      store,
      chatingAccount,
      haldleFilterAccount,
      handleSelectAccouont,
      haldleSetLeftCollapsedToggled,
      receiveAccountList,
      handleUseRobotStatusChange,
    };
  },
};
</script>

<style lang="scss" scoped>

.new-item-badge {
  position: absolute;
    background: rgb(82, 196, 26);
    z-index: 999;
    width: 54px;
    text-align: center;
    height: 34px;
    line-height: 50px;
    border-radius: 3px;
    color: #fff;
    font-size: 8px !important;
    padding: 2px 4px 0;
    top: -15px;
    left: -36px;
    transform: rotate(-45deg);
    transition: transform 0.1s ease-in;
}


::v-deep(.el-input__wrapper){
    background-color: #D2DBE9;
}
::v-deep(.el-input__inner){
  height: 25px;
  // background-color: red;
  border:none;
  background-color: #D2DBE9;
}
.selectFlag{
  position: absolute;
  top:15;
  right:0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 10px 15px 0;
  // border-color: transparent rgba(0,0,0,.3) transparent transparent;
  border-color: transparent rgba(0,0,0,.3) transparent transparent;
}
.selectAccount{
  background-image: linear-gradient(180deg, #fff, #fff) !important;
  border-radius: 5px;
}

.selectAccount:hover{
  background-color: rgba(248,250,253,0.8);
}

.leftContainer.collapsedLeft{
  width:60px;
}

.uncollapsedLeft{
   margin-left: 10px;
}
.collapsedLeftIcon {
    transform: rotate(180deg)
}

.leftContainer {
  box-sizing: border-box;
  overflow: hidden;
  min-width: 220px;
  flex-shrink: 0;
  height: 100%;
  // background-color: rgba(62,63,66, 0.9);
  background-color: rgba(223,229,244, .8);
  // margin-left: 1px;
  box-shadow: -12px 0px 19px rgb(41, 41, 42, 0.5);
  color: white;
  .title {
    // background-color: rgba(223,229,244, 0.8);
    background-color: rgba(223,229,244, 0.8);
    height: 35px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: 15px;
    justify-content: center;
    color: rgba(87,107,149, 0.6);
    padding-left:10px;
    padding-right:10px;
    .title_util{
     
      flex:1;
    }
  }
  .title_tip{
    box-sizing: border-box;
    background-color: rgba(223,229,244, 0.8);
    height: 15px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    color: #67c23a;
    justify-content: center;
    color: rgba(103,194,58, 0.6);
  }

  .title:hover {
    color: rgba(252,252,252, 0.8);
  }
  .item_contaimer {
    padding-bottom: 50px;
    .item:hover{
      // background-color: rgba(169,169,169,0.8);
       background-color: rgba(223,229,244, 0.9);
    }
    .item {
      color: rgba(87,107,149, 0.6);
      display: flex;
      align-items: center;
      height: 40px;
      margin:5px 10px;
      position: relative;
      box-sizing: border-box;
      overflow: hidden;
      cursor: pointer;

      .img_container_p{
        position: relative;
        .m_vui__badge{
          position:absolute;
          top:-5px;
          right:-5px;
          z-index: 998;
        }
        .img_container {
        width: 30px;
        height: 30px;
        overflow: hidden;
        border-radius: 100%;
        position: relative;
        margin-left: 10px;
        .img {
          object-fit: fill;
          width: 30px;
          height: 30px;
        }
        .image_cover{
          z-index: 11;width:100%;height: 100%;background-color: rgba(0,0,0,.8);position:absolute;display: flex;justify-content: center;align-items: center;text-align: center;color:white;
        }
      }
      }
      
      .info_container {
        box-sizing: border-box;
        margin: 5px 5px;
        // overflow: hidden;
        display: flex;
        align-items: center;
        
        .c1{
          .icon_item{
            margin-right: 3px;
          }
        }
        
      }
    }
    .item:hover {
      background-image: linear-gradient(180deg, #fff, #fff) !important;
      border-radius: 5px;
    }
  }
}
</style>
   
   