<template>
  <div class="vui__container flexbox flex-alignc flex-justifyc">
    <div class="vui__bgblur">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        class="blur-svg"
        viewBox="0 0 1920 875"
        preserveAspectRatio="none"
      >
        <filter id="blur_mkvvpnf">
          <feGaussianBlur in="SourceGraphic" stdDeviation="50"></feGaussianBlur>
        </filter>
        <image
          :xlink:href="store.state.user.skin"
          x="0"
          y="0"
          width="100%"
          height="100%"
          externalResourcesRequired="true"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          style="filter: url(#blur_mkvvpnf)"
          preserveAspectRatio="none"
        ></image>
      </svg>
      <div class="blur-cover"></div>
    </div>

    <div
      :class="['vui__wrapper', store.state.user.isWinMaximize && 'maximize']"
      :style="{ backgroundImage: 'url(' + store.state.user.skin + ')' }"
    >
      <div
        class="vui__board flexbox"
        :class="[
          store.state.user.winType && 'vui__board-' + store.state.user.winType,
        ]"
      >
        <!-- <SideBar
            v-if="!route.meta.hideSideBar && store.state.winType == 'qq'"
            class="nt__topbar flexbox"
          /> -->
        <div class="flex1 flexbox" style="position: relative">
          <WinBar v-if="!route.meta.hideWinBar" />

          <Sidebarleft
            v-if="!route.meta.hideSideBarLeft && store.state.user.token"
          />

          <SideBar
            v-if="!route.meta.hideSideBar && store.state.user.token"
            class="nt__sidebar flexbox flex-col"
          />
          <div style="flex: 2">
            <RouterView />
          </div>
        </div>
      </div>
    </div>

    <audioNotice />
    <!-- 网络中断提示对话框 -->
    <el-dialog
      v-model="networkOffline"
      title="提示信息"
      width="500"
      :showClose="false"
    >
      <div style="height: 250px">
        <el-row>
          <el-col :sm="24" :lg="24">
            <el-result
              icon="error"
              title="网络异常"
              sub-title="请检查您的计算机网络"
            >
              <template #extra>
                <el-button type="primary" @click="networkOffline = false">知道了</el-button>
              </template>
            </el-result>
          </el-col>
        </el-row>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <!-- <el-button @click="networkOffline = false">知道了</el-button> -->
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  inject,
  onMounted,
  computed,
  onUnmounted,
  reactive,
  toRefs,
  getCurrentInstance,
  watch,
  ref,
} from "vue";
import { ElNotification } from "element-plus";

import "splitpanes/dist/splitpanes.css";
import audioNotice from "@/components/audioplay/audio-notice.vue";
export default {
  name: "App",
  components: { audioNotice },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const bus = inject("$eventBus");
    const eventbusCms = inject("eventbusCms");
    const wsCmd = inject("wsCmd");
    // const v3layer = inject("v3layer");

    const networkOffline = ref(false);

    const data = reactive({
      x1: 0,
      y1: 0,
      h1: 100,
      w1: 100,
      active1: false,
    });

    const handleBeforeunload = (e) => {
      console.log(e);
      if (proxy.$disconnectWs) {
        proxy.$disconnectWs();
      }
      return true;
    };

    const loginUserInfo = computed(() => {
      return store.state.user.user;
    });

    //系统配置信息初始化
    function handleSystemCfgInit(respData) {
      let code = respData.code;
      if (code != 1) {
        return;
      }

      let data = respData.data;
      if (!data) {
        data = {};
      }
      store.commit("wsData/SET_SYS_CFG_INFO", data);

      //通知登录成功
      bus.emit(eventbusCms.sysCfgInitSuccess);
    }

    //七牛云token信息监听
    const handleQiniuTokenResp = (respData) => {
      let code = respData.code;
      if (code != 1) {
        return;
      }

      let data = respData.data?.qiniu_info;
      // console.log('qinniu response',respData)
      if (!data) {
        data = {};
      }
      let token = data.token;
      let domain = data.domain;
      let uploadurl = data.uploadurl;
      let active_time = data.active_time;
      let tokenData = { token, domain, uploadurl, active_time };
      store.commit("qiniu/SET_QINIU_INFO", tokenData);
    };

    function logout() {
      ElNotification({
        title: "提示信息",
        message: "登录信息已过期，请重新登录...！",
        type: "error",
        duration: 1000,
        onClose: () => {
          store.commit("user/LOGOUT");
          store.commit("wsData/CLEAR_SYS_CFG_INFO", {});
          store.commit("wsData/SET_CHATING_USERID", 0);
          router.push("/login");
        },
      });
    }

    //用户信息变更
    const handelUserInfoChange = (message) => {
      let userInfo = message.data;
      store.commit("user/UPDATE_USER", userInfo);
    };

    onUnmounted(() => {
      window.removeEventListener("beforeunload", (e) => handleBeforeunload(e));

      //监听事件start----------
      bus.off(`${wsCmd.cmd_99_resp}`); // listen
      bus.off(`${wsCmd.cmd_98_resp}`); // listen
      bus.off(eventbusCms.logout); // listen
      bus.off(`${wsCmd.cmd_101_resp}`);
      //监听事件end----------
    });

    onMounted(() => {
      // const _this = getCurrentInstance().appContext.config.globalProperties;
      // console.log(_this.$socket)
      //初始化会话消息
      store.commit("wsData/INIT_CHAT_DATA");
      window.addEventListener("beforeunload", (e) => handleBeforeunload(e));

      //监听事件start----------
      bus.on(`${wsCmd.cmd_99_resp}`, handleSystemCfgInit); // listen
      bus.on(`${wsCmd.cmd_98_resp}`, handleQiniuTokenResp); // listen
      bus.on(eventbusCms.logout, logout); // listen
      //用户信息变更
      bus.on(`${wsCmd.cmd_101_resp}`, handelUserInfoChange);
      //监听事件end----------

      // //如果用户信息存在加载系统配置信息
      //  //初始化系统配置信息
      //  if(loginUserInfo.value.uid){
      //   console.log('==================init initUserSystemData')
      //   initUserSystemData()
      //  }
    });

    const controller = new AbortController();
    const { signal } = controller;
    onUnmounted(() => {
      // Remove the listener!
      controller.abort();
    });
    //网络掉线上线
    onMounted(() => {
      window.addEventListener(
        "online",
        () => {
          networkOffline.value = false;
          window.location.reload();
        },
        { signal }
      );
      window.addEventListener(
        "offline",
        () => {
          networkOffline.value = true;
        },
        { signal }
      );
    });

    return {
      ...toRefs(data),
      route,
      store,
      loginUserInfo,
      networkOffline,
      handleBeforeunload,
      handleSystemCfgInit,
      handleQiniuTokenResp,
      logout,
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep(
    .default-theme.splitpanes--vertical > .splitpanes__splitter,
    .default-theme .splitpanes--vertical > .splitpanes__splitter
  ) {
  width: 5px;
  border-left: 1px solid #eee;
  margin-left: -1px;
}

::v-deep(.splitpanes.default-theme .splitpanes__splitter) {
  background-color: rgba(223, 229, 244, 0.2);
  box-sizing: border-box;
  position: relative;
  flex-shrink: 0;
}

::v-deep(.splitpanes.default-theme .splitpanes__splitter:before) {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transition: background-color 0.3s;
}
::v-deep(.splitpanes.default-theme .splitpanes__splitter:after) {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: transparent;
  transition: background-color 0.3s;
}
</style>

