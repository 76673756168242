<!-- //VScroll 自定义滚动条模板 -->
<template>
  <div
    class="vui__scrollbar"
    ref="ref__box"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    v-resize="handleResize"
  >
    <div
      :class="['vscroll__wrap', { native: native }]"
      ref="ref__wrap"
      @scroll="handleScroll"
    >
      <div class="vscroll__view" v-resize="handleResize">
        <slot />
      </div>
    </div>
    <div
      :class="['vscroll__bar vertical', { ishide: !isShow }]"
      @mousedown="handleClickTrack($event, 0)"
      :style="{
        width: parseInt(size) >= 0 ? parseInt(size) + 'px' : '',
        'z-index': parseInt(zIndex) >= 0 ? parseInt(zIndex) : '',
      }"
    >
      <div
        class="vscroll__thumb"
        ref="ref__barY"
        :style="{ background: color, height: barHeight + 'px' }"
        @mousedown="handleDragThumb($event, 0)"
      ></div>
    </div>
    <div
      :class="['vscroll__bar horizontal', { ishide: !isShow }]"
      @mousedown="handleClickTrack($event, 1)"
      :style="{
        height: parseInt(size) >= 0 ? parseInt(size) + 'px' : '',
        'z-index': parseInt(zIndex) >= 0 ? parseInt(zIndex) : '',
      }"
    >
      <div
        class="vscroll__thumb"
        ref="ref__barX"
        :style="{ background: color, width: barWidth + 'px' }"
        @mousedown="handleDragThumb($event, 1)"
      ></div>
    </div>
  </div>
</template>
<script>
import { onMounted, ref, reactive, toRefs, nextTick } from "vue";
import domUtils from "./utils/dom";
export default {
  props: {
    native: Boolean,
    // 是否自动隐藏滚动条
    autohide: Boolean,
    // 滚动条尺寸
    size: { type: [Number, String], default: "" },
    // 滚动条颜色
    color: String,
    // 滚动条层级
    zIndex: null,
  },
  directives: {
    resize: {
      beforeMount: function (el, binding) {
        let width = "",
          height = "";
        function get() {
          const elStyle = el.currentStyle
            ? el.currentStyle
            : document.defaultView.getComputedStyle(el, null);
          if (width !== elStyle.width || height !== elStyle.height) {
            binding.value({ width, height });
          }
          width = elStyle.width;
          height = elStyle.height;
        }
        el.__vueReize__ = setInterval(get, 16);
      },
      unmounted: function (el) {
        clearInterval(el.__vueReize__);
      },
    },
  },
  setup(props, context) {
    const ref__box = ref(null);
    const ref__wrap = ref(null);
    const ref__barX = ref(null);
    const ref__barY = ref(null);
    const data = reactive({
      barWidth: 0,
      barHeight: 0,
      ratioX: 1,
      ratioY: 1,
      isTaped: false,
      isHover: false,
      isShow: !props.autohide,
    });

    const ref_emit_scroll_toped = ref(false)

    //记录3次滚动条的坐标，区分上下滚动
    //const ref_scroll_x_coords = []
    onMounted(() => {
      nextTick(() => {
        updated();
      });
    });
    // 鼠标滑入
    const handleMouseEnter = () => {
      data.isHover = true;
      data.isShow = true;
      updated();
    };
    // 鼠标滑出
    const handleMouseLeave = () => {
      data.isHover = false;
      if (!data.isTaped && props.autohide) {
        data.isShow = false;
      }
    };
    const handleDragThumb = (e, index) => {
      const elWrap = ref__wrap.value;
      const elBarX = ref__barX.value;
      const elBarY = ref__barY.value;
      data.isTaped = true;
      let c = {};
      // 阻止默认事件
      domUtils.isIE()
        ? ((e.returnValue = false), (e.cancelBubble = true))
        : (e.stopPropagation(), e.preventDefault());
      document.onselectstart = () => false;
      if (index == 0) {
        c.dragY = true;
        c.clientY = e.clientY;
      } else {
        c.dragX = true;
        c.clientX = e.clientX;
      }
      domUtils.on(document, "mousemove", function (evt) {
        if (data.isTaped) {
          if (c.dragY) {
            elWrap.scrollTop += (evt.clientY - c.clientY) * data.ratioY;
            elBarY.style.transform = `translateY(${
              elWrap.scrollTop / data.ratioY
            }px)`;
            c.clientY = evt.clientY;
          }
          if (c.dragX) {
            elWrap.scrollLeft += (evt.clientX - c.clientX) * data.ratioX;
            elBarX.style.transform = `translateX(${
              elWrap.scrollLeft / data.ratioX
            }px)`;
            c.clientX = evt.clientX;
          }
        }
      });
      domUtils.on(document, "mouseup", function () {
        data.isTaped = false;
        c.dragY && delete c.dragY;
        c.dragX && delete c.dragX;
        document.onmouseup = null;
        document.onselectstart = null;
        if (!data.isHover && props.autohide) {
          data.isShow = false;
        }
      });
    };
    const handleClickTrack = (e, index) => {
      const elWrap = ref__wrap.value;
      const elBarX = ref__barX.value;
      const elBarY = ref__barY.value;
      if (index == 0) {
        elWrap.scrollTop =
          (Math.abs(e.target.getBoundingClientRect().top - e.clientY) -
            elBarY.offsetHeight / 2) *
          data.ratioY;
        elBarY.style.transform = `translateY(${
          elWrap.scrollTop / data.ratioY
        }px)`;
      }
      if (index == 1) {
        elWrap.scrollLeft =
          (Math.abs(e.target.getBoundingClientRect().left - e.clientX) -
            elBarX.offsetWidth / 2) *
          data.ratioX;
        elBarX.style.transform = `translateX(${
          elWrap.scrollLeft / data.ratioX
        }px)`;
      }
    };
    // 更新滚动区
    const updated = () => {
      if (props.native) return;
      if (!ref__box.value) return;
      const elBox = ref__box.value;
      const elWrap = ref__wrap.value;
      const elBarX = ref__barX.value;
      const elBarY = ref__barY.value;
      let barSize = domUtils.getScrollBarSize();
      // 垂直滚动条
      if (elWrap.scrollHeight > elWrap.offsetHeight) {
        data.barHeight = elBox.offsetHeight ** 2 / elWrap.scrollHeight;
        data.ratioY =
          (elWrap.scrollHeight - elBox.offsetHeight) /
          (elBox.offsetHeight - data.barHeight);
        elBarY.style.transform = `translateY(${
          elWrap.scrollTop / data.ratioY
        }px)`;
        // 隐藏系统滚动条
        if (barSize) {
          elWrap.style.marginRight = -barSize + "px";
        }
      } else {
        data.barHeight = 0;
        elBarY.style.transform = "";
        elWrap.style.marginRight = "";
      }
      // 水平滚动条
      if (elWrap.scrollWidth > elWrap.offsetWidth) {
        data.barWidth = elBox.offsetWidth ** 2 / elWrap.scrollWidth;
        data.ratioX =
          (elWrap.scrollWidth - elBox.offsetWidth) /
          (elBox.offsetWidth - data.barWidth);
        elBarX.style.transform = `translateX(${
          elWrap.scrollLeft / data.ratioX
        }px)`;
      } else {
        data.barWidth = 0;
        elBarX.style.transform = "";
      }
    };
    const handleResize = () => {
      updated();
    };
    // 鼠标滚动
    const handleScroll = (e) => {
      context.emit("scroll", e);
      let transform = ref__barY.value.style.transform

      //console.log(transform)
      if(transform){
        //const paragraph = 
        let regex = /^translateY\(([0](\.?\d*)?)px\)$/g;
        const found = regex.exec(transform);
        //console.log(found)
        if(found && found.length >0 && !ref_emit_scroll_toped.value){

          // console.log(found)
          //2秒后才能emit下一次
          context.emit("scrollTop");
          scrollTo(100)
          ref_emit_scroll_toped.value = true
          setTimeout(() => {
            ref_emit_scroll_toped.value = false
          }, 5000);
        }
      }
  

      // translateY(0.541333px)

    //   const ref__box = ref(null);
    // const ref__wrap = ref(null);
    // const ref__barX = ref(null);
    // const ref__barY = ref(null);
      updated();
    };
    const scrollTo = (dir) => {
      const elWrap = ref__wrap.value;
      const elBarY = ref__barY.value;
      nextTick(() => {
        elWrap.scrollTop =
          dir == "top"
            ? 0
            : dir == "bottom"
            ? elWrap.scrollHeight
            : parseInt(dir);
        elBarY.style.transform = `translateY(${
          elWrap.scrollTop / data.ratioY
        }px)`;
      });
    };
    return {
      ...toRefs(data),
      ref__box,
      ref__wrap,
      ref__barX,
      ref__barY,
      handleMouseEnter,
      handleMouseLeave,
      handleDragThumb,
      handleClickTrack,
      updated,
      handleResize,
      handleScroll,
      scrollTo,
    };
  },
};
</script>
<style>
.vui__scrollbar {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
}
.vscroll__wrap {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}
.vscroll__wrap.native {
  overflow-x: auto;
}
.vscroll__bar {
  border-radius: 15px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 100;
  transition: opacity 0.3s ease-out;
}
.vscroll__bar.ishide {
  opacity: 0;
}
.vscroll__bar.vertical {
  width: 6px;
  top: 0;
}
.vscroll__bar.vertical > div {
  width: 100%;
}
.vscroll__bar.horizontal {
  height: 6px;
  left: 0;
}
.vscroll__bar.horizontal > div {
  height: 100%;
}
/*滚动槽*/
.vscroll__thumb {
  background-color: rgb(144, 147, 153);
  opacity: 0.4;
  border-radius: inherit;
  display: block;
  height: 0;
  width: 0;
  position: relative;
  transition: opacity 0.3s;
}
.vscroll__thumb:hover {
  opacity: 0.6;
}
.vscroll__thumb:active {
  opacity: 0.8;
}
</style>