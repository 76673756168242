<template>
  <div ref="tempDivAudio">
    <audio hidden ref="audio" @play="handleOnplay" @ended="handlePlayEnd">
      <source :src="require(`@assets/audio/notice${noticeItem}.mp3`)" type="audio/ogg" />
    </audio>
  </div>
</template>

<script setup>
import { inject,nextTick, onMounted, ref,computed,onUnmounted,watch } from "vue";
import { useStore } from "vuex";

const bus = inject("$eventBus");
const wsCmd = inject("wsCmd");

const store = useStore();
const audio = ref();
const playing = ref(false);
const tempDivAudio = ref();

const noticeItem = computed(()=>{
  return store.state.profile.noticeItem
})

//提醒声音是否开启
const noticeStatus = computed(() => {
  return store.state.profile.noticeStatus;
});

const plays = () => {
  if(noticeStatus.value !== '1'){
    return;
  }
  if (audio.value && !playing.value) {
    try {
      audio.value.play();
    } catch (e) {
      console.error(e);
    }
  }
};

const handleOnplay = () => {
  playing.value = true;
};

const handlePlayEnd = () => {
  playing.value = false;
};

const playNotice = () => {
  plays();
};

watch(
      () => store.state.profile.noticeItem,
      (_)=>{
        audio.value.load()
      }
    )

onMounted(() => {
  bus.on(`${wsCmd.cmd_100_resp}`, playNotice)   // listen
});

onUnmounted(()=>{
  bus.off(`${wsCmd.cmd_100_resp}`)   // listen
})

</script>