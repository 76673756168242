
let store = null

const syscfgListByKey = (key)=>{
    if(!key){
        return []
    }
    let configAll = store.getters['wsData/getSysConfig']
    if(!configAll){
        return []
    }
    return configAll[key] || []
}

const syscfgNameByValue = (key,id)=>{
    if(!key){
        return ''
    }
    let configAll = store.getters['wsData/getSysConfig']
    if(!configAll){
        return ''
    }
    let configList = configAll[key]
    if(!configList || configList.length == 0){
        return ''
    }
    let items = configList.filter(item=>item.id === id)
    if(!items || items.length == 0){
        return ''
    }
    return items[0]['name']
}
const syscfgItemByValue = (key,id)=>{
    if(!key){
        return null
    }
    let configAll = store.getters['wsData/getSysConfig']
    if(!configAll){
        return null
    }
    let configList = configAll[key]
    if(!configList || configList.length == 0){
        return null
    }
    let items = configList.filter(item=>item.id === id)
    if(!items || items.length == 0){
        return null
    }
    return items[0]
}



export default {
    install: (app,_store) => {
        store = _store
        app.config.globalProperties.$syscfgListByKey = syscfgListByKey
        app.config.globalProperties.$syscfgNameByValue = syscfgNameByValue
        app.config.globalProperties.$syscfgItemByValue = syscfgItemByValue
    }
  }