// vue3主页面
import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Plugins from './plugins'
import sysconfigPlugin from './plugins/sysconfig-plugin'
/* 引入公共样式 */
import '@assets/fonts/iconfont.css'
import '@assets/css/reset.css'
import '@assets/css/common.css'
import '@assets/css/layout.css'
import VueNativeSock from "vue-native-websocket-vue3";
import Vue3DraggableResizable from 'vue3-draggable-resizable'
//需引入默认样式
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'
const app = createApp(App)



const mutations = {
  SOCKET_ONOPEN:'websocket/SOCKET_ONOPEN',
  SOCKET_ONCLOSE:'websocket/SOCKET_ONCLOSE',
  SOCKET_ONERROR:'websocket/SOCKET_ONERROR',
  SOCKET_ONMESSAGE:'websocket/SOCKET_ONMESSAGE',
  SOCKET_RECONNECT:'websocket/SOCKET_RECONNECT',
  SOCKET_RECONNECT_ERROR:'websocket/'
}

//   let url = process.env.VUE_APP_SOCKET_URL

app.use(
  VueNativeSock,
  process.env.VUE_APP_SOCKET_URL,
  // 'wss://vip.72ygj.com:1239',
  // 'wss://chat.72ygj.com/wss',
  // 'wss://jhtst.72ygj.com:1281',
  {
    // 启用Vuex集成
    store: store,
    mutations: mutations,
    // 数据发送/接收使用使用json
    format: "json",
    // 开启手动调用 connect() 连接服务器
    //connectManually: true,
    // 关闭自动重连
    reconnection: true,
    //重连频率
    reconnectionAttempts: 10, 
    //重连次数
    reconnectionDelay: Infinity

  }
);


app.use(store).use(router).use(Plugins).use(sysconfigPlugin,store).use(Vue3DraggableResizable).mount('#app')


  export default app;