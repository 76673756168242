<template>
  <div class="chat_custom_info_container">
    <el-scrollbar>
      <div style="height: 100px">
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <template #title>
              <div class="el-collapse_t">客资信息</div>
            </template>
            <div class="customer_info">
              <div class="nameInfo" v-loading="loadingSaveCustomClue">
                <div class="title">
                  <div
                    style="
                      width: 20px;
                      height: 20px;
                      border-radius: 100%;
                      overflow: hidden;
                      margin-right: 5px;
                    "
                  >
                    <img
                      :src="chatingFriendInfo.avatar"
                      width="20px"
                      height="20px"
                    />
                  </div>
                  {{ chatingFriendInfo.title }}
                  <el-dropdown @command="handleSelectUserFlag">
                    <span
                      style="
                        font-size: 11px;
                        font-weight: bold;
                        padding-left: 20px;
                        cursor: pointer;
                      "
                    >
                      <i
                        class="iconfont icon-24gf-tags2"
                        style="font-size: 15px"
                      ></i>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item
                          :command="item"
                          v-for="(item, index) in userFlagList"
                          :key="index"
                          >{{ item.name }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <div style="padding: 5px 10px">
                  <el-tag
                    type="primary"
                    style="color: white"
                    :color="item.color"
                    closable
                    v-for="(item, index) in userFlatList"
                    :key="index"
                    @close="handleRemoveTag(item)"
                  >
                    {{ item.name }}
                  </el-tag>
                </div>

                <div class="info_item">
                  <span class="info_item_l">分类:</span>
                  <div class="info_item_r">
                    <el-select
                      v-model="customClueInfo.type_id"
                      placeholder="请选择好友所属市场分类"
                      :value-on-clear="0"
                      size="small"
                      style="width: 100%"
                      clearable
                    >
                      <el-option
                        v-for="item in customerTypeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </div>
                </div>

                <div class="info_item">
                  <span class="info_item_l">昵称:</span>
                  <div class="info_item_r">
                    <el-input
                      v-model="customClueInfo.nickname"
                      style="width: 100%"
                      size="small"
                      placeholder="请输入昵称"
                    >
                      <!-- <template #prefix>
                        <i
                          class="iconfont icon-phone"
                          style="font-size: 11px"
                        ></i>
                      </template> -->
                    </el-input>
                  </div>
                </div>
                <div class="info_item">
                  <span class="info_item_l">性别:</span>
                  <div class="info_item_r">
                    <el-select
                      v-model="customClueInfo.sex"
                      placeholder="请选择好友性别"
                      size="small"
                      style="width: 100%"
                    >
                      <el-option :key="1" :label="'未知'" :value="0" />
                      <el-option :key="1" :label="'男'" :value="1" />
                      <el-option :key="2" :label="'女'" :value="2" />
                    </el-select>
                  </div>
                </div>
                <div class="info_item">
                  <span class="info_item_l">手机号:</span>
                  <div class="info_item_r">
                    <el-input
                      v-model="customClueInfo.mobile"
                      style="width: 100%"
                      size="small"
                      placeholder="请输入手机号"
                    >
                      <template #prefix>
                        <i
                          class="iconfont icon-phone"
                          style="font-size: 11px"
                        ></i>
                      </template>
                    </el-input>
                  </div>
                </div>
                <div class="info_item">
                  <span class="info_item_l">微信号:</span>
                  <div class="info_item_r">
                    <el-input
                      v-model="customClueInfo.wechat"
                      style="width: 100%"
                      size="small"
                      placeholder="请输入微信号"
                    >
                      <template #prefix>
                        <i
                          class="iconfont icon-weixin"
                          style="font-size: 11px"
                        ></i>
                      </template>
                    </el-input>
                  </div>
                </div>
                <div class="info_item">
                  <span class="info_item_l">QQ:</span>
                  <div class="info_item_r">
                    <el-input
                      v-model="customClueInfo.qq"
                      style="width: 100%"
                      size="small"
                      placeholder="请输入QQ号"
                    >
                      <template #prefix>
                        <i class="iconfont icon-QQ" style="font-size: 11px"></i>
                      </template>
                    </el-input>
                  </div>
                </div>
                <div class="info_item">
                  <span class="info_item_l">地区:</span>
                  <div class="info_item_r">
                    <el-cascader
                      style="width: 100%"
                      size="small"
                      :options="regionData"
                      placeholder="请选择好友地区"
                      v-model="selectedOptions"
                      :props="{checkStrictly: true}"
                    >
                    </el-cascader>
                  </div>
                </div>
                <div class="info_item">
                  <span class="info_item_l">备注:</span>
                  <div class="info_item_r">
                    <el-input
                      v-model="customClueInfo.memo"
                      style="width: 100%"
                      :rows="1"
                      type="textarea"
                      size="small"
                      placeholder="请输入备注"
                    />
                  </div>
                </div>

                <div class="info_btns">
                  <el-button
                    class="save_btn"
                    type="success"
                    size="small"
                    @click="handleSaveFriendClue"
                    :disabled="!chatingFriendInfo.cid"
                    >保存</el-button
                  >

                  <el-popconfirm
                    confirm-button-text="确认"
                    cancel-button-text="取消"
                    icon-color="#626AEF"
                    title="确认保存并报单?(如果线索没任何变化的时候不会发送报单!)"
                    @confirm="handleSaveFriendClue(true)"
                    width="200"
                  >
                    <template #reference>
                      <el-button
                        type="warning"
                        size="small"
                        :disabled="!chatingFriendInfo.cid"
                      >
                        保存并报单
                        <el-tooltip
                          class="box-item"
                          effect="dark"
                          content="保存客户资料并且发送报单"
                          placement="top-start"
                        >
                          <i class="iconfont icon-tishi1"></i>
                        </el-tooltip>
                      </el-button>
                    </template>
                  </el-popconfirm>
                  <!-- <el-button
                    type="warning"
                    size="small"
                    @click="handleSaveFriendClue(true)"
                    :disabled="!chatingFriendInfo.cid">
                    保存并报单
                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      content="保存客户资料并且发送报单"
                      placement="top-start"
                    >
                        <i class="iconfont icon-tishi1"></i>
                    </el-tooltip>
                  </el-button> -->
                </div>
              </div>
              <!-- {{ chatingFriendInfo }} -->
            </div>
          </el-collapse-item>

          <el-collapse-item name="2">
            <template #title>
              <div class="el-collapse_t flex_center">
                快捷回复
                <el-tooltip
                  class="box-item"
                  effect="light"
                  content="刷新快捷回复信息"
                  placement="top-start"
                >
                  <el-icon
                    @click.stop="handleRefreshSysCfgInfo"
                    style="margin-left: 10px"
                    ><RefreshRight
                  /></el-icon>
                </el-tooltip>
              </div>
            </template>
            <div class="main">
              <div class="main_content">
                <el-segmented
                  v-model="selectedFastReplyItem"
                  :options="fastReplyListItem"
                  block
                >
                  <template #default="{ item }">
                    <div class="flex flex-col items-center gap-2 p-2">
                      <div @click="selectedFastReplyItem = item.value">
                        {{ item.label }}
                      </div>
                    </div>
                  </template>
                </el-segmented>
                <div class="search">
                  <el-input
                    v-model="searchFastReplyValue"
                    style="width: 100%"
                    placeholder="请搜索快捷回复"
                    clearable
                  >
                    <template #suffix>
                      <i class="iconfont icon-search1"></i>
                    </template>
                  </el-input>
                </div>

                <div class="fastReply_collapse">
                  <el-collapse
                    v-if="!searchFastReplyValue"
                    v-model="selectedFastReplySubItem"
                    accordion
                  >
                    <el-collapse-item
                      :title="item.type_name"
                      :name="item.type_id"
                      v-for="(item, index) in fastReplyList"
                      :key="index"
                    >
                      <template #title>
                        <div class="el-collapse_t1">{{ item.type_name }}</div>
                      </template>
                      <div>
                        <div v-for="sub_item in item.list" :key="sub_item.id">
                          <fastReplayItem :fastReplyInfo="sub_item" />
                        </div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>

                  <div v-else>
                    <div
                      v-for="sub_item in fastReplySearchList"
                      :key="sub_item.id"
                    >
                      <fastReplayItem :fastReplyInfo="sub_item" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-scrollbar>
  </div>
</template>
    <script>
// 引入动态组件（中间栏）
import {
  toRefs,
  onMounted,
  reactive,
  computed,
  inject,
  watch,
  onUnmounted,
} from "vue";
// import { regionData} from "element-china-area-data";
import { useStore } from "vuex";
import { saveFriendClue } from "@/api/chatapi";
import { ElNotification, ElMessage } from "element-plus";
import { RefreshRight } from "@element-plus/icons-vue";
import fastReplayItem from "./components/fastReplayItem";
import { initUserSystemData } from "@/api/userapi";
function initCustomClue() {
  return {
    fid: 0,
    sex: 0,
    nickname: "",
    mobile: "",
    wechat: "",
    qq: "",
    memo: "",
    province_id: 0,
    city_id: "",
    region_id: "",
    label_ids: [],
    type_id: 0,
  };
}
export default {
  components: { fastReplayItem, RefreshRight },
  setup() {
    let store = useStore();
    const bus = inject("$eventBus");
    const wsCmd = inject("wsCmd");

    const myRegion = inject("myRegion");

    const data = reactive({
      active_item: 1,
      customClueInfo: initCustomClue(),
      loadingSaveCustomClue: false,
      regionData: myRegion.regionData,
      selectedOptions: [],
      activeNames: ["1", "2"],
      userFlatList: [],
      selectedFastReplyItem: "fast_reply_type1",
      selectedFastReplySubItem: 1,
      searchFastReplyValue: "",
      preViewMessageItem: {},
      dialogPreviewMsgVisible: false,
    });

    //当前聊天账号
    const chatingAccount = computed(() => {
      return store.state.wsData.chatingAccountInfo;
    });
    function handleItemClick(item) {
      data.active_item = item;
    }

    //获取好友客资信息
    function receiveCustomClue(respMsg) {
      data.loadingSaveCustomClue = false;
      let code = respMsg.code;
      if (code != 1) {
        return;
      }

      let _data = respMsg.data;
      if (!_data) {
        return;
      }
      let fid = respMsg.data.fid;
      if (fid == chatingFriendInfo.value.cid) {
        data.customClueInfo = _data;

        let province_id = _data.province_id;
        let city_id = _data.city_id;
        let region_id = _data.region_id;
        data.selectedOptions = [];
        if(province_id){
          data.selectedOptions[0] = province_id;
        }


        if(city_id){
          data.selectedOptions[1] = city_id;
        }

        if(region_id){
          data.selectedOptions[2] = region_id;
        }


        //标签信息
        if (_data.label_ids?.length > 0) {
          data.userFlatList = _data.label_ids;
        } else {
          data.userFlatList = [];
        }
      } else {
        data.customClueInfo = initCustomClue();
        data.customClueInfo.type_id = chatingAccount.value?.customer_type_id
        data.customClueInfo.nickname =  chatingFriendInfo.value?.title
        data.selectedOptions = [];
        data.userFlatList = [];
      }
    }

    //保存好友消息结果
    function saveCustomClueResp(respMsg) {
      data.loadingSaveCustomClue = false;
      let code = respMsg.code;
      if (code != 1) {
        return;
      }

      let _data = respMsg.data;
      if (_data?.fid == chatingFriendInfo.value.cid) {
        ElNotification({
          title: "提示信息",
          message: "操作成功",
          type: "success",
        });
      }
    }

    //保存好友客资信息
    /**
     *
     * @param {sendWebhook} 是否发送web报单
     */
    const handleSaveFriendClue = (sendWebhook) => {
      saveFriendClueInner(sendWebhook);
    };

    const saveFriendClueInner = (sendWebhook) => {
      data.loadingSaveCustomClue = true;

      if(data.selectedOptions?.length >0){
        data.customClueInfo.province_id = data.selectedOptions[0];
      }else{
        data.customClueInfo.city_id = undefined
      }

      if(data.selectedOptions?.length >= 2){
        data.customClueInfo.city_id = data.selectedOptions[1];
      }else{
        data.customClueInfo.city_id = undefined
      }

      if(data.selectedOptions?.length == 3){
        data.customClueInfo.region_id = data.selectedOptions[2];
      }else{
        data.customClueInfo.region_id = undefined
      }
   
      if (data.userFlatList.length > 0) {
        data.customClueInfo.label_ids = data.userFlatList.map(
          (item) => item.id
        );
      } else {
        data.customClueInfo.label_ids = [];
      }
      saveFriendClue({
        ...data.customClueInfo,
        fid: chatingFriendInfo.value.cid,
        send_flg: sendWebhook === true ? 1 : 0,
      });
    };

    //当前聊天好友
    const chatingFriendInfo = computed(() => {
      return store.state.wsData.chatingFriendInfo;
    });

    //添加选中用户标签
    const handleSelectUserFlag = (command) => {
      console.log(command);
      let findIdx = data.userFlatList.findIndex(
        (item) => item.id == command.id
      );
      if (findIdx < 0) {
        data.userFlatList.push({
          id: command.id,
          name: command.name,
          color: command.color,
        });
        handleSaveFriendClue();
      }
      //todo 服务器交互
    };

    const handleRemoveTag = (tagItem) => {
      let findIdx = data.userFlatList.findIndex(
        (item) => item.id == tagItem.id
      );
      if (findIdx >= 0) {
        data.userFlatList.splice(findIdx, 1);
        handleSaveFriendClue();
      }
    };

    const userFlagList = computed(() => {
      let sysCfgInfo = store.state.wsData.sysCfgInfo;
      let labelList = sysCfgInfo?.label_id_list;
      return labelList;
    });

    //快捷回复列表
    const fastReplyList = computed(() => {
      if (store.state.wsData.sysCfgInfo?.fast_reply_list) {
        return (
          store.state.wsData.sysCfgInfo?.fast_reply_list[
            data.selectedFastReplyItem
          ] || []
        );
      }
      return [];
    });

    //线索分类列表
    const customerTypeList = computed(() => {
      let resList = store.state.wsData.sysCfgInfo?.customer_type_list || [];
      return [...resList, { id: 0, name: "未选择" }];
    });

    const fastReplySearchList = computed(() => {
      if (!data.searchFastReplyValue) {
        return [];
      }

      let returnList = [];
      if (!store.state.wsData.sysCfgInfo?.fast_reply_list) {
        return [];
      }
      let resultList =
        store.state.wsData.sysCfgInfo?.fast_reply_list[
          data.selectedFastReplyItem
        ] || [];
      for (let i = 0; i < resultList.length; i++) {
        let item = resultList[i];
        returnList.push(...item.list);
      }

      returnList = returnList?.filter((item) => {
        return item.title.includes(data.searchFastReplyValue);
      });
      return returnList || [];
    });

    //获取快捷回复分类
    const fastReplyListItem = computed(() => {
      return [
        { label: "公共快捷语", value: "fast_reply_type1" },
        // {label:'部门快捷语',value:'fast_reply_type2'},
        { label: "个人快捷语", value: "fast_reply_type3" },
      ];
    });

    //刷新系统配置信息
    const handleRefreshSysCfgInfo = () => {
      initUserSystemData().then(() => {
        ElMessage({
          message: "刷新成功",
          type: "success",
          plain: true,
        });
      });
    };

    watch(
      () => chatingFriendInfo,
      (newValue) => {
        data.loadingSaveCustomClue = false;
        data.userFlatList = [];

        data.customClueInfo = initCustomClue();
        data.customClueInfo.nickname = newValue.value.title;
        data.customClueInfo.fid = newValue.value.cid;
        data.customClueInfo.type_id = chatingAccount.value?.customer_type_id;
      },
      { deep: true }
    );

    onUnmounted(() => {
      //注册监听事件start---
      bus.off(`${wsCmd.cmd_8_resp}`); // listen
      bus.off(`${wsCmd.cmd_7_resp}`); // listen
      //注册监听事件end---
    });

    onMounted(() => {
      data.customClueInfo = initCustomClue();
      bus.on(`${wsCmd.cmd_8_resp}`, receiveCustomClue); // listen
      bus.on(`${wsCmd.cmd_7_resp}`, saveCustomClueResp); // listen
    });
    return {
      ...toRefs(data),
      chatingFriendInfo,
      userFlagList,
      fastReplyList,
      fastReplyListItem,
      fastReplySearchList,
      customerTypeList,
      chatingAccount,
      handleItemClick,
      handleSaveFriendClue,
      handleSelectUserFlag,
      handleRemoveTag,
      receiveCustomClue,
      saveCustomClueResp,
      handleRefreshSysCfgInfo,
    };
  },
};
</script>
  
  <style lang="scss" scoped>
::v-deep(.el-collapse) {
  background-color: transparent !important;
}
::v-deep(.el-collapse-item) {
  background-color: transparent !important;
}
::v-deep(.el-collapse-item__wrap) {
  border: none;
  background-color: transparent !important;
}
::v-deep(.el-collapse-item__header) {
  height: 35px;
  //background: radial-gradient(circle, rgb(140, 255, 0, .1) 23%, rgb(146, 254, 157, .5) 94%) !important;
  background: transparent;
  box-shadow: 0px 22px 30px -3px rgba(0, 0, 0, 0.1);
}
::v-deep(.el-collapse-el-collapse-item__content) {
  background-color: transparent !important;
  //background: radial-gradient(circle, rgb(140, 255, 0, .1) 23%, rgb(146, 254, 157, .1) 94%) !important;
  padding-bottom: 0;
}

::v-deep(.el-collapse-item__content) {
  //background: radial-gradient(circle, rgb(140, 255, 0, .1) 23%, rgb(146, 254, 157, .1) 94%) !important;
  padding-bottom: 0;
}

.preview_msg {
  border: 1px dashed rgba(0, 0, 0, 0.5);
  padding: 10px 5px;
  box-sizing: border-box;
  height: 200px;
}

.el-collapse_t {
  width: 100%;
  box-sizing: border-box;
  //background: radial-gradient(circle, rgb(140, 255, 0, .3) 23%, rgb(146, 254, 157, .5) 94%) !important;
  background: radial-gradient(
    circle,
    rgb(173, 181, 189, 0.8) 23%,
    rgb(173, 181, 189, 0.5) 94%
  ) !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
}
.el-collapse_t1 {
  background: radial-gradient(
    circle,
    rgb(173, 181, 189, 0.4) 23%,
    rgb(173, 181, 189, 0.3) 94%
  ) !important;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  padding-left: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}

.chat_custom_info_container {
  height: 100%;
  overflow: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5px;
  .customer_info {
    overflow: hidden;
    margin-bottom: 10px;
    box-sizing: border-box;
    flex-shrink: 0;
    // border: 1px solid rgb(146, 254, 157, 0.7);
    background: transparent;
    //background: rgb(131,58,180);
    //background: radial-gradient(circle, rgb(140, 255, 0, .1) 23%, rgb(146, 254, 157, .1) 94%);
    border-radius: 10px;
    border: 1px solid white;
    padding: 5px;
    .nameInfo {
      overflow: hidden;
      .title {
        // color:blue;
        padding: 3px 0;
        font-size: 14px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: radial-gradient(
        //   circle,
        //   rgb(140, 255, 0, 0.1) 23%,
        //   rgb(146, 254, 157, 0.1) 94%
        // );
      }
      .info_item {
        padding: 2px 0;
        padding-left: 20px;
        display: flex;
        align-items: center;
        .info_item_l {
          width: 50px;
          overflow: hidden;
          text-align: right;
          margin-right: 10px;
        }
        .info_item_r {
          flex: 1;
        }
      }
      .info_btns {
        box-sizing: border-box;
        padding: 5px 20px;
        display: flex;
        justify-content: center;
        .save_btn {
          width: 80px;
        }
      }
    }
  }
  .main {
    box-sizing: border-box;
    flex-grow: 1;
    flex-shrink: 0;
    //background: rgb(131,58,180);
    //background: radial-gradient(circle, rgb(140, 255, 0, .1) 23%, rgb(146, 254, 157, .1) 94%);
    // border: 1px solid rgb(146, 254, 157, 0.7);
    height: 1000px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    border: 1px solid white;
    padding: 5px;
    //background-color: pink;
    .main_title {
      height: 30px;
      flex-grow: 0;
      flex-shrink: 0;
      box-sizing: border-box;
      overflow: hidden;
      display: flex;
      align-items: center;
      background: radial-gradient(
        circle,
        rgb(140, 255, 0, 0.1) 23%,
        rgb(146, 254, 157, 0.1) 94%
      );
      justify-content: center;
      font-size: 14px;
    }
    .main_content {
      flex-grow: 1;

      .search {
        box-sizing: border-box;
        padding: 10px 0;
        text-align: center;
      }

      .fastReply_collapse {
        box-sizing: border-box;
        overflow: hidden;
        margin-right: 5px;
        .fastMsgItem {
          border-bottom: 1px solid white;
          height: 30px;
          box-sizing: border-box;
          overflow: hidden;
          white-space: nowrap;
          padding-left: 10px;
          display: flex;
          align-items: flex-start;
          cursor: pointer;
          .icon {
            padding-right: 10px;
          }
          .t {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
            width: 0;
            flex: 1;
            align-self: center;
          }
        }
        .fastMsgItem:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
</style>
       
       