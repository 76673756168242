
import { createApp } from 'vue'
import LayerConstructor from './layer.vue'
import domUtils from './utils/dom.js'
let $inst
let createMount = (opts) => {
const mountNode = document.createElement('div')
 document.body.appendChild(mountNode)
    const app = createApp(LayerConstructor, {...opts, modelValue: true,
        remove() {
        let vdom = document.querySelector('#' + opts.id)
         if(!vdom) return
        app.unmount(mountNode)
         document.body.removeChild(mountNode)
     }
  })
    return app.mount(mountNode)
}
function V3Layer(options = {}) {
  options.id = options.id || 'v3layer_' + domUtils.generateId()
 let vdom = document.querySelector('#' + options.id)
    if(options.id && vdom) return
 $inst = createMount(options)
  return $inst
}

/**
 * Message 消息提示
 */
V3Layer.message = function(options) {
  typeof options == 'string' && (options = { content: options });
    return V3Layer({icon: 'info', anim: 'fadeInUp', position: 't', lockScroll: false, shade: false, time: 2, layerStyle: 'margin-top:15px;',
     ...options, title: null, type: 'message'
    })
}

/**
 * Notification 通知提醒
 */
V3Layer.notify = function(options) {
  typeof options == 'string' && (options = { content: options });
    return V3Layer({
     anim: 'fadeInRight', position: 'rt', lockScroll: false, shade: false, xclose: true, time: 4.5, layerStyle: 'margin:20px 0 0 -20px;',...options,
        type: 'notify'
  })
}

/**
 * Popover 弹出框 | Popconfirm 气泡确认框
 */
V3Layer.popover = function(options) {
  return V3Layer({
     anim: 'fadeIn', lockScroll: false, shade: false, xclose: true, ...options, type: 'popover'
  })
}
V3Layer.install = (app) => {
 app.component('v3-layer', LayerConstructor)
app.provide('v3layer', V3Layer)
}
export default V3Layer