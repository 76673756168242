<template>
    <div >
        <el-popover
            placement="left"
            :title="fastReplyInfo.title || ''"
            :width="500"
            trigger="hover"
            content="this is content, this is content, this is content"
            >
            <template #reference>
                <div class="fastMsgItem"  @click="handlePreViewSendMsg(fastReplyInfo)">
                    <div class="icon">
                    <i class="iconfont icon-text" v-if="fastReplyInfo.content_type == 3"></i>
                    <i class="iconfont icon-iamge1" v-else-if="fastReplyInfo.content_type == 5"></i>
                    <i class="iconfont icon-yuyin" v-else-if="fastReplyInfo.content_type == 34"></i>
                    </div>
                    <div class="t" >
                    <span style="font-weight: bold;">【{{ fastReplyInfo.title }}】</span>
                    <span v-if="fastReplyInfo.content_type == 3">
                        <span style="color:#008b8b" v-html="handleFormatMsg(fastReplyInfo.content)"></span>
                    </span>
                    </div>
                </div>
            </template>
            <div>
                <div class="preview_msg">
                    <div style="color:#008b8b" v-if="fastReplyInfo.content_type == 3" v-html="handleFormatMsg(fastReplyInfo.content)"></div>
                    <div v-if="fastReplyInfo.content_type == 34">
                        <audio controls ref="audio" class="aud">
                                <source :src="fastReplyInfo.content" />
                        </audio>
                    </div>
                    <div v-if="fastReplyInfo.content_type == 5">
                        <el-image
                            style="width: 100px; height: 100px"
                            :src="fastReplyInfo.content"
                            :zoom-rate="1.2"
                            :max-scale="7"
                            :min-scale="0.2"
                            :preview-src-list="[fastReplyInfo.content]"
                            :initial-index="4"
                            fit="cover"
                            />
                    </div>
                    
                </div>
                <div style="display: flex;justify-content: flex-end;margin-top: 10px;">
                    <el-button  type="primary"  @click="handleSendMsg" :disabled="!(fastReplyInfo.content_type == 3 || fastReplyInfo.content_type == 5)">
                        发送
                    </el-button>
                </div>
            </div>
           
        </el-popover>


        <!-- 快捷消息与发送 -->
        <el-dialog v-model="dialogPreviewMsgVisible" title="消息预览" width="50%" @close="handleDialogClose" draggable>
            <div v-if="!editMsg">
                <div class="preview_msg" v-if="fastReplyInfo.content_type == 3" v-html="handleFormatMsg(fastReplyInfo.content)" style="color:#008b8b"></div>
                <div class="preview_msg" v-else-if="fastReplyInfo.content_type == 5" >
                    <el-image
                        style="width: 100px; height: 100px"
                        :src="fastReplyInfo.content"
                        :zoom-rate="1.2"
                        :max-scale="7"
                        :min-scale="0.2"
                        :preview-src-list="[fastReplyInfo.content]"
                        :initial-index="4"
                        fit="cover"
                    />
                </div>
                <div class="preview_msg"  v-else-if="fastReplyInfo.content_type == 34">
                    <audio controls class="aud">
                            <source :src="fastReplyInfo.content" />
                    </audio>
                </div>
                <div v-else class="preview_msg" style="color:red">暂不支持的消息类型</div>
            </div>

            <div v-else class="preview_msg">
                <div>
                    <textarea ref="editorRef" v-model="editorText"  name="textarea" style="width:100%;height: 100%;border:none;" maxlength="1024"></textarea>
                </div>
            </div>


            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="editMsg = !editMsg" v-if="fastReplyInfo.content_type == 3">
                        <span v-if="!editMsg">编辑</span>
                        <span v-else>退出编辑</span>
                    </el-button>
                    <el-button @click="dialogPreviewMsgVisible = false">取消</el-button>
                    <el-button @click="handleSendMsg" type="primary" :disabled="!(fastReplyInfo.content_type == 3 || fastReplyInfo.content_type == 5)">
                        发送
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
  </template>
  <script>
import { replaceEmoji } from "@/utils/emoji-util";
import {  reactive,toRefs,ref,inject } from 'vue';
import { messageTypeTrans } from "@/utils/commonUtils";
import { v4 as uuidv4 } from "uuid";
import { ElMessage } from "element-plus";
  export default {
    components: {},
    props:{
        fastReplyInfo:{
            type:Object,
            required: true
        }
    },
    setup(props) {
        const bus = inject("$eventBus");
        const eventbusCms = inject("eventbusCms");

        const editorRef = ref(null)
        const data = reactive({
            dialogPreviewMsgVisible:false,
            editMsg:false,
            editorText:props.fastReplyInfo.content
        })


        //预显示消息
        const handlePreViewSendMsg = ()=>{
            data.dialogPreviewMsgVisible = true
        }




        const handleFormatMsg = (msg) => {
            if (!msg || typeof msg != 'string') {
                return msg;
            }
            msg = msg.replace(/\n/g, "<br>");
            msg = msg.replace(/ /g, "&nbsp;");
            msg = replaceEmoji(msg);
            return msg;
        };

        //关闭弹窗
        const handleDialogClose = ()=>{
            data.editMsg = false
            data.editorText = props.fastReplyInfo.content
        }

        //发送消息
        const handleSendMsg = ()=>{
            let msgItem = props.fastReplyInfo
            if(!msgItem){
                ElMessage.error('没有选择需要发送的消息')
                return
            }
            let content_type = msgItem.content_type


            let msgtype = messageTypeTrans(content_type)
            if(!msgtype){
                ElMessage.error('暂不支持的消息类型')
                return
            }

            let value = ''
            if(content_type == 3){
                //如果是编辑状态的话取编辑框里面的内容
                if(data.editMsg){
                value = data.editorText
                }else{
                value = msgItem.content
                }
            }else if(content_type == 5){
                value = msgItem.content
            }
            if(!value){
                ElMessage.error('消息不能为空!')
                return
            }

            let sendMsgItem = {
                msgtype,
                value,
                s_uuid: uuidv4(),
            }
            bus.emit(eventbusCms.sendFriendMsg,sendMsgItem)
            data.dialogPreviewMsgVisible = false
        }

        return{
            ...toRefs(data),
            editorRef,
            handleFormatMsg,
            handlePreViewSendMsg,
            handleDialogClose,
            handleSendMsg,
        }
    },
  };
  </script>


<style lang="scss" scoped>
.preview_msg{
  border:1px dashed rgba(0,0,0,0.5);
  padding:10px 5px;
  box-sizing: border-box;
  height: 200px;
}
.fastMsgItem{
    border-bottom:1px solid white;
    height: 30px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 10px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    .icon{
        padding-right: 10px;
    }
    .t{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
        width:0;
        flex:1;
        align-self: center;
    }
}
.fastMsgItem:hover{
    background-color: rgba(0,0,0,0.1);
}
</style>
  