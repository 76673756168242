<!-- V3Layer弹窗组件（Vue3.0版） -->
<template>
 <div ref="elRef" v-show="opened" class="vui__layer" :class="{'vui__layer-closed': closeCls}" :id="id">
     <div v-if="JSON.parse(shade)" class="vlayer__overlay" @click="shadeClicked" :style="{opacity}"></div>
      <!-- //窗体 -->
    <div class="vlayer__wrap" :class="['anim-'+anim, type&&'popui__'+type, drawer&&'popui__drawer-'+drawer, xclose&&'vlayer-closable', tipArrow]" :style="[layerStyle]">
        <div v-if="title" class="vlayer__wrap-tit" v-html="title"></div>
      <div v-if="type=='toast'&&icon" class="vlayer__toast-icon" :class="['vlayer__toast-'+icon]" v-html="toastIcon[icon]"></div>
         <div class="vlayer__wrap-cntbox">
         <template v-if="$slots.content">
        <div class="vlayer__wrap-cnt"><slot name="content" /></div>
         </template>
         <template v-else>
         <template v-if="content">
        <iframe v-if="type=='iframe'" scrolling="auto" allowtransparency="true" frameborder="0" :src="content"></iframe>
          <div v-else-if="type=='message' || type=='notify' || type=='popover'" class="vlayer__wrap-cnt"><i v-if="icon" class="vlayer-msg__icon" :class="icon" v-html="messageIcon[icon]"></i>
          <div class="vlayer-msg__group"><div v-if="title" class="vlayer-msg__title" v-html="title"></div><div class="vlayer-msg__content" v-html="content"></div></div>
          </div><div v-else class="vlayer__wrap-cnt" v-html="content"></div>
         </template>
        </template>
          <slot />
         </div><div v-if="btns" class="vlayer__wrap-btns"><span v-for="(btn,index) in btns" :key="index" class="btn" :class="{'btn-disabled': btn.disabled}" :style="btn.style" @click="btnClicked($event,index)" v-html="btn.text"></span>
         </div><span v-if="xclose" class="vlayer__xclose" :class="!maximize&&xposition" :style="{'color': xcolor}" @click="close"><svg viewBox="0 0 1024 1024"><path d="M825.76 246.496L560.256 512 825.76 777.504l-48.256 48.256-265.536-265.504L246.496 825.76l-48.256-48.256 265.472-265.536L198.24 246.496l48.256-48.256L512 463.744 777.504 198.24z"/></svg></span>
         <span v-if="maximize" class="vlayer__maximize" @click="maximizeClicked($event)"></span><span v-if="resize" class="vlayer__resize"></span>
      </div><div class="vlayer__dragfix"></div>
 </div>
</template>
<script>
 import {onMounted, onUnmounted, ref, reactive, watch, toRefs, nextTick} from 'vue'
import domUtils from './utils/dom.js'
let $index = 0, $locknum = 0, $timer = {}, $closeTimer = null
    export default {
        props: {
        modelValue: { type: Boolean, default: false },
          id: {type: String, default: ''
        },
         title: String, content: String,type: String,layerStyle: String,icon: String,
        shade: { type: [Boolean, String], default: true },shadeClose: { type: [Boolean, String], default: true },
         lockScroll: { type: [Boolean, String], default: true },opacity: { type: [Number, String], default: '' },
        xclose: Boolean,xposition: { type: String, default: 'right' },xcolor: { type: String, default: '#333' },
         anim: { type: String, default: 'scaleIn' },position: { type: [Array, String], default: 'auto' },
         drawer: String,follow: { type: [Array, String], default: null },time: { type: [Number, String], default: 0 },zIndex: { type: [Number, String], default: '8080' },
          teleport: { type: [String, Object], default: '' }, topmost: Boolean,area: { type: [Array, String], default: 'auto' },maxWidth: { type: [Number, String], default: 375 },
        maximize: Boolean,fullscreen: Boolean, fixed: { type: [Boolean, String], default: true },
         drag: { type: [Boolean, String], default: '.vlayer__wrap-tit' }, dragOut: { type: [Boolean, String], default: false },
         lockAxis: String, resize: Boolean, btns: {type: Array, default: null
         },
         onSuccess: { type: Function, default: null },
          onEnd: { type: Function, default: null },
        remove: Function,
        },
        emits: ['update:modelValue'],
        setup(props, context) {
          const elRef = ref(null);
        const data = reactive({opened: false,
             closeCls: '',toastIcon: {
              loading: '<svg viewBox="25 25 50 50"><circle fill="none" cx="50" cy="50" r="20"></circle></svg>',success: '<svg viewBox="0 0 1024 1024"><path fill="none" d="M75.712 445.712l240.176 185.52s13.248 6.624 29.808 0l591.36-493.872s84.272-17.968 68.64 71.488c-57.04 57.968-638.464 617.856-638.464 617.856s-38.096 21.536-74.544 0C256.272 790.256 12.816 523.568 12.816 523.568s-6.672-64.592 62.896-77.856z"/></svg>',
              fail: '<svg viewBox="0 0 1024 1024"><path fill="none" d="M450.602 665.598a62.464 62.464 0 0 0 122.88 0l40.96-563.198A102.615 102.615 0 0 0 512.042 0a105.256 105.256 0 0 0-102.4 112.64l40.96 552.958zm61.44 153.6a102.4 102.4 0 1 0 102.4 102.4 96.74 96.74 0 0 0-102.4-102.4z"/></svg>',
              },
              messageIcon: {
              loading: '<svg viewBox="25 25 50 50"><circle fill="none" cx="50" cy="50" r="20"></circle></svg>',
                success: '<svg viewBox="0 0 1024 1024"><path d="M512 85.333c235.648 0 426.667 191.019 426.667 426.667S747.648 938.667 512 938.667 85.333 747.648 85.333 512 276.352 85.333 512 85.333zm-74.965 550.4l-90.582-90.581a42.667 42.667 0 1 0-60.33 60.33l120.704 120.705a42.667 42.667 0 0 0 60.33 0L768.811 424.49a42.667 42.667 0 1 0-60.288-60.331L436.992 635.648z" /></svg>',
             error: '<svg viewBox="0 0 1024 1024"><path d="M512 85.333C276.352 85.333 85.333 276.352 85.333 512S276.352 938.667 512 938.667 938.667 747.648 938.667 512 747.648 85.333 512 85.333zm128.427 606.72l-129.75-129.749-129.066 129.024a35.968 35.968 0 1 1-50.902-50.901L459.733 511.36 329.301 380.928a35.968 35.968 0 1 1 50.859-50.944l130.475 130.475 129.706-129.75a35.968 35.968 0 1 1 50.944 50.902L561.536 511.36l129.75 129.75a35.968 35.968 0 1 1-50.902 50.943z" /></svg>',
              warning: '<svg viewBox="0 0 1024 1024"><path d="M512 941.12q-89.28 0-167.52-34.08t-136.32-92.16T116 678.08t-34.08-168T116 342.56t92.16-136.32 136.32-92.16T512 80t168 34.08 136.8 92.16 92.16 136.32 34.08 167.52-34.08 168-92.16 136.8T680 907.04t-168 34.08zM460.16 569.6q0 23.04 14.88 38.88T512 624.32t37.44-15.84 15.36-38.88V248q0-23.04-15.36-36.96T512 197.12t-37.44 14.4-15.36 37.44zM512 688.64q-27.84 0-47.52 19.68t-19.68 47.52 19.68 47.52T512 823.04t48-19.68 20.16-47.52T560 708.32t-48-19.68z"/></svg>',
               info: '<svg viewBox="0 0 1024 1024"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm84 343.1l-87 301.4c-4.8 17.2-7.2 28.6-7.2 33.9 0 3.1 1.3 6 3.8 8.7s5.2 4 8.1 4c4.8 0 9.6-2.1 14.4-6.4 12.7-10.5 28-29.4 45.8-56.8l14.4 8.5c-42.7 74.4-88 111.6-136.1 111.6-18.4 0-33-5.2-43.9-15.5-10.9-10.3-16.3-23.4-16.3-39.2 0-10.5 2.4-23.7 7.2-39.9l58.9-202.7c5.7-19.5 8.5-34.2 8.5-44.1 0-6.2-2.7-11.7-8.1-16.5-5.4-4.8-12.7-7.2-22-7.2-4.2 0-9.3.1-15.3.4l5.5-17L570.4 407H596v.1zm17.8-88.7c-12.2 12.2-26.9 18.2-44.1 18.2-17 0-31.5-6.1-43.7-18.2-12.2-12.2-18.2-26.9-18.2-44.1s6-31.9 18-44.1c12-12.1 26.6-18.2 43.9-18.2 17.5 0 32.3 6.1 44.3 18.2 12 12.2 18 26.9 18 44.1s-6.1 31.9-18.2 44.1z"/></svg>',
             },vlayerOpts: {},tipArrow: null,
            })
            onMounted(() => {
             if(props.modelValue) {open();
              }
             window.addEventListener('resize', autopos, false);
            })

            onUnmounted(() => {
             window.removeEventListener('resize', autopos, false);clearTimeout($closeTimer);
            })
            // 监听弹层v-model
            watch(() => props.modelValue, (val) => {
             if(val) {open();
                }else {close();
                }
            })
            const open = () => {
            if(data.opened) return;
              data.opened = true;
                typeof props.onSuccess === 'function' && props.onSuccess();
             nextTick(() => {
                 if(props.teleport) {
                  const dom = elRef.value;
                 let teleportNode = document.querySelector(props.teleport);
                teleportNode.appendChild(dom);
                  }
                 auto();
                })
              callback();
            }
            // 关闭弹窗
            const close = () => {
              if(!data.opened) return;
             let dom = elRef.value;
            let vlayero = dom.querySelector('.vlayer__wrap');
              let ocnt = dom.querySelector('.vlayer__wrap-cntbox');
             let omax = dom.querySelector('.vlayer__maximize');
                data.closeCls = true;
            clearTimeout($closeTimer);
             $closeTimer = setTimeout(() => {
                 data.opened = false;
                 data.closeCls = false;
                 if(data.vlayerOpts.lockScroll) {
                  $locknum--;
                     if(!$locknum) {
                    document.body.style.paddingRight = '';
                    document.body.classList.remove('vui__body-hidden');
                     }
                 }
                 if(props.time) {$index--;
                 }
                 vlayero.style.width = vlayero.style.height = vlayero.style.top = vlayero.style.left = '';
                 ocnt.style.height = '';
                omax && omax.classList.contains('maximized') && omax.classList.remove('maximized');
                  data.vlayerOpts.isBodyOverflow && (document.body.style.overflow = '');
                context.emit('update:modelValue', false);
                 typeof props.onEnd === 'function' && props.onEnd();
                 typeof props.remove === 'function' && props.remove();
              }, 200)
          }
            const auto = () => {
              let isLockScroll = JSON.parse(props.lockScroll);
            let isFixed = JSON.parse(props.fixed);
              let dom = elRef.value;
             let vlayero = dom.querySelector('.vlayer__wrap');
             let overlay = dom.querySelector('.vlayer__overlay');
            let otit = dom.querySelector('.vlayer__wrap-tit');
              let ocnt = dom.querySelector('.vlayer__wrap-cntbox');
            let obtn = dom.querySelector('.vlayer__wrap-btns');
             overlay && (overlay.style.zIndex = domUtils.getZIndex(props.zIndex) + 1);
              vlayero.style.zIndex = domUtils.getZIndex(props.zIndex) + 1;
            data.vlayerOpts.lockScroll = (isLockScroll && isFixed && !props.follow) ? true : false;
             data.vlayerOpts.area = typeof props.area === 'string' ? ([props.area == 'auto' ? '' : props.area, '']) : (props.area);
             if(data.vlayerOpts.lockScroll) {
                let scrollBarWidth = domUtils.getScrollBarSize();
              let bodyHasOverflow = document.documentElement.clientHeight < document.body.scrollHeight;
                 if(scrollBarWidth > 0 && bodyHasOverflow) {
              document.body.style.paddingRight = scrollBarWidth + 'px';
                  }
             if(!document.body.classList.contains('vui__body-hidden')) {
                document.body.classList.add('vui__body-hidden');
                 }
             $locknum++;
             }
            vlayero.style.width = data.vlayerOpts.area[0];
              vlayero.style.height = data.vlayerOpts.area[1];
             if(data.vlayerOpts.area[0] == '' && props.maxWidth > 0) {
                if(vlayero.offsetWidth > props.maxWidth) {
                 vlayero.style.width = props.maxWidth + 'px';
                 }
             }
             // 设置内容区高度
             let s = [vlayero.offsetWidth, vlayero.offsetHeight];
             let t = otit ? otit.offsetHeight : 0;
             let b = obtn ? obtn.offsetHeight : 0;
              if(data.vlayerOpts.area[1] == '') {
                 if(isFixed && s[1] > domUtils.client('height')) {
                ocnt.style.height = domUtils.client('height') - t - b + 'px';
                  }
             } else {
             ocnt.style.height = s[1] - t - b + 'px';
             }
             if(props.drawer == 'left' || props.drawer == 'right') { ocnt.style.height = s[1] - t - b + 'px';
             }
             autopos();
              // 全屏弹窗
             if(props.fullscreen) { full();
              }
             // 弹窗拖动|缩放
             move();
            }
            const autopos = () => {
            if(!data.opened) return;
              let oL, oT
            let pos = props.position;
            let isFixed = JSON.parse(props.fixed);
             let dom = elRef.value;
              let vlayero = dom.querySelector('.vlayer__wrap');
             if(!isFixed || props.follow) {vlayero.style.position = 'absolute';
             }
             let area = [domUtils.client('width'), domUtils.client('height'), vlayero.offsetWidth, vlayero.offsetHeight]
             oL = (area[0] - area[2]) / 2;
            oT = (area[1] - area[3]) / 2;
             if(props.follow) { offset();
              }else {
            typeof pos === 'object' ? (
             oL = parseFloat(pos[0]) || 0, oT = parseFloat(pos[1]) || 0
             ) : (
              pos == 't' ? oT = 0 : 
              pos == 'r' ? oL = area[0] - area[2] : 
               pos == 'b' ? oT = area[1] - area[3] : 
             pos == 'l' ? oL = 0 : 
              pos == 'lt' ? (oL = 0, oT = 0) : 
             pos == 'rt' ? (oL = area[0] - area[2], oT = 0) : 
            pos == 'lb' ? (oL = 0, oT = area[1] - area[3]) :
             pos == 'rb' ? (oL = area[0] - area[2], oT = area[1] - area[3]) : 
                null
             )
              vlayero.style.left = parseFloat(isFixed ? oL : domUtils.scroll('left') + oL) + 'px';
            vlayero.style.top = parseFloat(isFixed ? oT : domUtils.scroll('top') + oT) + 'px';
              }
            }
            const offset = () => {
            let oW, oH, pS
              let dom = elRef.value
             let vlayero = dom.querySelector('.vlayer__wrap');
              oW = vlayero.offsetWidth;
             oH = vlayero.offsetHeight;
            pS = domUtils.getFollowRect(props.follow, oW, oH);
            data.tipArrow = pS[2];
            vlayero.style.left = pS[0] + 'px';
             vlayero.style.top = pS[1] + 'px';
            }
            const full = () => {
              let timer
            let isFixed = JSON.parse(props.fixed);
             let dom = elRef.value;
              let vlayero = dom.querySelector('.vlayer__wrap');
             let otit = dom.querySelector('.vlayer__wrap-tit');
              let ocnt = dom.querySelector('.vlayer__wrap-cntbox');
             let obtn = dom.querySelector('.vlayer__wrap-btns');
              let omax = dom.querySelector('.vlayer__maximize');
            let t = otit ? otit.offsetHeight : 0
             let b = obtn ? obtn.offsetHeight : 0
            let rect = [parseFloat(domUtils.getStyle(vlayero, 'left')), parseFloat(domUtils.getStyle(vlayero, 'top')),
                parseFloat(domUtils.getStyle(vlayero, 'width')), parseFloat(domUtils.getStyle(vlayero, 'height'))||vlayero.offsetHeight]
             // 记录弹窗 left|top|width|height
             data.vlayerOpts.rect = rect;
            if(!data.vlayerOpts.lockScroll) {
                 data.vlayerOpts.isBodyOverflow = true;
                document.body.style.overflow = 'hidden';
             }
             props.maximize && omax.classList.add('maximized')
            clearTimeout(timer);
             timer = setTimeout(() => {
              vlayero.style.left = isFixed ? 0 : domUtils.scroll('left') + 'px';
                vlayero.style.top = isFixed ? 0 : domUtils.scroll('top') + 'px';
              vlayero.style.width = domUtils.client('width') + 'px';
              vlayero.style.height = domUtils.client('height') + 'px';
              ocnt.style.height = domUtils.client('height') - t - b + 'px';
              }, 16);
            }
            const restore = () => {
             let dom = elRef.value;
            let vlayero = dom.querySelector('.vlayer__wrap'); let otit = dom.querySelector('.vlayer__wrap-tit'); let ocnt = dom.querySelector('.vlayer__wrap-cntbox');
              let obtn = dom.querySelector('.vlayer__wrap-btns'); let omax = dom.querySelector('.vlayer__maximize');
             let t = otit ? otit.offsetHeight : 0; let b = obtn ? obtn.offsetHeight : 0;
              if(!data.vlayerOpts.lockScroll) {
             data.vlayerOpts.isBodyOverflow = false;
                 document.body.style.overflow = '';
             }
             props.maximize && omax.classList.remove('maximized')
             vlayero.style.left = parseFloat(data.vlayerOpts.rect[0]) + 'px';
            vlayero.style.top = parseFloat(data.vlayerOpts.rect[1]) + 'px';
              vlayero.style.width = parseFloat(data.vlayerOpts.rect[2]) + 'px';
              vlayero.style.height = parseFloat(data.vlayerOpts.rect[3]) + 'px';
            ocnt.style.height = parseFloat(data.vlayerOpts.rect[3]) - t - b + 'px';
            }
            const move = () => {
             let isFixed = JSON.parse(props.fixed);
            let isdragOut = JSON.parse(props.dragOut);
             let c = {}
             let dom = elRef.value;
            let vlayero = dom.querySelector('.vlayer__wrap');
             let otit = dom.querySelector('.vlayer__wrap-tit');
             let ocnt = dom.querySelector('.vlayer__wrap-cntbox');
            let obtn = dom.querySelector('.vlayer__wrap-btns');
             let odrag = dom.querySelector(props.drag);
            let oresize = dom.querySelector('.vlayer__resize');
          let ofix = dom.querySelector('.vlayer__dragfix');
            if(odrag) {
               odrag.style.cursor = domUtils.isIE() ? 'move' : 'grab';
                 domUtils.on(odrag, 'mousedown', function(e) {
                domUtils.isIE() ? (e.returnValue = false, e.cancelBubble = true) : (e.stopPropagation(), e.preventDefault())
                  c.dragStart = true;
                  c.pos = [e.clientX, e.clientY]
                     c.area = [parseFloat(domUtils.getStyle(vlayero, 'left')), parseFloat(domUtils.getStyle(vlayero, 'top')), parseFloat(domUtils.getStyle(vlayero, 'width')), parseFloat(domUtils.getStyle(vlayero, 'height'))||vlayero.offsetHeight
                     ]
                    c.client = [domUtils.client('width'), domUtils.client('height')]
                     c.scroll = [domUtils.scroll('height'), domUtils.scroll('left'), domUtils.scroll('top')]
                    ofix.style.cursor = domUtils.isIE() ? 'move' : 'grabbing';
                  ofix.style.display = 'block';
                if(props.topmost) {vlayero.style.zIndex = domUtils.getZIndex(props.zIndex) + 1;
                 }
                 })
                }
                if(oresize) {
                  domUtils.on(oresize, 'mousedown', function(e) {
                  domUtils.isIE() ? (e.returnValue = false, e.cancelBubble = true) : (e.stopPropagation(), e.preventDefault())
                    c.resizeStart = true;
                     c.pos = [e.clientX, e.clientY]
                    c.area = [parseFloat(domUtils.getStyle(vlayero, 'width')), parseFloat(domUtils.getStyle(vlayero, 'height'))||vlayero.offsetHeight]
                      ofix.style.cursor = 'se-resize';
                    ofix.style.display = 'block';
                 if(props.topmost) {
                  vlayero.style.zIndex = domUtils.getZIndex(props.zIndex) + 1;
                 }})
                }
                domUtils.on(document, 'mousemove', function(e) {
                 if(c.dragStart) {
                  let iL = e.clientX - c.pos[0] + c.area[0]
                    let iT = e.clientY - c.pos[1] + c.area[1]
                  let fixL = isFixed ? 0 : c.scroll[1]
                  let fixT = isFixed ? 0 : c.scroll[2]
                    let iMaxL = c.client[0] + fixL - c.area[2]
                  let iMaxT = c.client[1] + fixT - c.area[3]
                   let oMaxT = c.scroll[0] - c.area[3]
                      if(isdragOut) {
                        iT = iT < 0 ? 0 : iT
                         iT = (iT > oMaxT) ? oMaxT : iT
                    }else {
                     iL = (iL < fixL) ? fixL : iL
                         iL = (iL > iMaxL) ? iMaxL : iL
                      iT = (iT < fixT) ? fixT : iT
                       iT = (iT > iMaxT) ? iMaxT : iT
                     }
                    // 限制拖拽方向（v:垂直 h:水平，默认不限制）
                     props.lockAxis == 'h' ? (vlayero.style.left = iL + 'px')
                    : props.lockAxis == 'v' ? (vlayero.style.top = iT + 'px')
                     : (vlayero.style.left = iL + 'px', vlayero.style.top = iT + 'px')
                    }
                  if(c.resizeStart) {
                    let iW = e.clientX - c.pos[0] + c.area[0]
                      let iH = e.clientY - c.pos[1] + c.area[1]
                     let t = otit ? otit.offsetHeight : 0
                       let b = obtn ? obtn.offsetHeight : 0
                      iW <= 260 && (iW = 260)
                    iH - t - b <= 64 && (iH = 64 + t + b)
                      vlayero.style.width = iW + 'px';
                    vlayero.style.height = iH + 'px';
                     ocnt.style.height = iH - t - b + 'px';
                  }
                })
                domUtils.on(document, 'mouseup', function() {
                 c.dragStart && (delete c.dragStart, ofix.style.display = 'none'
                    )
                c.resizeStart && (delete c.resizeStart, ofix.style.display = 'none'
                    )
                document.onmouseup = null
              })
            }
            const callback = () => {
            if(props.time) {
              $index++
             // 防止重复点击
              if($timer[$index] !== null) clearTimeout($timer[$index])
               $timer[$index] = setTimeout(() => {
                 close();
                }, parseInt(props.time) * 1000)
             }
            }
            // 点击最大化按钮
            const maximizeClicked = (e) => {
             let o = e.target
            if(o.classList.contains('maximized')) {restore();
              } else { full();
             }
            }
         const shadeClicked = () => {
              if(JSON.parse(props.shadeClose)) {close();
             }
            }
        const btnClicked = (e, index) => {
           let btn = props.btns[index]
            if(!btn.disabled) {typeof btn.click === 'function' && btn.click(e)
             }
          }
        return {...toRefs(data), elRef,close, maximizeClicked,shadeClicked, btnClicked,
          }
      }
 }
</script>
<style>
body.vui__body-hidden{overflow-y:hidden!important;touch-action:none}
.vui__layer *{font-style:normal;list-style:none}
.vlayer__overlay{background:black;opacity:.5;pointer-events:auto;height:100%;width:100%;position:fixed;top:0;left:0;z-index:inherit;animation:mask-fadeIn .5s}
@keyframes mask-fadeIn{0%{opacity:0}
}.vlayer__wrap{background:#fff;border-radius:2px;box-shadow:0 1px 3px rgba(0,0,0,.2);pointer-events:auto;font-size:14px;font-family:"PingFang SC","Helvetica Neue",Helvetica,Arial,sans-serif;text-align:left;position:fixed;left:0;top:0;z-index:inherit}
.vlayer__wrap-tit{background-color:#f9f9f9;border-bottom:1px solid #eee;border-radius:2px 2px 0 0;color:#1f1f1f;font-size:14px;padding:0 60px 0 20px;height:42px;line-height:42px}
.vlayer__wrap-cntbox{overflow-x:hidden;overflow-y:auto}.vlayer__wrap-cnt{padding:15px;text-align:center;line-height:1.3;word-wrap:break-word;word-break:break-all}
.vlayer__wrap-btns{display:flex;align-items:center;line-height:48px;position:relative;-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;user-select:none}
.vlayer__wrap-btns:after{content:'';border-top:1px solid #d5d5d5;color:#d5d5d5;height:1px;position:absolute;left:0;right:0;top:0;z-index:5;transform:scaleY(.5);-webkit-transform:scaleY(.5);transform-origin:0 0;-webkit-transform-origin:0 0}
.vlayer__wrap-btns .btn{color:#1f1f1f;cursor:pointer;display:block;flex:1;font-size:14px;text-align:center;-webkit-tap-highlight-color:rgba(0,0,0,0);position:relative;z-index:3;transition:background-color .3s}
.vlayer__wrap-btns .btn:active{background:#eee}.vlayer__wrap-btns .btn.btn-disabled{color:#d5d5d5!important;cursor:not-allowed}
.vlayer__wrap-btns .btn.btn-disabled:active{background:none}.vlayer__wrap-btns .btn:after{content:"";border-left:1px solid #d5d5d5;color:#d5d5d5;width:1px;position:absolute;top:0;bottom:0;left:0;transform:scaleX(.5);-webkit-transform:scaleX(.5);transform-origin:0 0;-webkit-transform-origin:0 0}
.vlayer__wrap-btns .btn:first-child:after{display:none}.vlayer__wrap-btns .btn:first-child{border-bottom-left-radius:2px}
.vlayer__wrap-btns .btn:last-child{border-bottom-right-radius:2px}.vlayer__xclose{display:flex;align-items:center;justify-content:center;cursor:pointer;height:18px;width:18px;position:absolute;top:12px;right:12px;z-index:1001}
.vlayer__xclose.left{left:12px;right:auto}.vlayer__xclose.top{background:#fff;border-radius:50%;height:30px;width:30px;top:-40px;right:50%;transform:translateX(50%)}
.vlayer__xclose.bottom{background:#fff;border-radius:50%;height:30px;width:30px;top:auto;bottom:-40px;right:50%;transform:translateX(50%)}
.vlayer__xclose svg{display:block;font-size:18px;height:1em;width:1em}
.vlayer__xclose svg path{fill:currentColor}.vlayer__xclose:hover{opacity:.5}.vlayer__maximize{display:flex;align-items:center;justify-content:center;cursor:pointer;color:#333;height:18px;width:18px;position:absolute;top:12px;right:12px;z-index:1001}
.vlayer-closable .vlayer__maximize{right:40px}.vlayer__maximize:before{content:'□';font-size:25px;font-family:'Segoe UI';position:relative;top:-4px;left:0}
.vlayer__maximize.maximized:before{left:4px}.vlayer__maximize.maximized:after{content:'□';font-size:25px;font-family:'Segoe UI';position:relative;top:-6px;left:-8px}.vlayer__maximize:hover{opacity:.5}.vlayer__resize{cursor:se-resize;height:15px;width:15px;position:absolute;right:0;bottom:0;z-index:1001}
.vlayer__dragfix{display:none;opacity:0;height:100%;width:100%;position:fixed;left:0;top:0;z-index:20201028}
.vui__layer-closed .vlayer__overlay{animation:anim-fadeOut .3s}
.vui__layer-closed .vlayer__wrap{animation:anim-fadeOut .3s}.vui__layer-closed .popui__footer,.vui__layer-closed .popui__actionsheet,.vui__layer-closed .popui__actionsheetPicker{animation:anim-fadeOutDown .3s}
.vui__layer-closed .anim-scaleIn{animation:anim-ScaleOut .3s}.vui__layer-closed .anim-fadeInUp,.vui__layer-closed .popui__drawer-top{animation:anim-fadeOutUp .3s!important}
.vui__layer-closed .anim-fadeInRight,.vui__layer-closed .popui__drawer-right{animation:anim-fadeOutLeft .3s!important}
.vui__layer-closed .anim-fadeInDown,.vui__layer-closed .popui__drawer-bottom{animation:anim-fadeOutDown .3s!important}
.vui__layer-closed .anim-fadeInLeft,.vui__layer-closed .popui__drawer-left{animation:anim-fadeOutRight .3s!important}
.popui__toast{display:-webkit-box;display:-webkit-flex;display:flex;display:-ms-flexbox;align-items:center;justify-content:center;background:rgba(17,17,17,.7);border-radius:8px;color:#fff;box-sizing:content-box;margin:0 auto;padding:15px;min-height:90px;width:90px;-webkit-box-direction:normal;-moz-box-direction:normal;-webkit-box-orient:vertical;-moz-box-orient:vertical;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column}
.popui__toast .vlayer__wrap-cnt{padding:0}.popui__toast .vlayer__toast-icon{display:inline-block;color:#fff;margin-bottom:10px;height:32px;width:32px;position:relative}
.popui__toast .vlayer__toast-icon svg{display:block;height:100%;width:100%}
.popui__toast .vlayer__toast-icon svg path{fill:currentColor}.popui__toast .vlayer__toast-loading{animation:anim__rotate 2s linear infinite}.popui__toast .vlayer__toast-loading circle{stroke:currentColor;stroke-width:3;stroke-linecap:round;animation:anim__circular 1.5s ease-in-out infinite}
.popui__footer{background:0 0;margin:0 auto;max-width:100%}.popui__footer .vlayer__wrap-cnt{background-color:rgba(255,255,255,.9);border-radius:6px 6px 0 0;padding:15px 10px}
.popui__footer .vlayer__wrap-btns{display:block}.popui__footer .vlayer__wrap-btns:after{display:none}.popui__footer .vlayer__wrap-btns .btn{background:rgba(255,255,255,.9);border-radius:0}.popui__footer .vlayer__wrap-btns .btn:active{background:#d5d5d5}
.popui__footer .vlayer__wrap-btns .btn:after{display:none}.popui__footer .vlayer__wrap-btns .btn:before{content:'';border-top:1px solid #c5c5c5;color:#c5c5c5;height:1px;position:absolute;left:0;right:0;top:0;z-index:5;transform:scaleY(.5);-webkit-transform:scaleY(.5);transform-origin:0 0;-webkit-transform-origin:0 0}
.popui__footer .vlayer__wrap-btns .btn:last-child:before{display:none}.popui__footer .vlayer__wrap-btns:first-child .btn:first-child{border-radius:6px 6px 0 0}
.popui__footer .vlayer__wrap-btns:first-child .btn:first-child:before{display:none}.popui__footer .vlayer__wrap-btns .btn:nth-last-child(2){border-radius:0 0 6px 6px}.popui__footer .vlayer__wrap-btns .btn:nth-last-child(1){border-radius:6px;margin-top:10px}
.popui__actionsheet{margin:0 auto;max-width:100%}.popui__actionsheet .vlayer__wrap-cnt{padding:15px 10px}.popui__actionsheet .vlayer__wrap-btns{display:block}
.popui__actionsheet .vlayer__wrap-btns:after{display:none}.popui__actionsheet .vlayer__wrap-btns .btn:after{display:none}.popui__actionsheet .vlayer__wrap-btns .btn:before{content:'';border-top:1px solid #d5d5d5;color:#d5d5d5;height:1px;position:absolute;left:0;right:0;top:0;z-index:5;transform:scaleY(.5);-webkit-transform:scaleY(.5);transform-origin:0 0;-webkit-transform-origin:0 0}
.popui__actionsheet .vlayer__wrap-btns .btn:nth-last-child(1){border-top:8px solid #f5f5f5;border-radius:0 0 2px 2px}
.popui__actionsheet .vlayer__wrap-btns .btn:nth-last-child(1):before{display:none}
.popui__actionsheetPicker{margin:0 auto;max-width:100%}.popui__actionsheetPicker .vlayer__wrap-tit{font-size:16px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;text-align:center;padding:0 70px}
.popui__actionsheetPicker .vlayer__wrap-cnt{padding:15px 20px;text-align:left}
.popui__actionsheetPicker .vlayer__wrap-btns{display:block;line-height:42px;height:0;width:100%;position:absolute;left:0;top:0}
.popui__actionsheetPicker .vlayer__wrap-btns:after{display:none}.popui__actionsheetPicker .vlayer__wrap-btns .btn{border-radius:0;display:inline-block;padding:0 20px;text-align:left}
.popui__actionsheetPicker .vlayer__wrap-btns .btn:active{background:none;opacity:.7}
.popui__actionsheetPicker .vlayer__wrap-btns .btn:last-child{float:right}.popui__actionsheetPicker .vlayer__wrap-btns .btn:last-child:after{display:none}.vui__layer-closed .popui__ios,.vui__layer-closed .popui__android,.vui__layer-closed .popui__androidSheet{animation:anim-ScaleOut .3s}
.popui__ios{width:80%}.popui__ios .vlayer__wrap-tit{background:none;border-bottom:0;text-align:center;font-size:18px;padding:10px 25px 0}
.popui__ios .vlayer__wrap-cnt{color:#808080;padding:0 25px 15px;min-height:40px}.popui__ios .vlayer__wrap-cntbox:first-child .vlayer__wrap-cnt{color:#1f1f1f;padding:40px 20px 25px}
.popui__ios .vlayer__wrap-btns .btn{font-size:14px}.popui__android{width:80%}.popui__android .vlayer__wrap-tit{background:none;border-bottom:0;font-size:18px;padding:10px 25px 0}
.popui__android .vlayer__wrap-cnt{color:#808080;font-size:15px;padding:5px 25px 35px;text-align:left;min-height:40px}
.popui__android .vlayer__wrap-cntbox:first-child .vlayer__wrap-cnt{color:#1f1f1f;padding:25px 25px 35px}
.popui__android .vlayer__wrap-btns{display:block;padding:0 25px 15px;text-align:right;line-height:34px}
.popui__android .vlayer__wrap-btns:after{display:none}.popui__android .vlayer__wrap-btns .btn{border-radius:2px;font-size:14px;display:inline-block;vertical-align:top;padding:0 20px}
.popui__android .vlayer__wrap-btns .btn:after{display:none}.popui__android .vlayer__wrap-btns .btn:first-child{color:#808080}.popui__android .vlayer__wrap-btns .btn:last-child{margin-right:-10px}
.popui__androidSheet{width:80%}.popui__androidSheet .vlayer__wrap-tit{background:none;border-bottom:0;font-size:21px;padding:5px 20px 0;text-align:left}
.popui__androidSheet .vlayer__wrap-cnt{color:#808080;font-size:17px;padding:0 20px 15px;text-align:left}
.popui__androidSheet .vlayer__wrap-cntbox:first-child .vlayer__wrap-cnt{color:#1f1f1f;padding:15px 20px 5px}
.popui__androidSheet .vlayer__wrap-btns{display:block}
.popui__androidSheet .vlayer__wrap-btns:after{display:none}.popui__androidSheet .vlayer__wrap-btns .btn{border-radius:0;font-size:14px;padding:0 20px;text-align:left}
.popui__androidSheet .vlayer__wrap-btns .btn:first-child{border-top-left-radius:2px;border-top-right-radius:2px}
.popui__androidSheet .vlayer__wrap-btns .btn:last-child{border-bottom-left-radius:2px;border-bottom-right-radius:2px}
.popui__androidSheet .vlayer__wrap-btns .btn:before{content:'';border-top:1px solid #e5e5e5;color:#e5e5e5;height:1px;position:absolute;left:0;right:0;top:0;transform:scaleY(.5);-webkit-transform:scaleY(.5);transform-origin:0 0;-webkit-transform-origin:0 0}
.popui__androidSheet .vlayer__wrap-btns .btn:first-child:before{display:none}.popui__androidSheet .vlayer__wrap-btns .btn:after{display:none}.popui__contextmenu{box-shadow:0 0 12px rgba(0,0,0,.15);max-width:200px;position:absolute}
.popui__contextmenu .vlayer__wrap-tit{background:none;border-bottom:0;font-size:18px;padding:5px 20px 0;text-align:left}
.popui__contextmenu .vlayer__wrap-cnt{color:#808080;font-size:15px;padding:0 20px 15px;text-align:left}
.popui__contextmenu .vlayer__wrap-cntbox:first-child .vlayer__wrap-cnt{color:#1f1f1f;padding:15px 20px 5px}.popui__contextmenu .vlayer__wrap-btns{display:block}
.popui__contextmenu .vlayer__wrap-btns:after{display:none}.popui__contextmenu .vlayer__wrap-btns .btn{border-radius:0;display:flex;align-items:center;font-size:14px;padding:0 20px;text-align:left;height:45px;line-height:18px}
.popui__contextmenu .vlayer__wrap-btns .btn:first-child{border-radius:2px 2px 0 0}.popui__contextmenu .vlayer__wrap-btns .btn:last-child{border-radius:0 0 2px 2px}
.popui__contextmenu .vlayer__wrap-btns .btn:after{display:none}.popui__drawer-top,.popui__drawer-right,.popui__drawer-bottom,.popui__drawer-left{border-radius:0;max-width:100%;position:fixed;left:0!important;right:0!important;bottom:0!important}
.popui__drawer-top{top:0!important;bottom:auto!important;width:100%!important;animation:anim-fadeInUp .3s!important}
.popui__drawer-right{top:0!important;left:auto!important;animation:anim-fadeInRight .3s!important}.popui__drawer-bottom{top:auto!important;width:100%!important;animation:anim-fadeInDown .3s!important}.popui__drawer-left{top:0!important;right:auto!important;animation:anim-fadeInLeft .3s!important}
.popui__iframe iframe{display:block;height:100%;width:100%}.popui__message .vlayer__wrap-cnt{padding:10px 20px;text-align:left}.popui__message.vlayer-closable .vlayer__wrap-cnt{padding-right:50px}
.popui__message .vlayer__xclose{top:10px;right:10px}.popui__message .vlayer__xclose:after{font-size:12px}.vlayer-msg__icon{float:left;color:#2d8cf0;height:18px;width:18px}
.vlayer-msg__icon svg{display:block;height:100%;width:100%}.vlayer-msg__icon svg path{fill:currentColor}
.vlayer-msg__icon.success{color:#52c41a}.vlayer-msg__icon.warning{color:#faad14}
.vlayer-msg__icon.error{color:#ff3838}.vlayer-msg__icon.loading{animation:anim__rotate 2s linear infinite}
.vlayer-msg__icon.loading circle{stroke:currentColor;stroke-width:3;stroke-linecap:round;animation:anim__circular 1.5s ease-in-out infinite}.vlayer-msg__group{padding-left:25px}
.vlayer-msg__group:first-child{padding-left:0}.popui__notify{box-shadow:0 4px 12px rgba(0,0,0,.2);min-width:260px}
.popui__notify .vlayer__wrap-tit{display:none!important}.popui__notify .vlayer__wrap-cnt{padding:15px 20px;text-align:left}
.popui__notify.vlayer-closable .vlayer__wrap-cnt{padding-right:50px}.popui__notify .vlayer__xclose{top:15px;right:15px}
.popui__notify .vlayer__xclose:after{font-size:12px}.popui__notify .vlayer-msg__icon{height:24px;width:24px;margin-top:-3px}
.popui__notify .vlayer-msg__group{padding-left:35px}.popui__notify .vlayer-msg__group:first-child{padding-left:0}
.popui__notify .vlayer-msg__group .vlayer-msg__title{font-size:16px}.popui__notify .vlayer-msg__group .vlayer-msg__content{margin-top:8px}.popui__notify .vlayer-msg__group .vlayer-msg__content:first-child{margin-top:0}
.popui__popover .vlayer__wrap-tit{display:none!important}.popui__popover .vlayer__wrap-cnt{padding:10px 15px;text-align:left}
.popui__popover.vlayer-closable .vlayer__wrap-cnt{padding-right:40px}
.popui__popover .vlayer__xclose{top:10px;right:10px}.popui__popover .vlayer__xclose:after{font-size:12px}.popui__popover .vlayer-msg__group .vlayer-msg__title{font-size:16px}.popui__popover .vlayer-msg__group .vlayer-msg__content{color:#686868;margin-top:10px}
.popui__popover .vlayer-msg__group .vlayer-msg__content:first-child{margin-top:0}.popui__popover.top{margin-top:-10px}.popui__popover.bottom{margin-top:10px}
.popui__popover:before{content:'';display:block;border-color:transparent;border-style:solid;border-width:6px;height:0;width:0;position:absolute}
.popui__popover.top:before{bottom:-6px;left:20px;border-top-color:#fff;border-bottom-width:0;filter:drop-shadow(0px 1px 1px rgba(0,0,0,.2))}
.popui__popover.bottom:before{top:-6px;left:20px;border-bottom-color:#fff;border-top-width:0;filter:drop-shadow(0px -1px 1px rgba(0,0,0,.15))}
.popui__message .vlayer__wrap-btns,.popui__notify .vlayer__wrap-btns,.popui__popover .vlayer__wrap-btns{display:block;padding:0 15px 10px;text-align:right;line-height:24px}
.popui__message .vlayer__wrap-btns:after,.popui__notify .vlayer__wrap-btns:after,.popui__popover .vlayer__wrap-btns:after{display:none}
.popui__message .vlayer__wrap-btns .btn,.popui__notify .vlayer__wrap-btns .btn,.popui__popover .vlayer__wrap-btns .btn{border-radius:4px;font-size:12px;display:inline-block;vertical-align:top;margin-left:10px;padding:0 10px}
.popui__message .vlayer__wrap-btns .btn:after,.popui__notify .vlayer__wrap-btns .btn:after,.popui__popover .vlayer__wrap-btns .btn:after{display:none}
.popui__message .vlayer__wrap-btns .btn:first-child,.popui__notify .vlayer__wrap-btns .btn:first-child,.popui__popover .vlayer__wrap-btns .btn:first-child{color:#808080}
.popui__message .vlayer__wrap-btns .btn:last-child,.popui__notify .vlayer__wrap-btns .btn:last-child,.popui__popover .vlayer__wrap-btns .btn:last-child{background:#00e0a1;color:#fff}
.anim-fadeIn{animation:anim-fadeIn .5s}
.anim-scaleIn{animation:anim-scaleIn .3s}
.anim-fadeInUp{animation:anim-fadeInUp .3s}
.anim-fadeInDown{animation:anim-fadeInDown .3s}
.anim-fadeInRight{animation:anim-fadeInRight .3s}
.anim-fadeInLeft{animation:anim-fadeInLeft .3s}
.anim-footer{animation:anim-footer .3s}
.anim-fadeOutUp{animation:anim-fadeOut .3s}
.anim-fadeOut{animation:anim-fadeOut .3s}
.anim-ScaleOut{animation:anim-ScaleOut .3s}
@keyframes anim-fadeIn{0%{opacity:0}
100%{opacity:1}
}@keyframes anim-scaleIn{0%{opacity:0;transform:scale(.9)}
100%{opacity:1;transform:scale(1)}
}@keyframes anim-fadeOut{100%{opacity:0}
}@keyframes anim-ScaleOut{100%{opacity:0;-webkit-transform:scale(.9);transform:scale(.9)}
}@keyframes anim-footer{0%{opacity:0;transform:translateY(800px)}
100%{opacity:1;transform:translateY(0)}
}@keyframes anim__rotate{from{transform:rotate(0);-webkit-transform:rotate(0)}
to{transform:rotate(360deg);-webkit-transform:rotate(360deg)}
}@keyframes anim__circular{0%{stroke-dasharray:1,200;stroke-dashoffset:0}
50%{stroke-dasharray:90,150;stroke-dashoffset:-40}
100%{stroke-dasharray:90,150;stroke-dashoffset:-120}
}@keyframes anim-fadeInUp{0%{opacity:0;-webkit-transform:translate3d(0,-100%,0);transform:translate3d(0,-100%,0)}
100%{opacity:1;-webkit-transform:none;transform:none}
}@keyframes anim-fadeOutUp{0%{opacity:1}
100%{opacity:0;-webkit-transform:translate3d(0,-100%,0);transform:translate3d(0,-100%,0)}
}@keyframes anim-fadeInRight{0%{opacity:0;-webkit-transform:translate3d(100%,0,0);transform:translate3d(100%,0,0)}
100%{opacity:1;-webkit-transform:none;transform:none}
}@keyframes anim-fadeOutLeft{0%{opacity:1}
100%{opacity:0;-webkit-transform:translate3d(100%,0,0);transform:translate3d(100%,0,0)}
}@keyframes anim-fadeInDown{0%{opacity:0;-webkit-transform:translate3d(0,100%,0);transform:translate3d(0,100%,0)}
100%{opacity:1;-webkit-transform:none;transform:none}
}@keyframes anim-fadeOutDown{0%{opacity:1}
100%{opacity:0;-webkit-transform:translate3d(0,100%,0);transform:translate3d(0,100%,0)}
}@keyframes anim-fadeInLeft{0%{opacity:0;-webkit-transform:translate3d(-100%,0,0);transform:translate3d(-100%,0,0)}
100%{opacity:1;-webkit-transform:none;transform:none}
}@keyframes anim-fadeOutRight{0%{opacity:1}
100%{opacity:0;-webkit-transform:translate3d(-100%,0,0);transform:translate3d(-100%,0,0)}
}
</style>
